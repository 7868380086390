import { useState } from 'react';

import { useInterval } from 'components/useInterval';

function Blink({ children, delay = 500 }) {
  const [isVisible, setIsVisible] = useState(true);
  useInterval(() => setIsVisible(x => !x), delay);
  return isVisible ? children : null;
}

export default Blink;
