
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ArbitrageTable"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ArbitrageTable"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"startTime"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"sellExchangeEntryCurrency"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"buyExchangeEntryCurrency"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"rows"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"sellExchangeEntryPoint"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ArbitrageEntryPoint"},"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"buyExchangeEntryPoint"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ArbitrageEntryPoint"},"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"roiPercent"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"expense"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"revenue"},"arguments":[],"directives":[]}]}}]}}],"loc":{"start":0,"end":329}};
    doc.loc.source = {"body":"#import './ArbitrageEntryPoint.gql'\n\nfragment ArbitrageTable on ArbitrageTable {\n  startTime\n  sellExchangeEntryCurrency\n  buyExchangeEntryCurrency\n  rows {\n    sellExchangeEntryPoint {\n      ...ArbitrageEntryPoint\n    }\n    buyExchangeEntryPoint {\n      ...ArbitrageEntryPoint\n    }\n    roiPercent\n    expense\n    revenue\n  }\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require('./ArbitrageEntryPoint.gql').definitions));


      module.exports = doc;
    
