import { createGlobalStyle } from 'styled-components';
import { colors } from 'ui';

const GlobalStyle = createGlobalStyle`
  body {
    height: 100%;
    background-color: ${colors.background};
    margin: 0;
    font-family: unit, sans-serif;
    color: ${colors.bodyText};
    font-feature-settings: 'lnum' 1;
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  * {
    box-sizing: border-box;
  }
`;

export default GlobalStyle;
