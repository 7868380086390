import { useQuery } from '@apollo/react-hooks';

import getWalletBalancesPlatforms from './graphql/getWalletBalancesPlatforms.gql';

export function useWalletBalancesPlatforms() {
  const { data = {}, loading } = useQuery(getWalletBalancesPlatforms);
  const { walletBalancesPlatforms = [] } = data;
  return {
    platforms: walletBalancesPlatforms,
    loading
  };
}
