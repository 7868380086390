import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { matchPath, withRouter } from 'react-router';
import { colors } from 'ui';

const Wrapper = styled.div`
  display: flex;
`;

const NavLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  padding-left: 12px;
  padding-right: 12px;
`;

const Tab = styled.div`
  display: flex;
  align-items: center;
  padding-left: 2px;
  padding-right: 2px;
  font-size: 1.1em;
  outline-style: none;
  height: ${props => props.height};
  text-decoration: none;
  border-bottom-style: solid;
  color: ${props => (props.active ? colors.neutral50 : colors.bodyText)};
  border-bottom-color: ${props =>
    props.active ? colors.primary : 'transparent'};
  transition: color 200ms ease;

  &:hover,
  &:active,
  &:focus {
    color: ${colors.neutral50};
  }
`;

const NavTabs = ({
  height = '48px',
  routes = [],
  location: { pathname } = {}
}) => (
  <Wrapper>
    {routes.map(route => (
      <NavLink key={route.path} to={route.url}>
        <Tab
          height={height}
          active={Boolean(matchPath(pathname, { path: route.path }))}
        >
          {route.name}
        </Tab>
      </NavLink>
    ))}
  </Wrapper>
);

export default withRouter(NavTabs);
