import React, { useState } from 'react';
import styled from 'styled-components';
import classes from 'classnames';
import AgeLabel from 'components/dashboard/AgeLabel';
import GridSection from './GridSection';
import MarketCurrencySelect from './MarketCurrencySelect';
import { Checkmark, Warning, ArrowFromBottom } from 'components/ui/icons';
import { Card, Flex } from 'components/ui/layout';
import { OutlineButton } from 'components/ui/buttons';
import { useQuery } from '@apollo/react-hooks';
import { colors } from 'ui';
import { formatAmount, getAmountPrecision } from 'utils/format';

import getAccountCurrencyBalancesGql from './graphql/getAccountCurrencyBalances.gql';

const POLL_INTERVAL = 1000;

const ArrowFromBottomIcon = styled(ArrowFromBottom)`
  width: ${props => props.size};
  height: ${props => props.size};
`;

const Wrapper = styled.div`
  height: 100%;

  .first-row {
    display: flex;

    .select-container {
      font-size: 1rem;
      width: 100%;
      margin-bottom: 5px;
    }

    .button-container {
      font-size: 1rem;
      margin-left: 6px;

      button {
        width: 32px;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .table-container {
    overflow: auto;
    height: 210px;
  }

  table {
    font-feature-settings: 'tnum' 1, 'lnum' 1;
    border-collapse: collapse;
    width: 100%;

    td,
    th {
      height: 30px;

      &.orange {
        color: ${colors.primary};
      }

      &.v-center {
        display: flex;
        align-items: center;
      }

      &.center {
        text-align: center;
      }

      &.right {
        text-align: right;
      }
    }

    tbody {
      tr {
        transition: background-color 100ms ease-in;

        &:hover {
          background-color: ${colors.neutral10};
        }
      }
    }

    tfoot {
      th {
        position: sticky;
        bottom: 0px;
        left: 0px;
      }
    }

    th {
      text-align: left;
      padding: 6px 8px;
      vertical-align: top;
      white-space: nowrap;
      background-color: ${colors.neutral5};
      position: sticky;
      top: 0;
    }

    td {
      padding: 6px 8px;
    }
  }
`;

function useAccountCurrencyBalances(marketCurrency) {
  const { data = {}, loading } = useQuery(getAccountCurrencyBalancesGql, {
    skip: !marketCurrency,
    variables: { marketCurrency },
    fetchPolicy: 'network-only',
    pollInterval: POLL_INTERVAL
  });
  const { accountCurrencyBalances = {} } = data;
  return [accountCurrencyBalances, loading];
}

function getTotals(items) {
  const totals = {
    bitcoinBalance: 0,
    marketCurrencyBalance: 0
  };
  for (const item of items) {
    totals.bitcoinBalance += Number(item.bitcoinBalance);
    totals.marketCurrencyBalance += Number(item.marketCurrencyBalance);
  }
  return totals;
}

function CoinBalances({
  switchMarketCurrency,
  defaultMarketCurrency,
  marketCurrencyOptions,
  markets
}) {
  const [marketCurrency, setMarketCurrency] = useState(defaultMarketCurrency);
  const [balances] = useAccountCurrencyBalances(marketCurrency);
  const { items = [], totalTotalAmount, totalTotalAmountBtc } = balances;

  function handleCoinChange(option) {
    setMarketCurrency(option ? option.value : null);
  }

  function handleSelectButtonClick() {
    switchMarketCurrency(marketCurrency);
  }

  const totals = getTotals(items);
  const marketCurrencyPrecision = getAmountPrecision(markets[marketCurrency]);
  const btcPrecision = getAmountPrecision(markets.BTC);
  const isSelected = Boolean(marketCurrency);

  return (
    <GridSection header="Coin Balances">
      <Card height="275px" style={{ minWidth: '400px' }}>
        <Wrapper>
          <div className="first-row">
            <div className="select-container">
              <MarketCurrencySelect
                displayTotalAmount
                options={marketCurrencyOptions}
                marketCurrency={marketCurrency}
                totalTotalAmount={formatAmount(
                  totalTotalAmount,
                  marketCurrencyPrecision
                )}
                totalTotalAmountBtc={formatAmount(
                  totalTotalAmountBtc,
                  btcPrecision
                )}
                onChange={handleCoinChange}
              />
            </div>
            <div className="button-container">
              <OutlineButton
                active={isSelected && marketCurrency === defaultMarketCurrency}
                onClick={handleSelectButtonClick}
                disabled={!isSelected}
              >
                <ArrowFromBottomIcon size={18} />
              </OutlineButton>
            </div>
          </div>
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th>Account</th>
                  <th className="right">{marketCurrency || 'ALT'}</th>
                  <th className="right">BTC</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {balances.marketCurrency === marketCurrency
                  ? items.map(item => {
                      const bitcoinBalance = Number(item.bitcoinBalance);
                      return (
                        <tr key={item.accountName}>
                          <td>{item.accountName}</td>
                          <td className="right">
                            {formatAmount(
                              Number(item.marketCurrencyBalance),
                              marketCurrencyPrecision
                            )}
                          </td>
                          <td
                            className={classes('right', {
                              // highlight bitcoin balances less than 1:
                              orange: bitcoinBalance < 1
                            })}
                          >
                            {formatAmount(bitcoinBalance, btcPrecision)}
                          </td>
                          <td className="right" style={{ width: '100px' }}>
                            <Flex justifyContent="flex-end">
                              <AgeLabel startTime={item.startTime} />
                            </Flex>
                          </td>
                          <td className="v-center">
                            {item.hasError ? (
                              <Warning size={12} color={colors.red1} />
                            ) : (
                              <Checkmark size={12} color={colors.green} />
                            )}
                          </td>
                        </tr>
                      );
                    })
                  : null}
              </tbody>
              <tfoot>
                {balances.marketCurrency === marketCurrency ? (
                  <tr>
                    <th>Total</th>
                    <th className="right">
                      {formatAmount(
                        totals.marketCurrencyBalance,
                        marketCurrencyPrecision
                      )}
                    </th>
                    <th className="right">
                      {formatAmount(totals.bitcoinBalance, btcPrecision)}
                    </th>
                    <th></th>
                    <th></th>
                  </tr>
                ) : null}
              </tfoot>
            </table>
          </div>
        </Wrapper>
      </Card>
    </GridSection>
  );
}

export default CoinBalances;
