import React, { useState } from 'react';
import Select from 'components/Select';
import OrderList from './OrderList';
import { Box, Card, Flex } from 'components/ui/layout';
import { PrimaryButton } from 'components/ui/buttons';
import { useQuery } from '@apollo/react-hooks';

import getBithumbOrders from './graphql/getBithumbOrders.gql';
import getBithumbMarketCurrencies from './graphql/getBithumbMarketCurrencies.gql';

function useOpenOrdersQuery(marketCurrency) {
  const { data = {}, refetch, loading } = useQuery(getBithumbOrders, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    skip: !marketCurrency,
    variables: { marketCurrency }
  });
  const { bithumb: { openOrders: orders = [] } = {} } = data;
  return [orders, loading, () => refetch(marketCurrency)];
}

function useCurrenciesQuery() {
  const { data = {}, loading } = useQuery(getBithumbMarketCurrencies);
  const { exchanges = [] } = data;
  const { marketCurrencies = [] } = exchanges[0] || {};
  return [marketCurrencies, loading];
}

function useBithumbOrders() {
  const [currencyOption, setCurrencyOption] = useState(null);
  const [marketCurrencies, marketCurrenciesLoading] = useCurrenciesQuery();
  const [orders, ordersLoading, refetchOrders] = useOpenOrdersQuery(
    currencyOption ? currencyOption.value : ''
  );
  return {
    isCurrencySelected: Boolean(currencyOption),
    currencyOption,
    setCurrencyOption,
    marketCurrencies,
    marketCurrenciesLoading,
    orders,
    ordersLoading,
    refetchOrders
  };
}

function BithumbOrders() {
  const {
    currencyOption,
    setCurrencyOption,
    marketCurrencies,
    marketCurrenciesLoading,
    isCurrencySelected,
    orders,
    ordersLoading,
    refetchOrders
  } = useBithumbOrders();
  return (
    <Box marginBottom="75px" width="600px">
      <Flex
        marginBottom="20px"
        justifyContent="space-between"
        alignItems="center"
      >
        Bithumb orders
        <Flex alignItems="center">
          <Box marginRight="10px">
            <Box width="14rem">
              <Select
                placeholder="Select currency…"
                options={marketCurrencies.map(value => ({
                  value,
                  label: value
                }))}
                value={currencyOption}
                onChange={setCurrencyOption}
                isDisabled={marketCurrenciesLoading}
                isClearable
              />
            </Box>
          </Box>
          <PrimaryButton
            onClick={refetchOrders}
            disabled={marketCurrenciesLoading || !isCurrencySelected}
          >
            Refresh
          </PrimaryButton>
        </Flex>
      </Flex>
      <Box inlineFlexBox flexDirection="column">
        <Flex fontSize="0.75em" marginBottom="5px">
          {!isCurrencySelected ? (
            <Card minWidth="600px" flexBox>
              Select currency.
            </Card>
          ) : ordersLoading ? (
            <Card minWidth="600px" flexBox>
              Loading orders...
            </Card>
          ) : orders.length ? (
            <Card minWidth="600px" flexBox>
              <OrderList orders={orders} />
            </Card>
          ) : (
            <Card minWidth="600px" flexBox>
              Orders not found.
            </Card>
          )}
        </Flex>
      </Box>
    </Box>
  );
}

export default BithumbOrders;
