import styled from 'styled-components';
import { colors, textStyles } from 'ui';

export const Wrapper = styled.div`
  section.chain {
    overflow-x: auto;

    & > h3 {
      margin-bottom: 8px;
    }
  }

  .secondary {
    color: ${colors.neutral20};
  }

  .green {
    color: ${colors.green};
  }

  .primary {
    color: ${colors.primary};
  }

  .red {
    color: ${colors.red1};
  }

  .grid {
    display: flex;
    flex-wrap: wrap;
    column-gap: 24px;
  }

  p.comment {
    color: ${colors.primary};
    font-size: 0.85rem;
    margin-top: 0px;
    margin-bottom: 4px;
  }

  table {
    font-size: 0.85rem;
    border-collapse: collapse;
    font-feature-settings: 'tnum' 1, 'lnum' 1;
    border: 1px solid ${colors.neutral10};

    &.merged {
      border: 1px dotted ${colors.purple};
    }

    .right {
      text-align: right;
    }

    .center {
      text-align: center;
    }

    .lim {
      margin-top: 2px;
      font-size: 0.9em;
    }

    .account-balance {
      margin-top: 4px;
    }

    .pnl-cell {
      height: 1px;

      .pnl-container {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
      }

      span.pnl {
        color: inherit;
        text-decoration: none;
        transition: background-color 100ms ease;
        font-size: 0.9rem;
        padding: 2px 4px;
        border-radius: 2px;
        cursor: pointer;

        &:hover {
          background-color: ${colors.neutral10};
        }
      }
    }

    .ex {
      height: 1px;

      .container {
        height: 100%;
        display: flex;
        flex-direction: column;

        .ex-and-prices {
          display: flex;
          justify-content: space-between;
          column-gap: 6px;

          .price {
            font-size: 0.9em;
          }

          &.sx {
            align-items: baseline;
          }

          &.bx {
            flex-direction: column;
            row-gap: 2px;
          }
        }

        .ex-name {
          display: flex;
          flex-direction: column;
        }

        .prices-balances {
          color: ${colors.neutral20};
          display: flex;
          flex-direction: column;
          align-items: flex-end;
        }

        .balance {
          white-space: nowrap;
        }

        .price {
          white-space: nowrap;
        }

        .progress-bar-container {
          margin-bottom: 2px;
        }

        .bx-age-label-container {
          flex: 1;
          padding-top: 2px;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: flex-end;
        }
      }
    }

    tbody {
      tr {
        transition: background-color 150ms ease;

        &:hover {
          background-color: ${colors.neutral0};
        }

        &:not(:first-child) {
          border-top: 1px solid ${colors.neutral10};
        }

        td {
          padding: 4px 6px;
          vertical-align: top;

          &:not(:first-child) {
            border-left: 1px solid ${colors.neutral10};
          }

          &:not(:last-child) {
            border-right: 1px solid ${colors.neutral10};
          }
        }
      }
    }
  }

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 35px;
    margin-bottom: 24px;
    background-color: ${colors.backgroundLighter};

    .buttons {
      display: flex;
    }

    nav {
      height: 36px;
      width: 100%;
      display: flex;
      align-items: center;

      .toolbar {
        width: 100%;
        display: flex;
        align-items: baseline;
        justify-content: space-between;

        .toolbar-group {
        }

        margin-left: 44px;

        button:not(:first-child) {
          margin-left: 10px;
        }
      }

      ul {
        font-size: 1.2em;
        display: flex;
        list-style-type: none;
        margin: 0px;
        padding: 0px;

        li > a {
          color: ${colors.primary};
          text-decoration: none;

          &:hover {
            opacity: 0.6;
          }
        }

        & > li:not(:last-child)::after {
          content: '>';
          padding: 0 0.4em;
        }
      }
    }
  }

  main {
    padding: 0 35px;

    &.currency {
      margin-bottom: 120px;
    }

    section {
      margin-bottom: 16px;

      h3 {
        ${textStyles.smallCaps};
        color: ${colors.neutral60};
        margin-bottom: 4px;
        font-size: 1rem;
        margin-top: 0px;
        display: inline-block;

        &:not(.static) {
          cursor: pointer;
        }

        &:hover:not(.static) {
          opacity: 0.6;
        }
      }

      ul {
        min-height: 50px;
        list-style-type: none;
        background-color: ${colors.neutral0};
        padding: 6px 6px;
        display: flex;
        flex-wrap: wrap;
        gap: 4px 4px;
        margin: 0px;

        li {
          font-size: 0.9em;
        }

        a,
        span {
          line-height: 1;
          display: flex;
          text-decoration: none;
          color: inherit;
          background-color: ${colors.neutral5};
          padding: 4px 4px 16px 4px;
        }

        span.market {
          border: 2px solid transparent;
          cursor: pointer;
          transition: opacity 150ms ease;

          &:hover {
            opacity: 0.6;
          }

          &.pending {
            border: 2px solid ${colors.primary};
            color: ${colors.primary};
          }
        }

        span.platform {
          border: 2px solid ${colors.neutral10};

          &.active {
            border: 2px solid ${colors.green};
            color: ${colors.green};
          }

          &.edit {
            cursor: pointer;
          }
        }

        a {
          border: 2px solid ${colors.neutral10};
          transition: opacity 150ms ease;

          &:hover {
            opacity: 0.6;
          }
        }
      }
    }
  }
`;
