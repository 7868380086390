import React from 'react';
import AuthContext from './AuthContext';

export const withAuth = WrappedComponent => {
  return props => (
    <AuthContext.Consumer>
      {authProps => <WrappedComponent {...props} {...authProps} />}
    </AuthContext.Consumer>
  );
};
