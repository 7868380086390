import React, { useState } from 'react';
import styled from 'styled-components';
import OrderRow from './OrderRow';
import { OutlineButton } from 'components/ui/buttons';
import { colors } from 'ui';

const Table = styled.table`
  font-feature-settings: 'tnum' 1, 'lnum' 1;
  border-collapse: collapse;
  width: 100%;

  tfoot {
    td {
      padding-top: 25px;
    }
  }

  tbody {
    tr {
      transition: background-color 100ms ease-in;

      &:hover {
        background-color: ${colors.neutral10};
      }
    }
  }

  th {
    text-align: left;
    padding: 6px 8px;
    vertical-align: top;
    white-space: nowrap;
  }

  td {
    padding: 6px 8px;
    text-align: right;
  }
`;

function OrderList({ orders }) {
  const rowRefs = {};
  const [isCanceling, setCanceling] = useState(false);
  function cancelAll() {
    setCanceling(true);
    for (const id in rowRefs) {
      const row = rowRefs[id];
      row.cancel();
    }
  }
  return (
    <Table>
      <tbody>
        {orders.map(order => (
          <OrderRow
            ref={ref => {
              rowRefs[order.id] = ref;
            }}
            key={order.id}
            order={order}
          />
        ))}
      </tbody>
      {!isCanceling ? (
        <tfoot>
          <tr>
            <td colSpan={3}>
              <OutlineButton onClick={cancelAll} disabled={isCanceling} small>
                Cancel All
              </OutlineButton>
            </td>
          </tr>
        </tfoot>
      ) : null}
    </Table>
  );
}

export default OrderList;
