import React from 'react';
import styled, { keyframes } from 'styled-components';
import { colors } from 'ui';
import { CheckCircle, ExclamationCircle, Spinner } from 'components/ui/icons';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const AnimatedSpinner = styled(Spinner)`
  animation: ${rotate} 1s linear infinite;
`;

const StatusIcon = ({ status }) => {
  if (status === 'completed') {
    return <CheckCircle size={16} color={colors.green} />;
  }
  if (status === 'failed') {
    return <ExclamationCircle size={16} color={colors.red1} />;
  }
  if (status === 'starting' || status === 'started') {
    return <AnimatedSpinner size={16} />;
  }
  return null;
};

export default StatusIcon;
