import React, { useState } from 'react';
import ExchangeSelect from './ExchangeSelect';
import MarketCurrencySelect from './MarketCurrencySelect';
import styled from 'styled-components';
import TrackButton from './TrackButton';
import PauseButton from './PauseButton';
import { OutlineButton } from 'components/ui/buttons';
import { colors } from 'ui';
import { Container, Flex, Box } from 'components/ui/layout';

const SHOW_PAUSE_BUTTON = false;

const Wrapper = styled(Container)`
  display: flex;
  background-color: ${colors.backgroundLighter};

  .fields {
    display: flex;
    align-items: flex-end;
  }

  .buttons {
    display: flex;
    align-items: flex-start;
  }

  .pills-container {
    overflow: hidden;
    width: 100%;
    margin-right: 20px;
  }

  ul.pills {
    list-style: none;
    display: flex;
    align-items: center;
    margin: 0 -5px;
    padding: 0;

    li {
      padding: 0 5px;
    }
  }
`;

function useSorting(lastMarketCurrencyOptions) {
  const [isSortingOn, setIsSortingOn] = useState(
    localStorage.getItem('dashboard_last_coins_sorting_on') === 'true'
  );

  function toggleSortingOn() {
    setIsSortingOn(!isSortingOn);
    localStorage.setItem(
      'dashboard_last_coins_sorting_on',
      JSON.stringify(!isSortingOn)
    );
  }

  function sort(options) {
    if (isSortingOn) {
      return options.slice().sort((a, b) => a.value.localeCompare(b.value));
    } else {
      return options;
    }
  }

  return {
    isSortingOn,
    toggleSortingOn,
    lastMarketCurrencyOptionsSorted: sort(lastMarketCurrencyOptions)
  };
}

function DashboardToolbar({
  marketCurrency,
  marketCurrencyOptions,
  lastMarketCurrencyOptions,
  exchangeOptions,
  frequentExchangeOptions,
  firstExchangeId,
  secondExchangeId,
  trackingState,
  canStartTracking,
  canSelectMarketCurrency,
  canSelectExchange,
  handleMarketCurrencyChange,
  handleFirstExchangeChange,
  handleSecondExchangeChange,
  handlePauseButtonClick,
  handleTrackButtonClick
}) {
  const {
    isSortingOn,
    toggleSortingOn,
    lastMarketCurrencyOptionsSorted
  } = useSorting(lastMarketCurrencyOptions);

  const buttons = (
    <div className="buttons">
      {SHOW_PAUSE_BUTTON ? (
        <PauseButton
          trackingState={trackingState}
          onClick={handlePauseButtonClick}
        />
      ) : null}
      <TrackButton
        canStartTracking={canStartTracking}
        trackingState={trackingState}
        onClick={handleTrackButtonClick}
      />
    </div>
  );

  return (
    <Wrapper>
      <Flex
        flexDirection="column"
        width="100%"
        paddingTop="15px"
        paddingBottom="10px"
      >
        <Flex marginBottom="10px" alignItems="center" width="100%">
          <Flex minWidth="70px" marginRight="30px">
            {buttons}
          </Flex>
          <Box className="pills-container">
            <ul className="pills">
              {lastMarketCurrencyOptionsSorted.map(option => {
                const isActive = option.value === marketCurrency;
                return (
                  <li key={option.value}>
                    <OutlineButton
                      padding="0 8px"
                      active={isActive}
                      disabled={!canSelectMarketCurrency}
                      onClick={() =>
                        handleMarketCurrencyChange(isActive ? null : option)
                      }
                    >
                      {option.label}
                    </OutlineButton>
                  </li>
                );
              })}
            </ul>
          </Box>
          <Box minWidth="160px">
            <MarketCurrencySelect
              width="160px"
              options={marketCurrencyOptions}
              marketCurrency={marketCurrency}
              isDisabled={!canSelectMarketCurrency}
              onChange={handleMarketCurrencyChange}
            />
          </Box>
          <Flex minWidth="70px" marginLeft="30px">
            {buttons}
          </Flex>
        </Flex>
        <Flex alignItems="center" width="100%">
          <Flex minWidth="70px" marginRight="30px"></Flex>
          <div className="pills-container">
            <ul className="pills">
              {frequentExchangeOptions.map(option => {
                const isFirst = option.value === firstExchangeId;
                const isSecond = option.value === secondExchangeId;
                const isActive =
                  option.value === firstExchangeId ||
                  option.value === secondExchangeId;
                return (
                  <li key={option.value}>
                    <OutlineButton
                      padding="0 8px"
                      active={isActive}
                      disabled={!canSelectExchange}
                      onClick={() => {
                        if (isFirst) {
                          handleFirstExchangeChange(null);
                        } else if (isSecond) {
                          handleSecondExchangeChange(null);
                        } else if (!firstExchangeId) {
                          handleFirstExchangeChange(option);
                        } else if (!secondExchangeId) {
                          handleSecondExchangeChange(option);
                        }
                      }}
                    >
                      {option.label}
                    </OutlineButton>
                  </li>
                );
              })}
            </ul>
          </div>
          <Flex minWidth="458px">
            <ExchangeSelect
              width="224px"
              options={exchangeOptions}
              exchangeId={firstExchangeId}
              excludeIds={[secondExchangeId]}
              isDisabled={!canSelectExchange}
              onChange={handleFirstExchangeChange}
            />
            <ExchangeSelect
              width="224px"
              marginLeft="10px"
              options={exchangeOptions}
              exchangeId={secondExchangeId}
              excludeIds={[firstExchangeId]}
              isDisabled={!canSelectExchange}
              onChange={handleSecondExchangeChange}
            />
          </Flex>
          <Flex
            minWidth="70px"
            marginLeft="30px"
            justifyContent="space-between"
          >
            <OutlineButton
              width="70px"
              active={isSortingOn}
              onClick={toggleSortingOn}
            >
              A–Z
            </OutlineButton>
          </Flex>
        </Flex>
      </Flex>
    </Wrapper>
  );
}

export default DashboardToolbar;
