import React from 'react';
import AuthContext from './AuthContext';
import LoginForm from './LoginForm';
import authStore from 'security/authStore';
import jwt from 'jsonwebtoken';
import argoTime from 'middleware/argoTime';

const RENEW_TIMEOUT_MARGIN = 5000;

const getAuthInfo = () => {
  let expiresAt = null;
  let expiresIn = null;
  let username = null;
  let isAuthenticated = false;
  const accessToken = authStore.getAccessToken();
  if (accessToken) {
    const decoded = jwt.decode(accessToken);
    if (decoded !== null) {
      if (Number.isFinite(decoded.exp)) {
        expiresAt = decoded.exp * 1000;
        expiresIn = expiresAt - argoTime.now().getTime();
      }
      if (typeof decoded.username === 'string') {
        username = decoded.username;
      }
      if (username && expiresIn > 0) {
        isAuthenticated = true;
      }
    }
  }
  return { isAuthenticated, expiresAt, expiresIn, username, accessToken };
};

class AuthProvider extends React.Component {
  authenticate = () => {
    authStore.setAccessToken(null);
    this.forceUpdate();
  };
  login = async (username, password) => {
    const res = await fetch('/login', {
      method: 'POST',
      body: JSON.stringify({ username, password }),
      headers: { 'Content-Type': 'application/json' }
    });
    const { success, token, message } = await res.json();
    if (success) {
      authStore.setAccessToken(token);
      this.forceUpdate();
    }
    return { success, message };
  };
  renewSession = async () => {
    const { isAuthenticated, accessToken } = getAuthInfo();
    if (isAuthenticated) {
      const res = await fetch('/renew', {
        method: 'PATCH',
        headers: { Authorization: `Bearer ${accessToken}` }
      });
      const { success, token } = await res.json();
      if (success) {
        authStore.setAccessToken(token);
        this.scheduleRenewal();
        return;
      }
    }
    this.authenticate();
  };
  scheduleRenewal = () => {
    const { isAuthenticated, expiresIn } = getAuthInfo();
    if (isAuthenticated) {
      // To reduce the odds of using a stale token in the API,
      // schedule session renewal in `RENEW_TIMEOUT_MARGIN` ms before it expires.
      // A stale token will force the user to re-authentication.
      const timeout = expiresIn - RENEW_TIMEOUT_MARGIN;
      if (timeout > 0) {
        this.renewTimeout = setTimeout(this.renewSession, timeout);
      } else {
        // If schedule renewal within RENEW_TIMEOUT_MARGIN
        // request authentication:
        this.authenticate();
      }
    }
  };
  componentDidMount = () => {
    if (!this.renewTimeout) {
      this.scheduleRenewal();
    }
  };
  componentDidUpdate = () => {
    if (!this.renewTimeout) {
      this.scheduleRenewal();
    }
  };
  componentWillUnmount() {
    if (this.renewTimeout) {
      clearTimeout(this.renewTimeout);
    }
  }
  render() {
    const { isAuthenticated, username } = getAuthInfo();
    return (
      <AuthContext.Provider
        value={{ username, authenticate: this.authenticate }}
      >
        {isAuthenticated ? (
          this.props.children
        ) : (
          <LoginForm login={this.login} />
        )}
      </AuthContext.Provider>
    );
  }
}

export default AuthProvider;
