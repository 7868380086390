import React from 'react';
import GlobalStyle from 'components/GlobalStyle';
import AuthProvider from 'components/auth/AuthProvider';
import AuthContext from 'components/auth/AuthContext';
import AppPagesSwitch from './AppPagesSwitch';
import { getApolloClient } from 'middleware/apolloClient';
import { ApolloProvider } from 'react-apollo';
import { BrowserRouter } from 'react-router-dom';

const App = () => (
  <>
    <GlobalStyle />
    <AuthProvider>
      <AuthContext.Consumer>
        {({ authenticate }) => (
          <ApolloProvider client={getApolloClient({ authenticate })}>
            <BrowserRouter>
              <AppPagesSwitch />
            </BrowserRouter>
          </ApolloProvider>
        )}
      </AuthContext.Consumer>
    </AuthProvider>
  </>
);

export default App;
