import React from 'react';
import Switch from 'components/ui/Switch';
import styled from 'styled-components';
import { colors } from 'ui';

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 1px;
  padding: 6px 10px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  background-color: ${colors.neutral10};
`;

const Field = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    margin-right: 10px;
  }
`;

const ArbitrageFooter = ({ enableBalances, onEnableBalancesChange }) => (
  <Wrapper>
    <Field>
      <span>Use balances</span>
      <Switch small isOn={enableBalances} onChange={onEnableBalancesChange} />
    </Field>
  </Wrapper>
);

export default ArbitrageFooter;
