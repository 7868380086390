import React from 'react';
import styled from 'styled-components';
import { OutlineButton } from 'components/ui/buttons';
import { colors } from 'ui';
import { Container, Box } from 'components/ui/layout';

const Wrapper = styled(Container)`
  display: flex;
  justify-content: flex-end;
  height: 110px;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: ${colors.backgroundLighter};

  .pills-container {
    overflow: hidden;
    width: 100%;
    margin-right: 20px;
  }

  ul.pills {
    list-style: none;
    display: flex;
    align-items: center;
    margin: 0 -5px;
    padding: 0;

    li {
      padding: 0 5px;
    }
  }
`;

function WalletsToolbar({ platformId, selectPlatform, platforms }) {
  return (
    <Wrapper>
      <Box className="pills-container">
        <ul className="pills">
          {platforms.map(platform => {
            const isActive = platform.id === platformId;
            return (
              <li key={platform.id}>
                <OutlineButton
                  padding="0 8px"
                  active={isActive}
                  onClick={() => selectPlatform(platform.id)}
                >
                  {platform.name}
                </OutlineButton>
              </li>
            );
          })}
        </ul>
      </Box>
    </Wrapper>
  );
}

export default WalletsToolbar;
