import { useMemo } from 'react';
import { useQuery } from '@apollo/react-hooks';

import getMarketsGql from './graphql/getMarkets.gql';

function shape({ markets = [] } = {}) {
  const viewModel = {};
  for (const market of markets) {
    viewModel[market.marketCurrency] = market;
  }
  return viewModel;
}

export function useMarkets() {
  const { data = {}, loading } = useQuery(getMarketsGql, {
    fetchPolicy: 'network-only'
  });

  const viewModel = useMemo(() => shape(data), [data]);

  return [viewModel, loading];
}
