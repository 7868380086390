import React from 'react';
import GridSection from './GridSection';
import styled from 'styled-components';
import { colors } from 'ui';
import { Card } from 'components/ui/layout';

const defaultStyle = {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
};

const styles = {
  BTC: {
    minimumFractionDigits: 8,
    maximumFractionDigits: 8
  },
  IDR: {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  }
};

const formatPrice = (value, currency) => {
  const style = styles[currency] || defaultStyle;
  return value.toLocaleString('en-US', {
    style: 'decimal',
    ...style
  });
};

const formatAmount = value => {
  return value.toLocaleString('en-US', {
    style: 'decimal',
    minimumFractionDigits: 8,
    maximumFractionDigits: 8
  });
};

const Table = styled.table`
  font-feature-settings: 'tnum' 1, 'lnum' 1;
  border-collapse: collapse;
`;

const TableRow = styled.tr``;

const TableHeader = styled.th`
  text-align: left;
  padding: 0.25em 0.8em;
  vertical-align: top;
  text-align: right;
  white-space: nowrap;

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }
`;

const TableCell = styled.td`
  padding: 0.25em 0.8em;
  text-align: right;

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }
`;

const Orders = ({ orders, baseCurrency, marketCurrency }) => (
  <Table>
    <thead>
      <TableRow>
        <TableHeader>
          {marketCurrency}/{baseCurrency}
        </TableHeader>
        <TableHeader>{marketCurrency}</TableHeader>
        <TableHeader>cum {marketCurrency}</TableHeader>
        <TableHeader>cum {baseCurrency}</TableHeader>
      </TableRow>
    </thead>
    <tbody>
      {orders.map((item, index) => (
        <TableRow key={index}>
          <TableCell>{formatPrice(item.price, baseCurrency)}</TableCell>
          <TableCell>{formatAmount(item.amount)}</TableCell>
          <TableCell>{formatAmount(item.cumulativeAmount)}</TableCell>
          <TableCell>{formatAmount(item.cumulativeTotal)}</TableCell>
        </TableRow>
      ))}
    </tbody>
  </Table>
);

const Orderbook = ({ orderbook, baseCurrency, exchangeName }) => {
  if (orderbook !== null) {
    const { bids, asks, marketCurrency, startTime } = orderbook;
    return (
      <GridSection
        header={`${exchangeName} ${marketCurrency} orderbook`}
        startTime={startTime}
        useAgeLabelColor
      >
        <Card backgroundColor={colors.neutral10} color={colors.red1}>
          <Orders
            orders={asks}
            baseCurrency={baseCurrency}
            marketCurrency={marketCurrency}
          />
        </Card>
        <Card
          backgroundColor={colors.neutral10}
          color={colors.green}
          marginLeft="15px"
        >
          <Orders
            orders={bids}
            baseCurrency={baseCurrency}
            marketCurrency={marketCurrency}
          />
        </Card>
      </GridSection>
    );
  }
  return null;
};

export default Orderbook;
