import { useState, useEffect } from 'react';

export function useMarketCurrencySwitch({
  marketCurrency,
  marketCurrencyOptions,
  selectMarketCurrency,
  stopTracking,
  trackingState
}) {
  const [newMarketCurrencyOption, setNewMarketCurrencyOption] = useState(null);

  function switchMarketCurrency(newMarketCurrency) {
    if (newMarketCurrency !== marketCurrency) {
      const option = marketCurrencyOptions.find(
        ({ value }) => value === newMarketCurrency
      );
      if (option) {
        setNewMarketCurrencyOption(option);
        stopTracking();
      }
    }
  }

  useEffect(() => {
    if (newMarketCurrencyOption !== null) {
      if (trackingState === 'stopped') {
        selectMarketCurrency(newMarketCurrencyOption);
        setNewMarketCurrencyOption(null);
      }
    }
  }, [newMarketCurrencyOption, trackingState, selectMarketCurrency]);

  return {
    switchMarketCurrency
  };
}
