import React from 'react';
import BithumbOrders from './bithumb/BithumbOrders';
import styled from 'styled-components';
import { Container } from 'components/ui/layout';

const Content = styled(Container)`
  padding-top: 50px;
`;

const ConsolePage = () => {
  return (
    <Content>
      <BithumbOrders />
    </Content>
  );
};

export default ConsolePage;
