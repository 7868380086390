import React from 'react';
import AgeLabel from './AgeLabel';
import styled from 'styled-components';
import { Card, Flex, Box } from 'components/ui/layout';
import { formatAmount, getAmountPrecision } from 'utils/format';

// Non-breaking space symbol:
const NBSP = '\xa0';
const FIRST_COLUMN_WIDTH = '60px';

const Column = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  margin-left: ${props => props.marginLeft};
`;

const Item = styled.li`
  text-align: ${props => (props.right ? 'right' : undefined)};
`;

const Balances = ({
  markets,
  marketCurrency,
  firstBalances,
  firstBaseCurrency,
  firstName,
  secondBalances,
  secondBaseCurrency,
  secondName
}) => {
  const {
    baseCurrencyBalance: firstBaseCurrencyBalance,
    marketCurrencyBalance: firstMarketCurrencyBalance,
    bitcoinBalance: firstBitcoinBalance,
    startTime: firstStartTime
  } = firstBalances || {};

  const {
    baseCurrencyBalance: secondBaseCurrencyBalance,
    marketCurrencyBalance: secondMarketCurrencyBalance,
    bitcoinBalance: secondBitcoinBalance,
    startTime: secondStartTime
  } = secondBalances || {};

  const marketCurrencyPrecision = getAmountPrecision(markets[marketCurrency]);
  const btcPrecision = getAmountPrecision(markets.BTC);
  const firstBasePrecision = getAmountPrecision(markets[firstBaseCurrency]);
  const secondBasePrecision = getAmountPrecision(markets[secondBaseCurrency]);

  return (
    <Box>
      <Box inlineFlexBox flexDirection="column">
        <Flex padding="0px 15px" marginBottom="4px">
          <Flex width={FIRST_COLUMN_WIDTH} />
          <Flex flex="1">
            <Flex width="50%" justifyContent="flex-end">
              {firstName}
            </Flex>
            <Flex width="50%" justifyContent="flex-end">
              {secondName}
            </Flex>
          </Flex>
        </Flex>
        <Flex fontSize="0.75em" marginBottom="2px">
          <Card style={{ minWidth: '350px' }} flexBox>
            <Flex width={FIRST_COLUMN_WIDTH}>
              <Column>
                <Item>Base</Item>
                <Item>Bitcoin</Item>
                <Item>Market&nbsp;coin</Item>
              </Column>
            </Flex>
            <Flex flex="1">
              <Flex width="50%" justifyContent="flex-end">
                <Column>
                  <Item right>
                    {formatAmount(
                      firstBaseCurrencyBalance,
                      firstBasePrecision,
                      ''
                    )}
                  </Item>
                  <Item right>
                    {formatAmount(firstBitcoinBalance, btcPrecision, '')}
                  </Item>
                  <Item right>
                    {formatAmount(
                      firstMarketCurrencyBalance,
                      marketCurrencyPrecision,
                      ''
                    )}
                  </Item>
                </Column>
                <Column marginLeft="10px">
                  <Item>{firstBaseCurrency || NBSP}</Item>
                  <Item>{marketCurrency ? 'BTC' : NBSP}</Item>
                  <Item>{marketCurrency || NBSP}</Item>
                </Column>
              </Flex>
              <Flex width="50%" justifyContent="flex-end">
                <Column>
                  <Item right>
                    {formatAmount(
                      secondBaseCurrencyBalance,
                      secondBasePrecision,
                      ''
                    )}
                  </Item>
                  <Item right>
                    {formatAmount(secondBitcoinBalance, btcPrecision, '')}
                  </Item>
                  <Item right>
                    {formatAmount(
                      secondMarketCurrencyBalance,
                      marketCurrencyPrecision,
                      ''
                    )}
                  </Item>
                </Column>
                <Column marginLeft="10px">
                  <Item>{secondBaseCurrency || NBSP}</Item>
                  <Item>{marketCurrency ? 'BTC' : NBSP}</Item>
                  <Item>{marketCurrency || NBSP}</Item>
                </Column>
              </Flex>
            </Flex>
          </Card>
        </Flex>
        <Flex padding="0px 15px">
          <Flex width={FIRST_COLUMN_WIDTH} />
          <Flex flex="1">
            <Flex width="50%" justifyContent="flex-end">
              <AgeLabel
                startTime={firstStartTime}
                useColor={{ greenLimit: 5000, orangeLimit: 25000 }}
              />
            </Flex>
            <Flex width="50%" justifyContent="flex-end">
              <AgeLabel
                startTime={secondStartTime}
                useColor={{ greenLimit: 5000, orangeLimit: 25000 }}
              />
            </Flex>
          </Flex>
        </Flex>
      </Box>
    </Box>
  );
};

export default Balances;
