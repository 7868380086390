import React from 'react';

// icomoon
export { Clock } from '@styled-icons/icomoon/Clock';
export { Checkmark } from '@styled-icons/icomoon/Checkmark';
export { Warning } from '@styled-icons/icomoon/Warning';
export { Cross } from '@styled-icons/icomoon/Cross';
export { Bell } from '@styled-icons/icomoon/Bell';

// fa-solid
export { CheckCircle } from '@styled-icons/fa-solid/CheckCircle';
export { ExclamationCircle } from '@styled-icons/fa-solid/ExclamationCircle';
export { Spinner } from '@styled-icons/fa-solid/Spinner';
export { UserCircle } from '@styled-icons/fa-solid/UserCircle';
export { DotCircle } from '@styled-icons/fa-solid/DotCircle';

// boxicons-solid
export { ArrowFromBottom } from '@styled-icons/boxicons-solid/ArrowFromBottom';
export { Circle } from '@styled-icons/boxicons-solid/Circle';

// boxicons-regular
export { CaretDown } from '@styled-icons/boxicons-regular/CaretDown';
export { CaretUp } from '@styled-icons/boxicons-regular/CaretUp';

// custom
export const Dot = ({ size, color }) => (
  <svg
    viewBox="0 0 16 16"
    height={size}
    width={size}
    aria-hidden="true"
    focusable="false"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    color={color}
  >
    <path fillRule="evenodd" d="M8 4a4 4 0 1 0 0 8 4 4 0 0 0 0-8z"></path>
  </svg>
);
