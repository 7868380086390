import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { colors } from 'ui';
import { matchPath, withRouter } from 'react-router';

const Wrapper = styled.div`
  display: flex;
`;

const NavLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`;

const Tab = styled.div`
  display: flex;
  align-items: center;
  color: inherit;
  line-height: ${props => props.height};
  height: ${props => props.height};
  padding: ${props => props.padding || '0 15px'};
  font-feature-settings: 'case' 1, 'ss12' 1;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 0.05em;
  font-size: 1.2rem;
  opacity: ${props => (props.active ? 0.7 : undefined)};
  transition: background-color 150ms ease, opacity 150ms ease;

  &:hover {
    background-color: ${props => (!props.active ? colors.overlay : undefined)};
  }
`;

const PrimaryNavTabs = ({
  height = '48px',
  routes = [],
  location: { pathname } = {}
}) => (
  <Wrapper>
    {routes.map((route, index) => (
      <NavLink key={index} to={route.url}>
        <Tab height={height} active={Boolean(matchPath(pathname, route))}>
          {route.name}
        </Tab>
      </NavLink>
    ))}
  </Wrapper>
);

export default withRouter(PrimaryNavTabs);
