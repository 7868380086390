const routes = {
  home: {
    name: 'Argo',
    path: '/',
    url: '/',
    exact: true
  },
  scanner: {
    name: 'Scanner',
    path: '/scanner',
    url: '/scanner',
    exact: false,
    currency: {
      name: 'Scanner Currency',
      path: '/scanner/:currency',
      exact: true
    }
  },
  console: {
    name: 'Console',
    path: '/console',
    url: '/console',
    exact: true
  },
  balances: {
    name: 'Balances',
    path: '/balances',
    url: '/balances',
    exact: true
  },
  wallets: {
    name: 'Wallets',
    path: '/wallets',
    url: '/wallets',
    exact: true
  },
  staking: {
    name: 'Staking',
    path: '/staking',
    url: '/staking',
    exact: true
  },
  margins: {
    id: 'margins',
    name: 'Margins',
    path: '/margins',
    url: '/margins',
    exact: true
  },
  settings: {
    name: 'Settings',
    path: '/settings',
    url: '/settings',
    exact: false,
    general: {
      name: 'General',
      path: '/settings/general',
      url: '/settings/general',
      exact: true
    },
    exchanges: {
      name: 'Exchanges',
      path: '/settings/exchanges',
      url: '/settings/exchanges',
      exact: true
    }
  },
  health: {
    name: 'Health',
    path: '/health',
    url: '/health',
    exact: false,
    proxies: {
      name: 'Proxies',
      path: '/health/proxies',
      url: '/health/proxies',
      exact: true
    }
  }
};

export default routes;
