import React from 'react';
import styled from 'styled-components';
import ms from 'ms';
import argoTime from 'middleware/argoTime';
import classes from 'classnames';
import { colors } from 'ui';
import { Clock } from 'components/ui/icons';
import { Box } from 'components/ui/layout';

const Wrapper = styled(Box)`
  display: flex;
  align-items: center;
  font-size: 0.9em;

  .age {
    margin-left: 5px;

    &.hidden {
      margin-left: 0px;
      width: 0px;
      visibility: hidden;
    }
  }
`;

const ClockIcon = styled(Clock)`
  width: 12px;
  height: 12px;
`;

const formatAge = age => {
  if (age <= 10000) {
    return (age / 1000).toFixed(2) + 's';
  } else {
    return ms(age);
  }
};

const getAgeColor = (age, useColor) => {
  if (useColor) {
    const { greenLimit = 1000, orangeLimit = 5000 } =
      typeof useColor === 'object' ? useColor : {};
    if (age <= greenLimit) {
      return colors.green;
    }
    if (age <= orangeLimit) {
      return colors.orange1;
    }
    return colors.red1;
  }
};

const AgeLabel = ({ startTime, useColor, onlyIcon }) => {
  if (startTime) {
    const age = argoTime.now() - startTime;
    const color = getAgeColor(age, useColor);
    return (
      <Wrapper color={color}>
        <ClockIcon />
        <span className={classes('age', { hidden: onlyIcon })}>
          {formatAge(age)}
        </span>
      </Wrapper>
    );
  }
  return <Wrapper>&nbsp;</Wrapper>;
};

export default AgeLabel;
