import React from 'react';
import styled from 'styled-components';
import TotalBalances from './TotalBalances';
import { Container } from 'components/ui/layout';

const Content = styled(Container)`
  padding-top: 50px;
`;

const BalancesPage = () => {
  return (
    <div>
      <Content>
        <TotalBalances />
      </Content>
    </div>
  );
};

export default BalancesPage;
