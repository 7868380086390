import { useState } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';

import getScanner from './graphql/getScanner.gql';
import startScanner from './graphql/startScanner.gql';
import stopScanner from './graphql/stopScanner.gql';

export function useScannerStartStop() {
  const [isStarting, setStarting] = useState(false);
  const [isStopping, setStopping] = useState(false);
  const [startMutation] = useMutation(startScanner);
  const [stopMutation] = useMutation(stopScanner);

  const { data = {}, loading, refetch } = useQuery(getScanner, {
    fetchPolicy: 'network-only'
  });

  const { scanner = {} } = data;
  const { isStarted } = scanner || {};

  async function start() {
    setStarting(true);
    await startMutation();
    await refetch();
    setStarting(false);
  }

  async function stop() {
    setStopping(true);
    await stopMutation();
    await refetch();
    setStopping(false);
  }

  return {
    loading,
    isStarted,
    isStarting,
    isStopping,
    start,
    stop
  };
}
