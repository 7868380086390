import { useState } from 'react';

export function useSwitch(defaultIsOn = false) {
  const [isOn, setOn] = useState(defaultIsOn);

  function toggle() {
    setOn(!isOn);
  }

  return { isOn, toggle };
}
