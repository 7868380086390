import React from 'react';
import styled from 'styled-components';
import SettingsPage from '../SettingsPage';
import Switch from 'components/ui/Switch';
import validator from 'validator';
import debounce from 'lodash.debounce';
import { useStoredSwitch } from 'pages/scanner/useStoredSwitch';
import { withScannerStartStop } from 'components/scanner/withScannerStartStop';
import { OutlineButton } from 'components/ui/buttons';
import { Container } from 'components/ui/layout';
import { TextInput } from 'components/ui/inputs';
import { compose } from 'utils/func';
import { graphql } from 'react-apollo';

import getSettings from 'components/settings/graphql/getSettings.gql';
import updateSetting from 'components/settings/graphql/updateSetting.gql';
import restartServices from 'components/settings/graphql/restartServices.gql';

const SAVE_DEBOUNCE_INTERVAL = 300;

const Content = styled(Container)`
  padding-top: 15px;
`;

const Field = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  width: 350px;
`;

function NegativeProtectionSwitch() {
  const negativeSwitch = useStoredSwitch('trade_entry_disable_negative');
  return <Switch isOn={negativeSwitch.isOn} onChange={negativeSwitch.toggle} />;
}

function DisplayPriceBandsSwitch() {
  const priceBandsSwitch = useStoredSwitch('trade_entry_display_price_bands');
  return (
    <Switch isOn={priceBandsSwitch.isOn} onChange={priceBandsSwitch.toggle} />
  );
}

class SettingsGeneralPage extends React.Component {
  state = { settings: {}, ready: false, servicesRestartScheduled: false };
  setStateFromQuery = () => {
    const { settings, loading, error } = this.props.data;
    if (loading === false && !error && !this.state.ready) {
      const {
        showOrderbooks,
        updateOrderbooksOnPause,
        basicTakerPriceMarginPercent,
        btcAmountLimitPercent,
        isPaper
      } = settings;
      this.setState({
        ready: true,
        settings: {
          showOrderbooks,
          updateOrderbooksOnPause,
          isPaper,
          basicTakerPriceMarginPercent: basicTakerPriceMarginPercent.toFixed(2),
          btcAmountLimitPercent: btcAmountLimitPercent.toFixed(2)
        }
      });
    }
  };
  save = debounce(({ name, value }) => {
    return this.props.updateSetting({ variables: { name, value } });
  }, SAVE_DEBOUNCE_INTERVAL);
  handleToggleShowOrderbooks = showOrderbooks => {
    this.setState(state => ({
      ...state,
      settings: { ...state.settings, showOrderbooks }
    }));
    this.props.updateSetting({
      variables: { name: 'showOrderbooks', value: showOrderbooks.toString() }
    });
  };
  handleToggleUpdateOrderbooksOnPause = updateOrderbooksOnPause => {
    this.setState(state => ({
      ...state,
      settings: { ...state.settings, updateOrderbooksOnPause }
    }));
    this.props.updateSetting({
      variables: {
        name: 'updateOrderbooksOnPause',
        value: updateOrderbooksOnPause.toString()
      }
    });
  };
  handleToggleIsPaper = isPaper => {
    this.setState(state => ({
      ...state,
      settings: { ...state.settings, isPaper }
    }));
    this.props.updateSetting({
      variables: { name: 'isPaper', value: isPaper.toString() }
    });
  };
  handleToggleScanner = isStarting => {
    const scanner = this.props.scannerStartStop;
    if (isStarting) {
      scanner.start();
    } else {
      scanner.stop();
    }
  };
  handleUpdateBasicTakerPriceMargin = ({ target: { value } }) => {
    this.setState(state => ({
      ...state,
      settings: {
        ...state.settings,
        basicTakerPriceMarginPercent: value
      }
    }));
    if (validator.isFloat(value.toString())) {
      this.save({
        name: 'basicTakerPriceMarginPercent',
        value: value.toString()
      });
    }
  };
  handleUpdateBtcAmountLimit = ({ target: { value } }) => {
    this.setState(state => ({
      ...state,
      settings: {
        ...state.settings,
        btcAmountLimitPercent: value
      }
    }));
    if (validator.isFloat(value.toString())) {
      this.save({
        name: 'btcAmountLimitPercent',
        value: value.toString()
      });
    }
  };
  handleRestartServicesClick = () => {
    this.props.restartServices().then(() => {
      this.setState({ servicesRestartScheduled: true });
    });
  };
  componentDidUpdate() {
    this.setStateFromQuery();
  }
  componentDidMount() {
    this.setStateFromQuery();
  }
  render() {
    const scanner = this.props.scannerStartStop;
    const {
      servicesRestartScheduled,
      settings: {
        showOrderbooks,
        updateOrderbooksOnPause,
        basicTakerPriceMarginPercent,
        btcAmountLimitPercent,
        isPaper
      } = {}
    } = this.state;
    return (
      <SettingsPage>
        <Content>
          {this.state.ready ? (
            <>
              <Field>
                <span>Paper trading</span>
                <Switch isOn={isPaper} onChange={this.handleToggleIsPaper} />
              </Field>
              <Field>
                <span>Show order books</span>
                <Switch
                  isOn={showOrderbooks}
                  onChange={this.handleToggleShowOrderbooks}
                />
              </Field>
              <Field>
                <span>Update order books on pause</span>
                <Switch
                  isOn={updateOrderbooksOnPause}
                  onChange={this.handleToggleUpdateOrderbooksOnPause}
                />
              </Field>
              <Field>
                <span>Scanner</span>
                <Switch
                  isOn={scanner.isStarted}
                  onChange={this.handleToggleScanner}
                />
              </Field>
              <Field>
                <span>Ignore Negative P&L Click</span>
                <NegativeProtectionSwitch />
              </Field>
              <Field>
                <span>Display Price Bands</span>
                <DisplayPriceBandsSwitch />
              </Field>
              <Field>
                <span>Basic taker price margin %</span>
                <TextInput
                  width="80px"
                  height="36px"
                  padding="0px 8px"
                  style={{ textAlign: 'right' }}
                  onChange={this.handleUpdateBasicTakerPriceMargin}
                  value={basicTakerPriceMarginPercent}
                />
              </Field>
              <Field>
                <span>BTC amount limit %</span>
                <TextInput
                  width="80px"
                  height="36px"
                  padding="0px 8px"
                  style={{ textAlign: 'right' }}
                  onChange={this.handleUpdateBtcAmountLimit}
                  value={btcAmountLimitPercent}
                />
              </Field>
              <div style={{ marginTop: '30px' }}>
                <Field>
                  <OutlineButton
                    onClick={this.handleRestartServicesClick}
                    disabled={servicesRestartScheduled}
                  >
                    {servicesRestartScheduled
                      ? 'Scheduled'
                      : 'Restart Services'}
                  </OutlineButton>
                </Field>
              </div>
            </>
          ) : (
            'loading...'
          )}
        </Content>
      </SettingsPage>
    );
  }
}

export default compose(
  graphql(getSettings),
  graphql(updateSetting, { name: 'updateSetting' }),
  graphql(restartServices, { name: 'restartServices' }),
  withScannerStartStop
)(SettingsGeneralPage);
