
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"Orderbook"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Orderbook"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"startTime"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"marketCurrency"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"bids"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"price"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"amount"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"cumulativeAmount"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"cumulativeTotal"},"arguments":[],"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"asks"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"price"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"amount"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"cumulativeAmount"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"cumulativeTotal"},"arguments":[],"directives":[]}]}}]}}],"loc":{"start":0,"end":215}};
    doc.loc.source = {"body":"fragment Orderbook on Orderbook {\n  startTime\n  marketCurrency\n  bids {\n    price\n    amount\n    cumulativeAmount\n    cumulativeTotal\n  }\n  asks {\n    price\n    amount\n    cumulativeAmount\n    cumulativeTotal\n  }\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
