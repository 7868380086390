import { css } from 'styled-components';

export const flexProps = ({
  alignItems,
  alignContent,
  justifyContent,
  flexWrap,
  flexBasis,
  flexDirection,
  flex,
  justifySelf,
  alignSelf
}) => css`
  align-items: ${alignItems};
  align-content: ${alignContent};
  justify-content: ${justifyContent};
  flex-wrap: ${flexWrap};
  flex-basis: ${flexBasis};
  flex-direction: ${flexDirection};
  flex: ${flex};
  justify-self: ${justifySelf};
  align-self: ${alignSelf};
`;
