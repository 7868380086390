import HomePage from 'pages/HomePage';
import ConsolePage from 'pages/console/ConsolePage';
import BalancesPage from 'pages/balances/BalancesPage';
import WalletsPage from 'pages/wallets/WalletsPage';
import MarginsPage from 'pages/margins/MarginsPage';
import StakingPage from 'pages/staking/StakingPage';
import ScannerPage from 'pages/scanner/ScannerPage';
import ScannerCurrencyPage from 'pages/scanner/ScannerCurrencyPage';
import HealthPageResolver from 'pages/health/HealthPageResolver';
import HealthProxiesPage from 'pages/health/proxies/HealthProxiesPage';
import SettingsPageResolver from 'pages/settings/SettingsPageResolver';
import SettingsGeneralPage from 'pages/settings/general/SettingsGeneralPage';
import SettingsExchangesPage from 'pages/settings/SettingsExchangesPage';
import routes from 'routes';

const routeConfig = [
  { route: routes.home, component: HomePage },
  { route: routes.scanner.currency, component: ScannerCurrencyPage },
  { route: routes.scanner, component: ScannerPage },
  { route: routes.console, component: ConsolePage },
  { route: routes.balances, component: BalancesPage },
  { route: routes.wallets, component: WalletsPage },
  { route: routes.margins, component: MarginsPage },
  { route: routes.health.proxies, component: HealthProxiesPage },
  { route: routes.health, component: HealthPageResolver },
  { route: routes.settings.general, component: SettingsGeneralPage },
  { route: routes.settings.exchanges, component: SettingsExchangesPage },
  { route: routes.settings, component: SettingsPageResolver },
  { route: routes.staking, component: StakingPage }
];

export default routeConfig;
