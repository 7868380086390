import React from 'react';
import { OutlineButton, PrimaryButton } from 'components/ui/buttons';
import { trackingStates } from './trackingStates';

const width = '70px';

class TrackButton extends React.Component {
  render() {
    const { onClick = () => {}, canStartTracking, trackingState } = this.props;
    if (trackingState === trackingStates.started) {
      return (
        <OutlineButton width={width} onClick={onClick}>
          Stop
        </OutlineButton>
      );
    }
    if (trackingState === trackingStates.starting) {
      return (
        <PrimaryButton width={width} disabled>
          …
        </PrimaryButton>
      );
    }
    if (trackingState === trackingStates.stopped) {
      return (
        <PrimaryButton
          width={width}
          onClick={onClick}
          disabled={!canStartTracking}
        >
          Start
        </PrimaryButton>
      );
    }
    if (trackingState === trackingStates.stopping) {
      return (
        <OutlineButton width={width} disabled>
          …
        </OutlineButton>
      );
    }
    if (trackingState === trackingStates.onHold) {
      return (
        <PrimaryButton
          width={width}
          onClick={onClick}
          disabled={!canStartTracking}
        >
          Re-Start
        </PrimaryButton>
      );
    }
    return null;
  }
}

export default TrackButton;
