import React from 'react';
import Select from 'components/Select';
import { Box } from 'components/ui/layout';

const ExchangeSelect = ({
  marketCurrency,
  options = [],
  exchangeId,
  excludeIds,
  isDisabled,
  onChange,
  ...containerProps
}) => {
  const filteredOptions = options.filter(
    ({ value }) => excludeIds.indexOf(value) === -1
  );
  const value =
    filteredOptions.find(({ value }) => value === exchangeId) || null;
  return (
    <Box {...containerProps}>
      <Select
        options={filteredOptions}
        value={value}
        isDisabled={isDisabled}
        onChange={onChange}
        isClearable
      />
    </Box>
  );
};

export default ExchangeSelect;
