export function haveSameKeys(objectA, objectB) {
  const keysA = Object.keys(objectA);
  const keysB = Object.keys(objectB);
  if (keysA.length === keysB.length) {
    for (const keyA of keysA) {
      if (!(keyA in objectB)) {
        return false;
      }
    }
    return true;
  }
  return false;
}
