import styled from 'styled-components';
import { colors } from 'ui';

export const TextInput = styled.input`
  height: ${props => props.height || '36px'};
  width: ${props => props.width || '100%'};
  color: ${colors.neutral80};
  background-color: ${colors.neutral0};
  border-color: ${colors.neutral20};
  border-radius: 0;
  border-style: solid;
  border-width: 1px;
  padding: ${props => props.padding || '0px 10px'};
  outline-style: none;
  font-family: inherit;
  font-size: inherit;
  transition: border-color 150ms ease;

  ::placeholder {
    color: ${colors.neutral50};
  }

  &:hover {
    border-color: ${colors.neutral40};
  }

  &:focus {
    border-color: ${colors.primary};
  }
`;
