export const sortDirs = {
  none: null,
  asc: 'ASC',
  desc: 'DESC'
};

export const sortColumns = {
  none: null,
  market: 'BASE',
  balance: 'BALANCE'
};
