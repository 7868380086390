import React from 'react';
import routes from 'routes';
import classes from 'classnames';
import argoTime from 'middleware/argoTime';
import VolumeBar from './VolumeBar';
import AgeLabel from 'components/dashboard/AgeLabel';
import { Wrapper } from './scannerStyles';
import { Link } from 'react-router-dom';
import { OutlineButton } from 'components/ui/buttons';
import { useParams } from 'react-router';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import { formatPrice, formatAmount } from 'utils/format';
import { getAmountPrecision } from 'utils/format';
import { useStoredSwitch } from './useStoredSwitch';
import { useSwitch } from './useSwitch';
import { useCollapsed } from './useCollapsed';
import { colors } from 'ui';

import getScannerCurrencyGql from './graphql/getScannerCurrency.gql';
import startTrackingGql from 'components/dashboard/graphql/startArbitrageTracking.gql';

function getLim(exchanges) {
  const lims = exchanges.map(({ lim }) => lim);
  return Math.max(...lims);
}

function shape(data) {
  const {
    scanner = {},
    market = {},
    btcMarket = {},
    accountCurrencyBalances = {}
  } = data;
  const { arbitrageTracking = {} } = data;
  const {
    marketChainSpreads = [],
    marketChainExchanges = [],
    exchangeBalances = [],
    settings = {},
    disabledChains = []
  } = scanner;

  const chains = {};
  const balances = {};

  for (const item of exchangeBalances) {
    balances[item.exchangeId] = item;
  }

  for (const chain of marketChainExchanges) {
    const chainName = chain.chain || 'UNCHAINED';
    const exchanges = chain.exchanges;
    const sxData = {};
    const bxData = {};

    for (const item of exchanges) {
      // Make sure we have balances for the exchange
      // Otherwise we can’t display it and it would crash
      if (Boolean(balances[item.exchangeId])) {
        sxData[item.exchangeId] = {
          lim: item.lim,
          amount: item.sellAmount,
          bestPrice: item.sellBestPrice,
          bestPriceFiat: item.sellBestPriceFiat,
          startTime: item.startTime
        };
        bxData[item.exchangeId] = {
          lim: item.lim,
          amount: item.buyAmount,
          bestPrice: item.buyBestPrice,
          bestPriceFiat: item.buyBestPriceFiat,
          startTime: item.startTime
        };
      }
    }

    const exchangeIds = Object.keys(sxData);
    if (exchangeIds.length > 0) {
      chains[chainName] = {
        name: chainName,
        lim: getLim(exchanges),
        exchangeIds,
        spreads: {},
        sxData,
        bxData
      };
    }
  }

  for (const chain of marketChainSpreads) {
    const chainName = chain.chain || 'UNCHAINED';
    const spreads = {};

    if (!chains[chainName]) {
      continue;
    }

    for (const spread of chain.spreads) {
      const { sxId, bxId, spreadPercent } = spread;
      if (!spreads[sxId]) {
        spreads[sxId] = {};
      }
      spreads[sxId][bxId] = spreadPercent;
    }

    chains[chainName].spreads = spreads;
  }

  return {
    chains: Object.values(chains),
    disabledChains,
    balances,
    market,
    btcMarket,
    accountCurrencyBalances,
    arbitrageTracking,
    settings
  };
}

function getExchangeDisplayName(exchangeId) {
  const [name, baseCurrency] = exchangeId.split(':');
  const isFiat = baseCurrency !== 'BTC';
  const displayName = isFiat ? `${name} – ${baseCurrency}` : name;
  return displayName;
}

function ExchangeCell({
  exchangeId,
  data,
  balances,
  market,
  btcMarket,
  settings,
  sx = false,
  isBalancesOn = false,
  isTimeOn = false
}) {
  const { lim, amount, bestPrice, bestPriceFiat, startTime } = data;
  const ratio = lim > 0 ? amount / lim : 0;
  const displayName = getExchangeDisplayName(exchangeId);
  const balance = sx ? balances.balance : balances.balanceBtc;
  const precision = getAmountPrecision(sx ? market : btcMarket);
  const displayBalance = formatAmount(balance, precision);
  const isPositiveBalance = balance > precision;
  const age = argoTime.now() - startTime;
  const isOutdated = age > settings.orangeLimit;
  const exchangeNameColor = isOutdated ? colors.red1 : undefined;
  const balanceColor = isPositiveBalance ? colors.bodyText : undefined;
  const useColor = {
    greenLimit: settings.greenLimit,
    orangeLimit: settings.orangeLimit
  };

  return (
    <td className="ex">
      {sx ? (
        <div className="container" style={{ minHeight: '44px' }}>
          <div className="progress-bar-container">
            <VolumeBar value={ratio} />
          </div>
          <div className={classes('ex-and-prices sx')}>
            <div className="ex-name">
              <span style={{ color: exchangeNameColor }}>{displayName}</span>
              {isTimeOn ? (
                <AgeLabel startTime={startTime} useColor={useColor} />
              ) : null}
            </div>
            {isBalancesOn ? (
              <div className="prices-balances">
                <div className="price">&nbsp;</div>
                <div className="price" style={{ color: balanceColor }}>
                  {displayBalance}
                </div>
              </div>
            ) : (
              <div className="prices-balances">
                <div className="price">{formatPrice(bestPrice)}</div>
                {bestPriceFiat ? (
                  <div className="price">{formatPrice(bestPriceFiat)}</div>
                ) : null}
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="container">
          <div className="progress-bar-container">
            <VolumeBar value={ratio} />
          </div>
          <div className={classes('ex-and-prices bx')}>
            <div className="ex-name" style={{ color: exchangeNameColor }}>
              {displayName}
            </div>
            {isBalancesOn ? (
              <div className="prices-balances">
                <div className="price">{displayBalance}</div>
              </div>
            ) : (
              <div className="prices-balances">
                <div className="price">{formatPrice(bestPrice)}</div>
                {bestPriceFiat ? (
                  <div className="price">{formatPrice(bestPriceFiat)}</div>
                ) : null}
              </div>
            )}
          </div>
          <div className="bx-age-label-container">
            {isTimeOn ? (
              <AgeLabel
                startTime={startTime}
                useColor={useColor}
                onlyIcon={!isTimeOn}
              />
            ) : null}
          </div>
        </div>
      )}
    </td>
  );
}

function getSpreadColor(spreadPercent, settings, sxItem, bxItem) {
  if (spreadPercent > 0) {
    const { startTime: sxStartTime } = sxItem;
    const { startTime: bxStartTime } = bxItem;
    const { orangeLimit } = settings;
    const now = argoTime.now();
    const sxAge = now - sxStartTime;
    const bxAge = now - bxStartTime;
    const isOutdated = sxAge > orangeLimit || bxAge > orangeLimit;
    if (isOutdated) {
      return colors.red1;
    } else {
      return colors.green;
    }
  }
  return colors.neutral20;
}

function TopLeftCell({
  lim,
  market,
  btcMarket,
  accountCurrencyBalances: { totalTotalAmount, totalTotalAmountBtc }
}) {
  const btcPrecision = getAmountPrecision(btcMarket);
  const precision = getAmountPrecision(market);
  const btcAmount = formatAmount(totalTotalAmountBtc, btcPrecision);
  const amount = formatAmount(totalTotalAmount, precision);
  const limAmount = formatAmount(lim, precision);
  return (
    <td>
      <div className="account-balance">
        {amount} ({btcAmount})
      </div>
      <div className="lim secondary">LIM {limAmount}</div>
    </td>
  );
}

function CrossTable({
  chain,
  spreads,
  balances,
  sxData,
  bxData,
  lim,
  exchangeIds,
  market,
  btcMarket,
  accountCurrencyBalances,
  settings,
  isBalancesOn,
  isTimeOn,
  isMergedOn,
  onPnlClick
}) {
  const count = Object.keys(sxData).length;
  const { isCollapsed, toggleCollapsed } = useCollapsed();

  function handlePnlClick(balances, sxId, bxId) {
    function pickBestTradeExchangeId(options) {
      if (options.length > 0) {
        const list = options
          .map(({ exchangeId, balance }) => ({
            exchangeId,
            balance
          }))
          .sort((a, b) => b.balance - a.balance);
        return list[0].exchangeId;
      }
      return null;
    }

    // Exchange displayed in the cell (sx or bx)
    // May not be the best one to actually trade at.
    // For example, there are multiple exchange hiding behind “upbit:BTC”
    // We chose the one with the highest coin balance
    const sxOptions = balances[sxId].tradeExchangeOptions;
    const bxOptions = balances[bxId].tradeExchangeOptions;
    const tradeSxId = pickBestTradeExchangeId(sxOptions) || sxId;
    const tradeBxId = pickBestTradeExchangeId(bxOptions) || bxId;

    if (onPnlClick) {
      onPnlClick(tradeSxId, tradeBxId);
    }
  }

  return (
    <section className="chain">
      <h3 className="header" onClick={toggleCollapsed}>
        {chain} ({count}) {isCollapsed ? '+' : ''}
      </h3>
      {isCollapsed ? null : (
        <table className={classes({ merged: isMergedOn })}>
          <tbody>
            <tr>
              <TopLeftCell
                lim={lim}
                market={market}
                btcMarket={btcMarket}
                accountCurrencyBalances={accountCurrencyBalances}
              />
              {exchangeIds.map(bxId => {
                const bxItem = bxData[bxId];
                return (
                  <ExchangeCell
                    key={bxId}
                    exchangeId={bxId}
                    data={bxItem}
                    balances={balances[bxId]}
                    market={market}
                    btcMarket={btcMarket}
                    settings={settings}
                    isBalancesOn={isBalancesOn}
                    isTimeOn={isTimeOn}
                  />
                );
              })}
            </tr>
            {exchangeIds.map(sxId => {
              const sxItem = sxData[sxId];
              return (
                <tr key={sxId}>
                  <ExchangeCell
                    sx
                    exchangeId={sxId}
                    data={sxItem}
                    balances={balances[sxId]}
                    market={market}
                    btcMarket={btcMarket}
                    settings={settings}
                    isBalancesOn={isBalancesOn}
                    isTimeOn={isTimeOn}
                  />
                  {exchangeIds.map(bxId => {
                    const bxItem = bxData[bxId];
                    const spreadPercent = spreads[sxId]
                      ? spreads[sxId][bxId]
                      : null;
                    const spreadColor = getSpreadColor(
                      spreadPercent,
                      settings,
                      sxItem,
                      bxItem
                    );
                    return (
                      <td key={bxId} className="pnl-cell">
                        {Number.isFinite(spreadPercent) && bxId !== sxId ? (
                          <div className="pnl-container">
                            <span
                              className="pnl"
                              style={{ color: spreadColor }}
                              onClick={() =>
                                handlePnlClick(balances, sxId, bxId)
                              }
                            >
                              {spreadPercent.toFixed(2) + '%'}
                            </span>
                          </div>
                        ) : null}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </section>
  );
}

function InfoSection({ market: { comment1, comment2 } = {} }) {
  const { isCollapsed, toggleCollapsed } = useCollapsed({
    defaultCollapsed: false
  });
  return comment1 || comment2 ? (
    <section className="chain">
      <h3 className="header" onClick={toggleCollapsed}>
        Info {isCollapsed ? '+' : ''}
      </h3>
      {isCollapsed ? null : (
        <div>
          {comment1 ? <p className="comment">{comment1}</p> : null}
          {comment2 ? <p className="comment">{comment2}</p> : null}
        </div>
      )}
    </section>
  ) : null;
}

function DisabledSection({ disabledChains }) {
  const count = disabledChains.length;
  const { isCollapsed, toggleCollapsed } = useCollapsed({
    defaultCollapsed: false
  });
  if (count > 0) {
    return (
      <section className="chain">
        <h3 className="header" onClick={toggleCollapsed}>
          Disabled ({count}) {isCollapsed ? '+' : ''}
        </h3>
        {isCollapsed ? null : (
          <table>
            <tbody>
              {disabledChains.map(({ platformId, reason, chains }) => (
                <tr key={platformId}>
                  <td>{platformId}</td>
                  <td className="secondary right">{chains}</td>
                  <td className="secondary">{reason}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </section>
    );
  }
  return null;
}

function ScannerCurrencyPage() {
  const history = useHistory();
  const [startTrackingMutation] = useMutation(startTrackingGql);
  const { currency } = useParams();
  const balancesSwitch = useStoredSwitch('scanner_balances_on');
  const navigateSwitch = useStoredSwitch('scanner_navigate_on');
  const timeSwitch = useStoredSwitch('scanner_time_on');
  const mergedSwitch = useSwitch(false);

  const { data = {} } = useQuery(getScannerCurrencyGql, {
    fetchPolicy: 'network-only',
    pollInterval: 250,
    notifyOnNetworkStatusChange: true,
    variables: {
      symbol: currency,
      merged: mergedSwitch.isOn
    }
  });

  const {
    chains,
    balances,
    market,
    btcMarket,
    settings,
    disabledChains,
    accountCurrencyBalances,
    arbitrageTracking = {}
  } = shape(data);

  async function handlePnlClick(sxId, bxId) {
    await startTrackingMutation({
      variables: {
        marketCurrency: currency.toUpperCase(),
        firstExchangeId: sxId,
        secondExchangeId: bxId
      }
    });
    if (navigateSwitch.isOn) {
      history.push('/');
    }
  }

  const {
    isStarted,
    marketCurrency: trackingMarketCurrency
  } = arbitrageTracking;

  return (
    <Wrapper>
      <header>
        <nav>
          <ul>
            <li>
              <Link to={routes.scanner.url}>Scanner</Link>
            </li>
            <li>{currency}</li>
          </ul>
          <div className="toolbar">
            <div className="toolbar-group">
              <OutlineButton
                active={balancesSwitch.isOn}
                onClick={balancesSwitch.toggle}
              >
                Balances
              </OutlineButton>
              <OutlineButton
                active={navigateSwitch.isOn}
                onClick={navigateSwitch.toggle}
              >
                To Argo
              </OutlineButton>
              <OutlineButton
                active={timeSwitch.isOn}
                onClick={timeSwitch.toggle}
              >
                Time
              </OutlineButton>
              <OutlineButton
                active={mergedSwitch.isOn}
                onClick={mergedSwitch.toggle}
              >
                Merged
              </OutlineButton>
            </div>
            <div className="toolbar-group">
              {isStarted ? (
                <span className="secondary">
                  Tracking{' '}
                  <span className="primary">{trackingMarketCurrency}</span>
                </span>
              ) : (
                <span className="secondary">Tracking stopped</span>
              )}
            </div>
          </div>
        </nav>
      </header>
      <main className="currency">
        <div className="grid">
          <InfoSection market={market} />
          <DisabledSection disabledChains={disabledChains} />
        </div>
        <div>
          {chains.map(chain => (
            <CrossTable
              key={chain.name}
              chain={chain.name}
              spreads={chain.spreads}
              sxData={chain.sxData}
              bxData={chain.bxData}
              lim={chain.lim}
              balances={balances}
              market={market}
              btcMarket={btcMarket}
              accountCurrencyBalances={accountCurrencyBalances}
              isBalancesOn={balancesSwitch.isOn}
              isTimeOn={timeSwitch.isOn}
              isMergedOn={mergedSwitch.isOn}
              exchangeIds={chain.exchangeIds}
              settings={settings}
              onPnlClick={handlePnlClick}
            />
          ))}
        </div>
      </main>
    </Wrapper>
  );
}

export default ScannerCurrencyPage;
