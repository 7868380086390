export default {
  id: arg => arg,
  isTruthy: arg => Boolean(arg),
  isFalsy: arg => !Boolean(arg),
  noop: (...args) => {},
  true: (...args) => true
};

const compose2 = (f, g) => (...args) => f(g(...args));

export const compose = (...fns) => fns.reduce(compose2);
export const pipe = (...fns) => fns.reduceRight(compose2);

export const flip = f => (...args) => f(...args.reverse());
