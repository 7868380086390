import React, { useState } from 'react';
import GridSection from 'components/dashboard/GridSection';
import ArbitrageHeader from './ArbitrageHeader';
import ArbitrageFooter from './ArbitrageFooter';
import ResultsTable from './ResultsTable';
import { Card } from 'components/ui/layout';
import { useBalances } from 'components/dashboard/useBalances';

function calculatePnl(trade, prices, sellExchange, buyExchange) {
  const sxPnlMultiplier = 1 - sellExchange.pnlFeePercent / 100;
  const bxPnlMultiplier = 1 - buyExchange.pnlFeePercent / 100;
  const pnlMultiplier = sxPnlMultiplier * bxPnlMultiplier;
  const sxEntry = trade.sellExchangeEntryBalances;
  const sxExit = trade.sellExchangeResultBalances;
  const bxEntry = trade.buyExchangeEntryBalances;
  const bxExit = trade.buyExchangeResultBalances;

  function getAlt(entry, exit) {
    return exit.marketCurrencyBalance - entry.marketCurrencyBalance;
  }

  function getBtc(entry, exit) {
    return exit.bitcoinBalance - entry.bitcoinBalance;
  }

  function getBase(entry, exit) {
    return exit.baseCurrencyBalance - entry.baseCurrencyBalance;
  }

  const alt = getAlt(sxEntry, sxExit) + getAlt(bxEntry, bxExit);
  const btc = getBtc(sxEntry, sxExit) + getBtc(bxEntry, bxExit);
  const sxBase = getBase(sxEntry, sxExit);
  const bxBase = getBase(bxEntry, bxExit);

  return (
    pnlMultiplier *
    (btc +
      alt * prices.marketCurrencyPrice +
      sxBase * prices.sellExchangeBasePrice +
      bxBase * prices.buyExchangeBasePrice)
  );
}

function Arbitrage(props) {
  const [enableBalances, setEnableBalances] = useState(false);

  const {
    id,
    status,
    pnl,
    prices = {},
    startTime,
    marketCurrency,
    firstExchangeId,
    secondExchangeId,
    sellExchange,
    sellExchangeEntryBalances = {},
    sellExchangeResultBalances = {},
    buyExchange,
    buyExchangeEntryBalances = {},
    buyExchangeResultBalances = {}
  } = props.arbitrage || {};

  function isFiatExchange({ baseCurrency } = {}) {
    return baseCurrency !== 'BTC';
  }

  function isSellExchangeFirst() {
    return firstExchangeId === sellExchange.id;
  }

  const [{ firstBalances = {}, secondBalances = {} }] = useBalances({
    enableBalances,
    marketCurrency,
    firstExchangeId,
    secondExchangeId
  });

  if (!props.arbitrage) {
    return null;
  }

  let first = null;
  let firstEntryBalances = null;
  let firstResultBalances = null;
  let second = null;
  let secondEntryBalances = null;
  let secondResultBalances = null;
  let trade = null;
  if (isSellExchangeFirst()) {
    first = sellExchange;
    firstEntryBalances = sellExchangeEntryBalances;
    firstResultBalances = enableBalances
      ? firstBalances
      : sellExchangeResultBalances;
    second = buyExchange;
    secondEntryBalances = buyExchangeEntryBalances;
    secondResultBalances = enableBalances
      ? secondBalances
      : buyExchangeResultBalances;
    trade = {
      sellExchangeEntryBalances,
      buyExchangeEntryBalances,
      sellExchangeResultBalances: firstResultBalances,
      buyExchangeResultBalances: secondResultBalances
    };
  } else {
    first = buyExchange;
    firstEntryBalances = buyExchangeEntryBalances;
    firstResultBalances = enableBalances
      ? firstBalances
      : buyExchangeResultBalances;
    second = sellExchange;
    secondEntryBalances = sellExchangeEntryBalances;
    secondResultBalances = enableBalances
      ? secondBalances
      : sellExchangeResultBalances;
    trade = {
      sellExchangeEntryBalances,
      buyExchangeEntryBalances,
      sellExchangeResultBalances: secondResultBalances,
      buyExchangeResultBalances: firstResultBalances
    };
  }

  function toggleEnableBalances() {
    setEnableBalances(enableBalances => !enableBalances);
  }

  const displayedPnl = enableBalances
    ? calculatePnl(trade, prices, sellExchange, buyExchange)
    : pnl;

  return (
    <GridSection header={id ? `Trade #${id}` : 'Trade …'} startTime={startTime}>
      <Card height="275px" padding="0">
        <ArbitrageHeader
          pnl={displayedPnl}
          status={status}
          markets={props.markets}
        />
        <ResultsTable
          markets={props.markets}
          marketCurrency={marketCurrency}
          firstIsFiat={isFiatExchange(first)}
          firstName={first.name}
          firstBaseCurrency={first.baseCurrency}
          firstEntryBalances={firstEntryBalances}
          firstResultBalances={firstResultBalances}
          firstBalances={firstBalances}
          secondIsFiat={isFiatExchange(second)}
          secondName={second.name}
          secondBaseCurrency={second.baseCurrency}
          secondEntryBalances={secondEntryBalances}
          secondResultBalances={secondResultBalances}
          secondBalances={secondBalances}
        />
        <ArbitrageFooter
          enableBalances={enableBalances}
          onEnableBalancesChange={toggleEnableBalances}
        />
      </Card>
    </GridSection>
  );
}

export default Arbitrage;
