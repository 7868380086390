import { useState } from 'react';

export function useCollapsed({ defaultCollapsed = false } = {}) {
  const [isCollapsed, setCollapsed] = useState(defaultCollapsed);

  function toggleCollapsed() {
    setCollapsed(!isCollapsed);
  }

  return {
    isCollapsed,
    toggleCollapsed
  };
}
