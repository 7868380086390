import { useQuery } from '@apollo/react-hooks';

import getBalancesGql from './graphql/getBalances.gql';

export function useBalances({
  enableBalances,
  marketCurrency,
  firstExchangeId,
  secondExchangeId
}) {
  const { data = {}, loading } = useQuery(getBalancesGql, {
    fetchPolicy: 'network-only',
    skip: !enableBalances,
    pollInterval: 1000,
    notifyOnNetworkStatusChange: true,
    variables: {
      marketCurrency,
      firstExchangeId,
      secondExchangeId
    }
  });
  return [data, loading];
}
