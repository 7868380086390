import { useState } from 'react';

export function useStoredSwitch(key) {
  const [isOn, setOn] = useState(localStorage.getItem(key) === 'true');

  function toggle() {
    setOn(!isOn);
    localStorage.setItem(key, JSON.stringify(!isOn));
  }

  return { isOn, toggle };
}
