import React from 'react';
import StatusLabel from './StatusLabel';
import StatusIcon from './StatusIcon';
import styled from 'styled-components';
import classes from 'classnames';
import { Flex } from 'components/ui/layout';
import { colors } from 'ui';
import { formatAmount, getAmountPrecision } from 'utils/format';
import { copyToClipboard } from 'utils/clipboard';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px;
  height: 36px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  background-color: ${colors.neutral10};

  .pnl {
    font-weight: bold;
    line-height: 1;
    font-size: 1rem;
    transition: opacity 150ms ease;

    &:hover {
      opacity: 0.8;
      cursor: pointer;
    }

    &.green {
      color: ${colors.green};
    }

    &.red {
      color: ${colors.red1};
    }
  }
`;

const ArbitrageHeader = ({ pnl, status, markets }) => {
  const btcPrecision = getAmountPrecision(markets.BTC);
  const pnlFormatted = formatAmount(pnl, btcPrecision);
  return (
    <Wrapper>
      <div
        className={classes('pnl', { red: pnl < 0, green: pnl > 0 })}
        onClick={() => copyToClipboard(pnlFormatted)}
      >
        {pnlFormatted}
      </div>
      <Flex alignItems="center">
        <StatusLabel status={status} />
        <StatusIcon status={status} />
      </Flex>
    </Wrapper>
  );
};

export default ArbitrageHeader;
