import React from 'react';
import styled from 'styled-components';
import classes from 'classnames';
import AgeLabel from 'components/dashboard/AgeLabel';
import WalletBalancesTableContainer from './WalletBalancesTableContainer';
import AutoFocusTextInput from 'components/ui/AutoFocusTextInput';
import { Checkmark, Warning, CaretDown, CaretUp } from 'components/ui/icons';
import { colors } from 'ui';
import { Container } from 'components/ui/layout';
import { Box, Card, Flex } from 'components/ui/layout';
import { useMarketSearch } from 'components/useMarketSearch';
import { useMarkets } from 'components/data/useMarkets';
import { useWalletBalances } from './useWalletBalances';
import { formatAmount, getAmountPrecision } from 'utils/format';
import { sortDirs, sortColumns } from './walletBalancesSorting';

const CaretDownIcon = styled(CaretDown)`
  width: 14px;
  height: 14px;
`;

const CaretUpIcon = styled(CaretUp)`
  width: 14px;
  height: 14px;
`;

const Wrapper = styled(Container)`
  padding-top: 15px;
  display: flex;
  flex-wrap: wrap;

  .sorting-header {
    cursor: pointer;
    display: inline-block;
    transition: color 200ms ease;

    &:hover {
      color: ${colors.neutral90};
    }
  }

  .green {
    color: ${colors.green};
  }

  .orange {
    color: ${colors.orange1};
  }

  .red {
    color: ${colors.red1};
  }

  table {
    display: block;
    overflow: auto;

    font-feature-settings: 'tnum' 1, 'lnum' 1;
    border-collapse: collapse;
    width: 100%;

    td,
    th {
      height: 30px;

      &.v-center {
        display: flex;
        align-items: center;
      }

      &.center {
        text-align: center;
      }

      &.right {
        text-align: right;
      }
    }

    tfoot {
      td {
        padding-top: 25px;
      }
    }

    thead {
      th {
        position: sticky;
        top: 0px;
        background: ${colors.neutral5};
      }
    }

    tbody {
      tr {
        transition: background-color 100ms ease-in;

        &:hover {
          background-color: ${colors.neutral10};
        }
      }
    }

    th {
      text-align: left;
      padding: 6px 8px;
      vertical-align: top;
      white-space: nowrap;
    }

    td {
      padding: 6px 8px;
    }
  }
`;

function SortingHeader({
  children,
  column,
  sortColumn,
  sortDirection,
  toggleSorting,
  className,
  left = false
}) {
  let sortingSymbol = null;
  if (column === sortColumn) {
    switch (sortDirection) {
      case sortDirs.asc:
        sortingSymbol = <CaretUpIcon />;
        break;
      case sortDirs.desc:
        sortingSymbol = <CaretDownIcon />;
        break;
      default:
        break;
    }
  }
  return (
    <div
      className={classes('sorting-header', className)}
      onClick={() => toggleSorting(column)}
    >
      {left ? (
        <>
          {sortingSymbol} {children}
        </>
      ) : (
        <>
          {children} {sortingSymbol}
        </>
      )}
    </div>
  );
}

function WalletBalances({ platformId }) {
  const [markets] = useMarkets();
  const {
    walletBalances,
    sortColumn,
    sortDirection,
    toggleSorting
  } = useWalletBalances({ platformId });

  const { searchedItems, searchText, updateSearchText } = useMarketSearch({
    items: walletBalances
  });

  return (
    <Wrapper>
      <Box>
        <Flex
          marginBottom="8px"
          justifyContent="space-between"
          alignItems="center"
        >
          Balances
          <Box>
            <AutoFocusTextInput
              key={platformId}
              placeholder="Search market…"
              style={{ paddingBottom: '1px' }}
              value={searchText}
              onChange={event => updateSearchText(event.target.value)}
            />
          </Box>
        </Flex>
        <WalletBalancesTableContainer key={platformId}>
          {({ height }) => {
            const tableHeight = height - (height % 30);
            return (
              <Box inlineFlexBox flexDirection="column">
                <Flex fontSize="0.75em">
                  <Card width="600px" flexBox>
                    <table style={{ height: tableHeight }}>
                      <thead>
                        <tr>
                          <th>
                            <SortingHeader
                              column={sortColumns.market}
                              sortColumn={sortColumn}
                              sortDirection={sortDirection}
                              toggleSorting={toggleSorting}
                            >
                              Market
                            </SortingHeader>
                          </th>
                          <th className="right">
                            <SortingHeader
                              left
                              column={sortColumns.balance}
                              sortColumn={sortColumn}
                              sortDirection={sortDirection}
                              toggleSorting={toggleSorting}
                            >
                              Balance
                            </SortingHeader>
                          </th>
                          <th style={{ width: '100%' }}></th>
                          <th></th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {searchedItems.map(item => {
                          const marketCurrency = item.marketCurrency;
                          const balance = item.balance;
                          const startTime = item.startTime;
                          const hasError = item.hasError;
                          const amountPrecision = getAmountPrecision(
                            markets[marketCurrency]
                          );
                          return (
                            <tr key={marketCurrency}>
                              <td>{marketCurrency}</td>
                              <td className="right">
                                {formatAmount(balance, amountPrecision)}
                              </td>
                              <td></td>
                              <td className="right" style={{ width: '120px' }}>
                                <Flex justifyContent="flex-end">
                                  <AgeLabel startTime={startTime} />
                                </Flex>
                              </td>
                              <td className="v-center">
                                {hasError ? (
                                  <Warning size={12} color={colors.red1} />
                                ) : (
                                  <Checkmark size={12} color={colors.green} />
                                )}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </Card>
                </Flex>
              </Box>
            );
          }}
        </WalletBalancesTableContainer>
      </Box>
    </Wrapper>
  );
}

export default WalletBalances;
