import { useState, useEffect } from 'react';
import { useWalletBalancesPlatforms } from './useWalletBalancesPlatforms';

export function useWallets() {
  const [platformId, setPlatformId] = useState(null);
  const { platforms } = useWalletBalancesPlatforms();

  useEffect(() => {
    if (platformId === null && platforms.length > 0) {
      const firstPlatformId = platforms[0].id;
      setPlatformId(firstPlatformId);
    }
  }, [platforms, platformId]);

  function selectPlatform(platformId) {
    setPlatformId(platformId);
  }

  return {
    platformId,
    selectPlatform,
    platforms
  };
}
