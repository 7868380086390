import { css } from 'styled-components';
import { colors } from 'ui/colors';

export const borderProps = ({
  border,
  borderBottom,
  borderTop,
  borderLeft,
  borderRight,
  noBorderBottom,
  noBorderTop,
  noBorderLeft,
  noBorderRight,
  borderWidth
}) => css`
  ${border && `border: ${borderWidth || '1px'} solid ${colors.border}`};

  ${borderBottom &&
  `border-bottom: ${borderWidth || '1px'} solid ${colors.border}`};
  ${noBorderBottom && 'border-bottom: 0'};

  ${borderTop && `border-top: ${borderWidth || '1px'} solid ${colors.border}`};
  ${noBorderTop && 'border-top: 0'};

  ${borderLeft &&
  `border-left: ${borderWidth || '1px'} solid ${colors.border}`};
  ${noBorderLeft && 'border-left: 0'};

  ${borderRight &&
  `border-right: ${borderWidth || '1px'} solid ${colors.border}`};
  ${noBorderRight && 'border-right: 0'};
`;
