import React, { useState } from 'react';
import styled from 'styled-components';
import MarginAccountSelect from './MarginAccountSelect';
import Margins from './Margins';
import { colors } from 'ui';
import { Container } from 'components/ui/layout';
import { Field, FieldLabel } from 'components/ui/fields';

const Toolbar = styled(Container)`
  display: flex;
  justify-content: flex-start;
  height: 110px;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: ${colors.backgroundLighter};

  .fields {
    display: flex;
    align-items: flex-end;
  }
`;

function MarginsPage() {
  const [accountName, setAccountName] = useState('Binance');

  function changeAccountName({ value }) {
    setAccountName(value);
  }

  return (
    <div>
      <Toolbar>
        <div className="fields">
          <Field>
            <FieldLabel>Account Name</FieldLabel>
            <MarginAccountSelect
              accountName={accountName}
              onChange={changeAccountName}
            />
          </Field>
        </div>
      </Toolbar>
      <Margins accountName={accountName} />
    </div>
  );
}

export default MarginsPage;
