import styled from 'styled-components';
import {
  colors,
  sizeProps,
  spaceProps,
  colorProps,
  textProps,
  flexProps,
  positionProps,
  borderProps
} from 'ui';

const getFlexDisplay = props => {
  if (props.flexBox) {
    return 'flex';
  }
  if (props.inlineFlexBox) {
    return 'inline-flex';
  }
};

const Box = styled.div`
  display: ${props => getFlexDisplay(props)};
  ${sizeProps};
  ${spaceProps};
  ${colorProps};
  ${textProps};
  ${positionProps};
  ${borderProps};
  ${flexProps};
`;

const Flex = styled(Box).attrs({ flexBox: true })``;

const Absolute = styled(Box).attrs({ position: 'absolute' })``;

const Relative = styled(Box).attrs({ position: 'relative' })``;

const Card = styled(Box)`
  padding: ${props => props.padding || '10px 15px'};
  border-radius: 3px;
  background-color: ${colors.neutral5};
`;

const Container = styled.div`
  padding-left: 35px;
  padding-right: 35px;
`;

export { Box, Flex, Absolute, Relative, Card, Container };
