import { useState, useEffect } from 'react';
import * as JsSearch from 'js-search';

export function useMarketSearch({ items }) {
  const [searchText, setSearchText] = useState('');
  const [search, setSearch] = useState(null);

  function createSearch(documents = []) {
    const search = new JsSearch.Search('marketCurrency');
    search.addIndex('marketCurrency');
    search.addDocuments(documents);
    return search;
  }

  function updateSearchText(text) {
    setSearchText(text.toUpperCase());
  }

  function getSearchedItems() {
    if (search !== null) {
      const isValidSearchText = searchText.trim().length > 0;
      if (isValidSearchText) {
        const searched = search.search(searchText);
        const searchedSymbols = {};
        searched.forEach(
          ({ marketCurrency }) => (searchedSymbols[marketCurrency] = true)
        );
        return items.filter(
          ({ marketCurrency }) => searchedSymbols[marketCurrency]
        );
      }
    }
    return items;
  }

  useEffect(() => {
    if (search === null || search._documents.length !== items.length) {
      setSearch(createSearch(items));
    }
  }, [search, items]);

  return {
    searchText,
    updateSearchText,
    searchedItems: getSearchedItems()
  };
}
