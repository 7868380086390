import React from 'react';
import styled from 'styled-components';
import classes from 'classnames';
import AgeLabel from 'components/dashboard/AgeLabel';
import { colors } from 'ui';
import { Container } from 'components/ui/layout';
import { Box, Card, Flex } from 'components/ui/layout';
import { CaretDown, CaretUp } from 'components/ui/icons';
import { useMarkets } from 'components/data/useMarkets';
import { useMargin } from './useMargin';
import { formatAmount, getAmountPrecision } from 'utils/format';
import { sortDirs } from './marginSorting';
import { isolatedSortColumns } from './marginSorting';
import { crossSortColumns } from './marginSorting';

const CaretDownIcon = styled(CaretDown)`
  width: 14px;
  height: 14px;
`;

const CaretUpIcon = styled(CaretUp)`
  width: 14px;
  height: 14px;
`;

const Wrapper = styled(Container)`
  padding-top: 15px;
  display: flex;
  flex-wrap: wrap;

  .sorting-header {
    cursor: pointer;
    display: inline-block;
    transition: color 200ms ease;

    &:hover {
      color: ${colors.neutral90};
    }
  }

  .green {
    color: ${colors.green};
  }

  .orange {
    color: ${colors.orange1};
  }

  .red {
    color: ${colors.red1};
  }

  table {
    font-feature-settings: 'tnum' 1, 'lnum' 1;
    border-collapse: collapse;
    width: 100%;

    td,
    th {
      height: 30px;

      &.v-center {
        display: flex;
        align-items: center;
      }

      &.center {
        text-align: center;
      }

      &.right {
        text-align: right;
      }
    }

    tfoot {
      td {
        padding-top: 25px;
      }
    }

    tbody {
      tr {
        transition: background-color 100ms ease-in;

        &:hover {
          background-color: ${colors.neutral10};
        }
      }
    }

    th {
      text-align: left;
      padding: 6px 8px;
      vertical-align: top;
      white-space: nowrap;
    }

    td {
      padding: 6px 8px;
    }
  }
`;

function SortingHeader({
  children,
  column,
  sortColumn,
  sortDirection,
  toggleSorting,
  className,
  left = false
}) {
  let sortingSymbol = null;
  if (column === sortColumn) {
    switch (sortDirection) {
      case sortDirs.asc:
        sortingSymbol = <CaretUpIcon />;
        break;
      case sortDirs.desc:
        sortingSymbol = <CaretDownIcon />;
        break;
      default:
        break;
    }
  }
  return (
    <div
      className={classes('sorting-header', className)}
      onClick={() => toggleSorting(column)}
    >
      {left ? (
        <>
          {sortingSymbol} {children}
        </>
      ) : (
        <>
          {children} {sortingSymbol}
        </>
      )}
    </div>
  );
}

function formatMarginLevel(value) {
  return Number.isFinite(value) ? value.toFixed(1) : '';
}

function formatInterestRate(value) {
  if (Number.isFinite(value)) {
    return value.toLocaleString('en', {
      style: 'percent',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
  } else {
    return '';
  }
}

function MarginsPage({ accountName }) {
  const [markets] = useMarkets();

  const {
    margin,
    isolatedSortColumn,
    isolatedSortDirection,
    toggleIsolatedSorting,
    crossSortColumn,
    crossSortDirection,
    toggleCrossSorting
  } = useMargin(accountName);

  const { marginData = {}, startTime } = margin || {};
  const { isolatedSyncTime, crossSyncTime } = margin || {};
  const { isolated = {}, cross = {} } = marginData;
  const { assets: isolatedAssets = [] } = isolated;
  const { assets: crossAssets = [] } = cross;
  const { marginLevel: crossMarginLevel } = cross;
  const { marginLevelColor: crossMarginLevelColor } = cross;

  return (
    <Wrapper>
      <Box marginBottom="75px" marginRight="35px">
        <Box marginBottom="8px">
          <Flex justifyContent="space-between">
            Isolated
            <Flex>
              <Flex width="75px" justifyContent="flex-end">
                <AgeLabel startTime={isolatedSyncTime} />
              </Flex>
              <Flex width="75px" justifyContent="flex-end">
                <AgeLabel startTime={startTime} />
              </Flex>
            </Flex>
          </Flex>
        </Box>
        <Box inlineFlexBox flexDirection="column">
          <Flex fontSize="0.75em" marginBottom="5px">
            <Card minWidth="600px" flexBox>
              <table>
                <thead>
                  <tr>
                    <th>
                      <SortingHeader
                        column={isolatedSortColumns.market}
                        sortColumn={isolatedSortColumn}
                        sortDirection={isolatedSortDirection}
                        toggleSorting={toggleIsolatedSorting}
                      >
                        Market
                      </SortingHeader>
                    </th>
                    <th>
                      <SortingHeader
                        column={isolatedSortColumns.quote}
                        sortColumn={isolatedSortColumn}
                        sortDirection={isolatedSortDirection}
                        toggleSorting={toggleIsolatedSorting}
                      >
                        Quote
                      </SortingHeader>
                    </th>
                    <th className="right">Borrowed</th>
                    <th className="right">
                      <SortingHeader
                        left
                        column={isolatedSortColumns.collateral}
                        sortColumn={isolatedSortColumn}
                        sortDirection={isolatedSortDirection}
                        toggleSorting={toggleIsolatedSorting}
                      >
                        Collateral
                      </SortingHeader>
                    </th>
                    <th className="right">
                      <SortingHeader
                        left
                        column={isolatedSortColumns.interest}
                        sortColumn={isolatedSortColumn}
                        sortDirection={isolatedSortDirection}
                        toggleSorting={toggleIsolatedSorting}
                      >
                        Interest %
                      </SortingHeader>
                    </th>
                    <th className="right">
                      <SortingHeader
                        left
                        column={isolatedSortColumns.marginLevel}
                        sortColumn={isolatedSortColumn}
                        sortDirection={isolatedSortDirection}
                        toggleSorting={toggleIsolatedSorting}
                      >
                        Margin Level
                      </SortingHeader>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {isolatedAssets.map(asset => {
                    const marketCurrency = asset.marketCurrency;
                    const quoteCurrency = asset.quoteCurrency;
                    const marketNetAsset = asset.marketNetAsset;
                    const quoteNetAsset = asset.quoteNetAsset;
                    const marginLevel = asset.marginLevel;
                    const color = asset.marginLevelColor;
                    const marketAnnualRate = asset.marketAnnualRate;
                    const marketAnnualRateLevel = asset.marketAnnualRateLevel;
                    const amountPrecision = getAmountPrecision(
                      markets[marketCurrency]
                    );
                    const quoteAmountPrecision = getAmountPrecision(
                      markets[quoteCurrency]
                    );
                    return (
                      <tr key={`${marketCurrency}:${quoteCurrency}`}>
                        <td>{marketCurrency}</td>
                        <td>{quoteCurrency}</td>
                        <td className="right">
                          {formatAmount(marketNetAsset, amountPrecision)}
                        </td>
                        <td className="right">
                          {formatAmount(quoteNetAsset, quoteAmountPrecision)}
                        </td>
                        <td
                          className={classes('right', {
                            green: marketAnnualRateLevel === 'NORMAL',
                            orange: marketAnnualRateLevel === 'WARNING',
                            red: marketAnnualRateLevel === 'EXCESSIVE'
                          })}
                        >
                          {formatInterestRate(marketAnnualRate)}
                        </td>
                        <td
                          className={classes('right', {
                            green: color === 'GREEN',
                            orange: color === 'ORANGE',
                            red: color === 'RED'
                          })}
                        >
                          {marginLevel.toFixed(2)}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </Card>
          </Flex>
        </Box>
      </Box>
      <Box marginBottom="75px">
        <Box marginBottom="8px">
          <Box marginBottom="8px">
            <Flex justifyContent="space-between">
              <div>
                Cross{' '}
                <span
                  className={classes('right', {
                    green: crossMarginLevelColor === 'GREEN',
                    orange: crossMarginLevelColor === 'ORANGE',
                    red: crossMarginLevelColor === 'RED'
                  })}
                >
                  {formatMarginLevel(crossMarginLevel)}
                </span>
              </div>
              <Flex>
                <Flex width="75px" justifyContent="flex-end">
                  <AgeLabel startTime={crossSyncTime} />
                </Flex>
                <Flex width="75px" justifyContent="flex-end">
                  <AgeLabel startTime={startTime} />
                </Flex>
              </Flex>
            </Flex>
          </Box>
        </Box>
        <Box inlineFlexBox flexDirection="column">
          <Flex fontSize="0.75em" marginBottom="5px">
            <Card minWidth="300px" flexBox>
              <table>
                <thead>
                  <tr>
                    <th>
                      <SortingHeader
                        column={crossSortColumns.market}
                        sortColumn={crossSortColumn}
                        sortDirection={crossSortDirection}
                        toggleSorting={toggleCrossSorting}
                      >
                        Market
                      </SortingHeader>
                    </th>
                    <th className="right">Borrowed</th>
                    <th className="right">
                      <SortingHeader
                        left
                        column={crossSortColumns.interest}
                        sortColumn={crossSortColumn}
                        sortDirection={crossSortDirection}
                        toggleSorting={toggleCrossSorting}
                      >
                        Interest %
                      </SortingHeader>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {crossAssets.map(asset => {
                    const marketCurrency = asset.marketCurrency;
                    const marketNetAsset = asset.marketNetAsset;
                    const amountPrecision = getAmountPrecision(
                      markets[marketCurrency]
                    );
                    const marketAnnualRate = asset.marketAnnualRate;
                    const marketAnnualRateLevel = asset.marketAnnualRateLevel;
                    return (
                      <tr key={marketCurrency}>
                        <td>{marketCurrency}</td>
                        <td
                          className={classes('right', {
                            green: marketNetAsset >= 0,
                            red: marketNetAsset < 0
                          })}
                        >
                          {formatAmount(
                            Math.abs(marketNetAsset),
                            amountPrecision
                          )}
                        </td>
                        <td
                          className={classes('right', {
                            green: marketAnnualRateLevel === 'NORMAL',
                            orange: marketAnnualRateLevel === 'WARNING',
                            red: marketAnnualRateLevel === 'EXCESSIVE'
                          })}
                        >
                          {formatInterestRate(marketAnnualRate)}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </Card>
          </Flex>
        </Box>
      </Box>
    </Wrapper>
  );
}

export default MarginsPage;
