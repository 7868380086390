import styled, { css } from 'styled-components';
import { colors, textStyles, spaceProps, sizeProps, textProps } from 'ui';

export const Button = styled.button`
  background-color: transparent;
  display: inline-block;
  height: 36px;
  line-height: 34px;
  border: 0;
  white-space: nowrap;
  padding: 0 12px;
  border-radius: 3px;
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  outline-style: none;
  cursor: pointer;

  &[disabled],
  &:disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  ${props =>
    props.small &&
    css`
      padding: 0 8px;
      height: 28px;
      line-height: 26px;
    `}

  ${textStyles.caps};
  ${spaceProps};
  ${textProps};
  ${sizeProps};
`;

export const OutlineButton = styled(Button)`
  border: 1px solid ${colors.neutral50};
  transition: background-color 100ms ease;

  ${props =>
    props.active &&
    css`
      color: ${colors.primary};
      border-color: ${colors.primary};
    `}

  &:hover {
    background-color: ${props =>
      props.active ? colors.neutral10 : colors.neutral20};
  }
`;

export const PrimaryButton = styled(Button)`
  background-color: ${colors.primary};
  color: white;
  transition: opacity 100ms ease;
  font-weight: bold;

  &:hover {
    opacity: 0.8;
  }
`;

export default Button;
