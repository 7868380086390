import React from 'react';
import GridSection from './GridSection';
import AgeLabel from './AgeLabel';
import styled from 'styled-components';
import classes from 'classnames';
import { colors } from 'ui';
import { formatAmount, formatPrice, getAmountPrecision } from 'utils/format';
import { Card, Box, Flex } from 'components/ui/layout';
import { Dot } from 'components/ui/icons';

const Table = styled.table`
  margin: 6px 10px;
  font-feature-settings: 'tnum' 1, 'lnum' 1;
  border-collapse: collapse;

  .red {
    color: ${colors.red1};
  }

  .pnl {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 28px;
    width: 100%;
    padding-left: 9px;
    padding-right: 4px;
    transition: background-color 100ms ease;
    border-radius: 3px;

    &.enabled {
      cursor: pointer;

      &:hover {
        background-color: ${colors.neutral20};
      }
    }
  }
`;

const TableHeaderRow = styled.tr``;

const TableRow = styled.tr`
  transition: background-color 100ms ease-in;

  &:hover {
    background-color: ${colors.neutral10};
  }
`;

const TableHeader = styled.th`
  font-size: 1.25em;
  text-align: ${props => props.textAlign || 'right'};
  height: 30px;
  vertical-align: top;
  padding-left: 8px;
  padding-right: 8px;
  vertical-align: top;
  white-space: nowrap;
`;

const TableCell = styled.td`
  padding: 3px 8px;
  text-align: right;
  min-width: ${props => props.minWidth || undefined};
  text-align: ${props => props.textAlign || 'right'};
`;

const RoiPercent = ({ roiPercent }) => {
  if (roiPercent === null) {
    return <Box paddingRight="4px">N/A</Box>;
  } else {
    return (
      <Box
        paddingRight="4px"
        color={roiPercent > 0 ? colors.green : colors.red1}
      >
        {roiPercent.toFixed(3) + '%'}
      </Box>
    );
  }
};

function formatPoints(points) {
  if (Number.isFinite(points)) {
    return (points * 1e4).toFixed(1);
  }
  return '';
}

function formatRatio(amountRatio) {
  if (Number.isFinite(amountRatio)) {
    return (amountRatio * 100).toFixed(0);
  }
}

function getAmount(entryPoint) {
  return entryPoint.displayAmount || entryPoint.amount;
}

function getPrice(entryPoint) {
  return entryPoint.displayPrice || entryPoint.price;
}

function getPriceWithMargin(entryPoint) {
  return entryPoint.displayPriceWithMargin || entryPoint.priceWithMargin;
}

function removeTypename({ __typename, ...rest }) {
  return rest;
}

function ArbitrageTable({
  negativeSwitch,
  priceBandsSwitch,
  onStartArbitrage,
  marketCurrency,
  firstExchangeId,
  secondExchangeId,
  markets,
  hasTradeAlert,
  sellExchangeBalances,
  buyExchangeBalances,
  sellExchange = {},
  buyExchange = {}
}) {
  // Skip starting the trade with negative expected P&L
  // if the corresponding protective switch is on
  function shouldSkipNegativeTrade(row) {
    return (
      (row.roiPercent < 0 || !Number.isFinite(row.roiPercent)) &&
      negativeSwitch.isOn
    );
  }

  function handleStartArbitrage(row) {
    if (onStartArbitrage) {
      const arbitrageInput = {
        marketCurrency,
        firstExchangeId,
        secondExchangeId,
        sellExchangeEntryPoint: removeTypename(row.sellExchangeEntryPoint),
        buyExchangeEntryPoint: removeTypename(row.buyExchangeEntryPoint)
      };
      onStartArbitrage(arbitrageInput);
    }
  }

  function hasGreenDot(rows, index) {
    const row = rows[index];
    const prevRow = rows[index - 1] || {};
    const amount = row.sellExchangeEntryPoint.amount;
    const roiPercent = row.roiPercent;
    const prevAmount = (prevRow.sellExchangeEntryPoint || {}).amount;
    const prevRoiPercent = prevRow.roiPercent;
    if (roiPercent > 0) {
      return index === 0
        ? true
        : amount * roiPercent > prevAmount * prevRoiPercent;
    }
    return false;
  }

  if (sellExchange.arbitrageTable !== null) {
    const {
      rows,
      startTime,
      sellExchangeEntryCurrency,
      buyExchangeEntryCurrency
    } = sellExchange.arbitrageTable;

    const { marketCurrencyBalance: sellBalance = null } =
      sellExchangeBalances || {};

    const { bitcoinBalance: buyBalance = null } = buyExchangeBalances || {};

    const sellExchangeEntryPrecision = getAmountPrecision(
      markets[sellExchangeEntryCurrency]
    );
    const btcPrecision = getAmountPrecision(markets.BTC);

    return (
      <GridSection
        header={
          <Flex flex="1" justifyContent="space-between" alignItems="center">
            <div>{sellExchange.name}</div>
            <Flex>
              <Box marginRight="10px">{sellExchangeEntryCurrency}</Box>
              <AgeLabel startTime={startTime} useColor />
            </Flex>
            <div>{buyExchange.name}</div>
          </Flex>
        }
      >
        <Card
          padding="0"
          style={{
            border: `1px solid ${hasTradeAlert ? colors.red1 : 'transparent'}`
          }}
        >
          <Table>
            <thead>
              <TableHeaderRow>
                {priceBandsSwitch.isOn ? <TableHeader>SX_L</TableHeader> : null}
                <TableHeader>
                  {sellExchangeEntryCurrency}/{sellExchange.baseCurrency}
                </TableHeader>
                <TableHeader>%</TableHeader>
                <TableHeader>Amount</TableHeader>
                <TableHeader textAlign="center">PL%</TableHeader>
                <TableHeader>PL</TableHeader>
                <TableHeader>Total</TableHeader>
                <TableHeader />
                <TableHeader>%</TableHeader>
                <TableHeader>
                  {buyExchangeEntryCurrency}/{buyExchange.baseCurrency}
                </TableHeader>
                {priceBandsSwitch.isOn ? <TableHeader>BX_U</TableHeader> : null}
              </TableHeaderRow>
            </thead>
            <tbody>
              {rows.map((row, index) => {
                const sxEntryPoint = row.sellExchangeEntryPoint;
                const bxEntryPoint = row.buyExchangeEntryPoint;
                const roiPercent = row.roiPercent;
                const expense = row.expense;
                const revenue = row.revenue;
                const pnl = revenue - expense;
                const sxPriceBandLower = sxEntryPoint.priceBandLower;
                const bxPriceBandUpper = bxEntryPoint.priceBandUpper;
                const sxEntryPrice = getPrice(sxEntryPoint);
                const sxEntryPriceWithMargin = getPriceWithMargin(sxEntryPoint);
                const bxEntryPrice = getPrice(bxEntryPoint);
                const bxEntryPriceWithMargin = getPriceWithMargin(bxEntryPoint);
                const isSxPriceTooLow = Number.isFinite(sxPriceBandLower)
                  ? sxEntryPriceWithMargin <= sxPriceBandLower
                  : false;
                const isBxPriceTooHigh = Number.isFinite(bxPriceBandUpper)
                  ? bxEntryPriceWithMargin >= bxPriceBandUpper
                  : false;
                const amount = getAmount(sxEntryPoint);
                const amountRatio = Number.isFinite(sellBalance)
                  ? amount / sellBalance
                  : null;
                const revenueRatio = Number.isFinite(buyBalance)
                  ? revenue / buyBalance
                  : null;
                const shouldSkip =
                  shouldSkipNegativeTrade(row) ||
                  isBxPriceTooHigh ||
                  isSxPriceTooLow;
                const enabled = !shouldSkip;
                return (
                  <TableRow key={index}>
                    {priceBandsSwitch.isOn ? (
                      <TableCell>{formatPrice(sxPriceBandLower)}</TableCell>
                    ) : null}
                    <TableCell
                      minWidth="80px"
                      className={isSxPriceTooLow ? 'red' : undefined}
                    >
                      {formatPrice(sxEntryPrice)}
                    </TableCell>
                    <TableCell minWidth="36px">
                      {formatRatio(amountRatio)}
                    </TableCell>
                    <TableCell>
                      {formatAmount(amount, sellExchangeEntryPrecision)}
                    </TableCell>
                    <TableCell>
                      <div
                        className={classes('pnl', { enabled })}
                        onClick={() => {
                          if (enabled) {
                            handleStartArbitrage(row);
                          }
                        }}
                      >
                        <RoiPercent roiPercent={roiPercent} />
                        <Dot
                          size={18}
                          color={
                            hasGreenDot(rows, index)
                              ? colors.green
                              : colors.red1
                          }
                        />
                      </div>
                    </TableCell>
                    <TableCell minWidth="50px">{formatPoints(pnl)}</TableCell>
                    <TableCell>{formatAmount(expense, btcPrecision)}</TableCell>
                    <TableCell minWidth="50px" />
                    <TableCell minWidth="36px">
                      {formatRatio(revenueRatio)}
                    </TableCell>
                    <TableCell
                      minWidth="80px"
                      className={isBxPriceTooHigh ? 'red' : undefined}
                    >
                      {formatPrice(bxEntryPrice)}
                    </TableCell>
                    {priceBandsSwitch.isOn ? (
                      <TableCell>{formatPrice(bxPriceBandUpper)}</TableCell>
                    ) : null}
                  </TableRow>
                );
              })}
            </tbody>
          </Table>
        </Card>
      </GridSection>
    );
  }

  return null;
}

export default ArbitrageTable;
