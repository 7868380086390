import { useState } from 'react';
import { sortColumns, sortDirs } from './totalBalancesSorting';

export function useTotalBalancesSorting() {
  const [sortBy, setSortBy] = useState(sortDirs.none);
  const [sortDir, setSortDir] = useState(sortColumns.none);

  function toggleSort(column) {
    function tryToggleFor(targetColumn) {
      if (column === targetColumn) {
        if (sortBy !== targetColumn) {
          setSortBy(targetColumn);
          setSortDir(sortDirs.asc);
        } else {
          if (sortDir === sortDirs.asc) {
            setSortDir(sortDirs.desc);
          } else if (sortDir === sortDirs.desc) {
            setSortBy(null);
            setSortDir(null);
          }
        }
        return true;
      }
      return false;
    }
    tryToggleFor(sortColumns.market);
    tryToggleFor(sortColumns.margin);
    tryToggleFor(sortColumns.other);
    tryToggleFor(sortColumns.actualToTotalDiff);
    tryToggleFor(sortColumns.actualToSavedDiff);
    tryToggleFor(sortColumns.totalBtc);
    tryToggleFor(sortColumns.actualBtc);
  }

  function sort(items) {
    if (sortBy === sortColumns.market) {
      if (sortDir === sortDirs.asc) {
        return items.sort((a, b) =>
          a.marketCurrency.localeCompare(b.marketCurrency)
        );
      } else {
        return items.sort((a, b) =>
          b.marketCurrency.localeCompare(a.marketCurrency)
        );
      }
    } else if (sortBy === sortColumns.margin) {
      const zeros = items.filter(item => item.totalMarginAmount === 0);
      const nonZeros = items.filter(item => item.totalMarginAmount !== 0);
      if (sortDir === sortDirs.asc) {
        return nonZeros
          .sort((a, b) => a.totalMarginAmount - b.totalMarginAmount)
          .concat(zeros);
      } else {
        return nonZeros
          .sort((a, b) => b.totalMarginAmount - a.totalMarginAmount)
          .concat(zeros);
      }
    } else if (sortBy === sortColumns.other) {
      const zeros = items.filter(item => item.totalOtherAmount === 0);
      const nonZeros = items.filter(item => item.totalOtherAmount !== 0);
      if (sortDir === sortDirs.asc) {
        return nonZeros
          .sort((a, b) => a.totalOtherAmount - b.totalOtherAmount)
          .concat(zeros);
      } else {
        return nonZeros
          .sort((a, b) => b.totalOtherAmount - a.totalOtherAmount)
          .concat(zeros);
      }
    } else if (sortBy === sortColumns.actualToTotalDiff) {
      if (sortDir === sortDirs.asc) {
        return items.sort((a, b) => a.actualToTotalDiff - b.actualToTotalDiff);
      } else {
        return items.sort((a, b) => b.actualToTotalDiff - a.actualToTotalDiff);
      }
    } else if (sortBy === sortColumns.actualToSavedDiff) {
      if (sortDir === sortDirs.asc) {
        return items.sort((a, b) => a.actualToSavedDiff - b.actualToSavedDiff);
      } else {
        return items.sort((a, b) => b.actualToSavedDiff - a.actualToSavedDiff);
      }
    } else if (sortBy === sortColumns.totalBtc) {
      if (sortDir === sortDirs.asc) {
        return items.sort(
          (a, b) => a.totalTotalAmountBtc - b.totalTotalAmountBtc
        );
      } else {
        return items.sort(
          (a, b) => b.totalTotalAmountBtc - a.totalTotalAmountBtc
        );
      }
    } else if (sortBy === sortColumns.actualBtc) {
      if (sortDir === sortDirs.asc) {
        return items.sort(
          (a, b) => a.totalActualAmountBtc - b.totalActualAmountBtc
        );
      } else {
        return items.sort(
          (a, b) => b.totalActualAmountBtc - a.totalActualAmountBtc
        );
      }
    }

    return items;
  }

  return {
    sortBy,
    sortDir,
    toggleSort,
    sort
  };
}
