import React from 'react';
import styled from 'styled-components';
import { useRef } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useDebounceCallback } from '@react-hook/debounce';

const marginBottom = 40;
const resizeDebounceInterval = 250;

const Container = styled.div`
  height: ${props => props.height + 'px'};
`;

function TotalBalancesTableContainer({ children, cursor }) {
  const containerRef = useRef(null);
  const [containerHeight, setContainerHeight] = useState(null);
  const [containerWidth, setContainerWidth] = useState(null);

  function setDimensions() {
    const rect = containerRef.current.getBoundingClientRect();
    const height = window.innerHeight - rect.top - marginBottom;
    const width = rect.width;
    setContainerHeight(height);
    setContainerWidth(width);
  }

  const setDimensionsDebounced = useDebounceCallback(
    setDimensions,
    resizeDebounceInterval
  );

  useEffect(() => {
    if (containerHeight === null) {
      setDimensions();
    }
  }, [containerHeight]);

  useEffect(() => {
    window.addEventListener('resize', setDimensionsDebounced);
    return () => {
      window.removeEventListener('resize', setDimensionsDebounced);
    };
  });

  return (
    <div ref={containerRef} style={{ flex: '1' }}>
      {containerHeight === null ? null : (
        <Container height={containerHeight} cursor={cursor}>
          {children({
            height: containerHeight,
            width: containerWidth
          })}
        </Container>
      )}
    </div>
  );
}

export default TotalBalancesTableContainer;
