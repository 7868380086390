import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import classes from 'classnames';
import AgeLabel from 'components/dashboard/AgeLabel';
import GridSection from './GridSection';
import { Cross, Bell } from 'components/ui/icons';
import { Card, Flex } from 'components/ui/layout';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { colors } from 'ui';
import { formatAmount, getAmountPrecision } from 'utils/format';
import { useInterval } from 'components/useInterval';

import alertAudio from 'media/alert.mp3';
import getDepositsGql from './graphql/getDeposits.gql';
import deleteDepositGql from './graphql/deleteDeposit.gql';

const POLL_INTERVAL = 2500;
const CLEAR_EXPIRED_ADDED_IDS_INTERVAL = 1000;
const ADDED_DEPOSIT_TIMEOUT = 5000;

const Wrapper = styled.div`
  height: 100%;

  .select-container {
    font-size: 1rem;
    width: 100%;
    margin-bottom: 5px;
  }

  .table-container {
    overflow: auto;
    height: 100%;
  }

  .green {
    color: ${colors.green};
  }

  .red {
    color: ${colors.red1};
  }

  .market-currency {
    cursor: pointer;
    transition: color 150ms ease;

    &:hover {
      color: ${colors.primary};
    }
  }

  table {
    font-feature-settings: 'tnum' 1, 'lnum' 1;
    border-collapse: collapse;
    width: 100%;

    td,
    th {
      z-index: 1;
      height: 30px;

      &.v-center {
        display: flex;
        align-items: center;
      }

      &.center {
        text-align: center;
      }

      &.right {
        text-align: right;
      }
    }

    tbody {
      tr {
        transition: background-color 100ms ease-in;

        &.deleting {
          opacity: 0.5;
          pointer-events: none;
        }

        td {
          transition: background-color 100ms ease-in;
        }

        &.added {
          td {
            background-color: hsla(95, 38%, 62%, 0.3);
          }
        }

        &:hover {
          background-color: ${colors.neutral10};
        }
      }
    }

    th {
      text-align: left;
      padding: 6px 8px;
      vertical-align: top;
      white-space: nowrap;
      background-color: ${colors.neutral5};
      position: sticky;
      top: 0;
    }

    td {
      padding: 6px 8px;
    }
  }
`;

const IconButton = styled.button`
  display: flex;
  color: inherit;
  align-items: center;
  justify-content: center;
  outline-style: none;
  border-radius: 50%;
  border-color: transparent;
  border-width: 0;
  height: ${props => props.size};
  width: ${props => props.size};
  background-color: ${props => (props.active ? colors.overlay : 'transparent')};
  transition: background-color 150ms ease;

  &:hover {
    background-color: ${colors.overlay};
  }
`;

function haveSameKeys(objectA, objectB) {
  const keysA = Object.keys(objectA);
  const keysB = Object.keys(objectB);
  if (keysA.length === keysB.length) {
    for (const keyA of keysA) {
      if (!(keyA in objectB)) {
        return false;
      }
    }
    return true;
  }
  return false;
}

function getDepositsIds(deposits) {
  const ids = {};
  for (const deposit of deposits) {
    ids[deposit.id] = true;
  }
  return ids;
}

function useDeposits({ onDepositsAdded }) {
  const [deletingIds, setDeletingIds] = useState({});
  const [prevIds, setPrevIds] = useState(null);
  const [addedIds, setAddedIds] = useState({});
  const [deleteMutation] = useMutation(deleteDepositGql);
  const { data = {}, loading, refetch } = useQuery(getDepositsGql, {
    fetchPolicy: 'network-only',
    pollInterval: POLL_INTERVAL
  });

  function addDeletingId(depositId) {
    setDeletingIds(ids => ({
      ...ids,
      [depositId]: true
    }));
  }

  function removeDeletingId(depositId) {
    setDeletingIds(({ [depositId]: value, ...restIds }) => {
      return restIds;
    });
  }

  async function deleteDeposit(depositId) {
    addDeletingId(depositId);
    await deleteMutation({ variables: { depositId } });
    await refetch();
    removeDeletingId(depositId);
  }

  function shape() {
    const { deposits = [] } = data;
    return deposits.map(deposit => {
      const isDeleting = Boolean(deletingIds[deposit.id]);
      const isAdded = !isDeleting && Boolean(addedIds[deposit.id]);
      return {
        ...deposit,
        isDeleting,
        isAdded
      };
    });
  }

  const deposits = shape();
  const currentIds = getDepositsIds(deposits);
  const currentIdsList = deposits.map(({ id }) => id);

  function clearExpiredAddedIds() {
    setAddedIds(addedIds => {
      const updatedAddedIds = {};
      for (const id in addedIds) {
        const timestamp = addedIds[id];
        const timeLeft = Date.now() - timestamp;
        if (timeLeft < ADDED_DEPOSIT_TIMEOUT) {
          updatedAddedIds[id] = addedIds[id];
        }
      }
      return updatedAddedIds;
    });
  }

  useInterval(clearExpiredAddedIds, CLEAR_EXPIRED_ADDED_IDS_INTERVAL);

  useEffect(() => {
    if (prevIds === null) {
      if (!loading) {
        // Set the initial state for prevIds = currentIds:
        setPrevIds(currentIds);
      }
    } else if (!haveSameKeys(currentIds, prevIds)) {
      const newAddedIds = {};
      let shouldNotify = false;
      for (const id of currentIdsList) {
        if (currentIds[id] && !prevIds[id]) {
          newAddedIds[id] = new Date().getTime();
          shouldNotify = true;
        } else {
          // To avoid including deposits inserted in the end
          // because of the list number limitation
          break;
        }
      }
      if (shouldNotify) {
        if (onDepositsAdded) {
          onDepositsAdded();
        }
      }
      setAddedIds(addedIds => ({ ...addedIds, ...newAddedIds }));
      setPrevIds(currentIds);
    }
  }, [loading, currentIds, prevIds, currentIdsList, onDepositsAdded]);

  return {
    deposits,
    deleteDeposit,
    loading
  };
}

function useAudioAlert() {
  const audioRef = useRef(null);

  const [isAlertEnabled, setIsAlertEnabled] = useState(
    localStorage.getItem('deposits_audio_alert_enabled') === 'true'
  );

  function toggleAudioAlert() {
    setIsAlertEnabled(!isAlertEnabled);
    localStorage.setItem(
      'deposits_audio_alert_enabled',
      JSON.stringify(!isAlertEnabled)
    );
  }

  function playAudioAlert() {
    if (audioRef.current) {
      if (isAlertEnabled) {
        audioRef.current.play();
      }
    }
  }

  return {
    audioRef,
    isAlertEnabled,
    toggleAudioAlert,
    playAudioAlert
  };
}

function Deposits({ switchMarketCurrency, markets }) {
  const {
    audioRef,
    isAlertEnabled,
    playAudioAlert,
    toggleAudioAlert
  } = useAudioAlert();

  const { deposits, deleteDeposit } = useDeposits({
    onDepositsAdded: playAudioAlert
  });

  return (
    <GridSection
      header={
        <Flex flex="1" justifyContent="space-between" alignItems="center">
          <div>Deposits</div>
          <IconButton size="24px" onClick={toggleAudioAlert}>
            <Bell
              size={24}
              color={isAlertEnabled ? colors.primary : undefined}
            />
          </IconButton>
        </Flex>
      }
      ageLabelsPosition="none"
      marginBottom="8px"
    >
      <Card
        height="165px"
        padding="5px 15px 10px 15px"
        style={{ minWidth: '350px' }}
      >
        <audio ref={audioRef} src={alertAudio} />
        <Wrapper>
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th>Market</th>
                  <th className="right">Amount</th>
                  <th>Account</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {deposits.map(deposit => {
                  const id = deposit.id;
                  const marketCurrency = deposit.marketCurrency;
                  const accountName = deposit.accountName;
                  const amount = deposit.amount;
                  const time = deposit.time;
                  const precision = getAmountPrecision(markets[marketCurrency]);
                  const isDeleting = deposit.isDeleting;
                  const isAdded = deposit.isAdded;
                  return (
                    <tr
                      key={id}
                      className={classes({
                        deleting: isDeleting,
                        added: isAdded
                      })}
                    >
                      <td>
                        <span
                          className="market-currency"
                          onClick={() => switchMarketCurrency(marketCurrency)}
                        >
                          {marketCurrency}
                        </span>
                      </td>
                      <td
                        className={classes('right', {
                          red: amount < 0,
                          green: amount > 0
                        })}
                      >
                        {formatAmount(amount, precision)}
                      </td>
                      <td>{accountName}</td>
                      <td className="right">
                        <Flex justifyContent="flex-end">
                          <AgeLabel startTime={time} />
                        </Flex>
                      </td>
                      <td className="v-center">
                        <IconButton
                          size="20px"
                          onClick={() => deleteDeposit(id)}
                        >
                          <Cross size={8} />
                        </IconButton>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </Wrapper>
      </Card>
    </GridSection>
  );
}

export default Deposits;
