import React from 'react';
import styled from 'styled-components';
import HeaderNav from 'components/navigation/HeaderNav';
import { useAlerts } from 'components/data/useAlerts';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const Main = styled.main`
  flex: 1;
`;

const Layout = ({ children }) => {
  const { marginLevelStatus, hasStakingAlerts } = useAlerts();
  return (
    <Wrapper>
      <HeaderNav
        marginLevelStatus={marginLevelStatus}
        hasStakingAlerts={hasStakingAlerts}
      />
      <Main>{children}</Main>
    </Wrapper>
  );
};

export default Layout;
