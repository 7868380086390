import React from 'react';
import styled from 'styled-components';
import routes from 'routes';
import PrimaryNavTabs from './PrimaryNavTabs';
import ProfileMenuPopover from './ProfileMenuPopover';
import Blink from './Blink';
import { Circle, UserCircle, Bell } from 'components/ui/icons';
import { Flex, Box, Relative } from 'components/ui/layout';
import { colors } from 'ui';

const MarginAlertIcon = styled(Circle).attrs({ width: 24 })``;
const StakingAlertIcon = styled(Bell).attrs({ width: 18 })``;

const height = '48px';

const Wrapper = styled.header`
  width: 100%;
  background-color: ${colors.primary};
  color: ${colors.neutral0};
  box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1);
`;

const Nav = styled.nav``;

const NavRoot = styled.ul`
  margin: 0 20px;
  padding: 0;
  list-style: none;
  position: relative;
  height: ${height};
`;

const NavSectionPrimary = styled.li`
  z-index: 1;
  list-style: none;
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  height: ${height};
`;

const NavSectionSecondary = styled.li`
  z-index: 1;
  display: flex;
  list-style: none;
  position: absolute;
  top: 0;
  right: 4px;
  height: ${height};
`;

const NavSectionMiddle = styled.li`
  list-style: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: ${height};
`;

const IconButton = styled.button`
  display: flex;
  color: inherit;
  align-items: center;
  justify-content: center;
  outline-style: none;
  border-radius: 50%;
  border-color: transparent;
  border-width: 0;
  height: ${props => props.size};
  width: ${props => props.size};
  background-color: ${props => (props.active ? colors.overlay : 'transparent')};
  transition: background-color 150ms ease;

  &:hover {
    background-color: ${colors.overlay};
  }
`;

class HeaderNav extends React.Component {
  state = { showMenu: false };
  handleMenuButtonClick = event => {
    event.stopPropagation();
    this.setState(state => ({ showMenu: !state.showMenu }));
  };
  handleWindowClick = () => {
    this.setState({ showMenu: false });
  };
  componentDidMount() {
    window.addEventListener('click', this.handleWindowClick);
  }
  componentWillUnmount() {
    window.removeEventListener('click', this.handleWindowClick);
  }
  render() {
    const { marginLevelStatus, hasStakingAlerts } = this.props;
    return (
      <Wrapper>
        <Nav>
          <NavRoot>
            <NavSectionPrimary>
              <PrimaryNavTabs
                height={height}
                routes={[
                  routes.home,
                  routes.scanner,
                  routes.console,
                  routes.balances,
                  {
                    ...routes.margins,
                    marginLevelStatus
                  },
                  routes.wallets,
                  routes.staking
                ]}
              />
            </NavSectionPrimary>
            <NavSectionSecondary>
              <Box marginRight="15px">
                <PrimaryNavTabs
                  height={height}
                  routes={[routes.health, routes.settings]}
                />
              </Box>
              <Flex
                alignItems="center"
                padding="0"
                height="100%"
                position="relative"
              >
                <Relative height="40px" width="40px">
                  <IconButton
                    size="40px"
                    active={this.state.showMenu}
                    onClick={this.handleMenuButtonClick}
                  >
                    <UserCircle size="24" />
                  </IconButton>
                  {this.state.showMenu ? <ProfileMenuPopover /> : null}
                </Relative>
              </Flex>
            </NavSectionSecondary>
            <NavSectionMiddle>
              <Flex
                alignItems="center"
                justifyContent="center"
                padding="0"
                height="100%"
                position="relative"
              >
                {marginLevelStatus === 'ALERT' ? (
                  <Blink>
                    <MarginAlertIcon />
                    <MarginAlertIcon />
                    <MarginAlertIcon />
                  </Blink>
                ) : hasStakingAlerts ? (
                  <Blink>
                    <StakingAlertIcon />
                    <StakingAlertIcon style={{ marginLeft: '6px' }} />
                    <StakingAlertIcon style={{ marginLeft: '6px' }} />
                  </Blink>
                ) : null}
              </Flex>
            </NavSectionMiddle>
          </NavRoot>
        </Nav>
      </Wrapper>
    );
  }
}

export default HeaderNav;
