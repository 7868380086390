import React from 'react';
import styled from 'styled-components';
import ReactSelect from 'react-select';
import { selectStyles, theme } from 'components/Select';
import { components } from 'react-select';
import { Box } from 'components/ui/layout';
import { colors } from 'ui';

const customStyles = {
  ...selectStyles,
  singleValue: styles => {
    return { ...styles, maxWidth: 'calc(100% - 10px)', width: '100%' };
  }
};

const SingleValueContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .total-container {
    display: flex;
    align-items: center;
    font-size: 0.85em;
    padding: 0px 4px;
    border-radius: 2px;
    color: ${colors.neutral50};
    background-color: ${colors.neutral5};
  }
`;

const SingleValueWithTotals = ({
  totalTotalAmount,
  totalTotalAmountBtc,
  ...props
}) => {
  return (
    <components.SingleValue {...props}>
      <SingleValueContainer>
        <div>{props.data.label}</div>
        <div className="total-container">
          {totalTotalAmount} ({totalTotalAmountBtc} BTC)
        </div>
      </SingleValueContainer>
    </components.SingleValue>
  );
};

const MarketCurrencySelect = ({
  options,
  marketCurrency,
  displayTotalAmount,
  totalTotalAmount,
  totalTotalAmountBtc,
  isDisabled,
  onChange,
  ...containerProps
}) => {
  const value = options.find(({ value }) => value === marketCurrency) || null;
  return (
    <Box {...containerProps}>
      <ReactSelect
        isClearable
        options={options}
        value={value}
        isDisabled={isDisabled}
        onChange={onChange}
        theme={theme}
        styles={customStyles}
        components={{
          SingleValue: displayTotalAmount
            ? props => (
                <SingleValueWithTotals
                  {...props}
                  totalTotalAmount={totalTotalAmount}
                  totalTotalAmountBtc={totalTotalAmountBtc}
                />
              )
            : components.SingleValue
        }}
      />
    </Box>
  );
};

export default MarketCurrencySelect;
