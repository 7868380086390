import { useQuery } from '@apollo/react-hooks';
import getAlertsGql from './graphql/getAlerts.gql';

export function useAlerts() {
  const {
    data: { marginLevelStatus, hasStakingAlerts } = {},
    loading
  } = useQuery(getAlertsGql, {
    fetchPolicy: 'network-only',
    pollInterval: 1000
  });

  return {
    hasStakingAlerts,
    marginLevelStatus: loading ? 'LOADING' : marginLevelStatus
  };
}
