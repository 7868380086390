export const colors = {
  overlay: 'rgba(255, 255, 255, 0.2)',
  border: 'hsl(220, 13%, 51%)',
  backgroundLighter: 'hsl(220, 13%, 18%)',
  background: 'hsl(216, 13%, 15%)',
  bodyText: 'hsl(220, 14%, 75%)',
  neutral0: 'hsl(220, 13%, 18%)',
  neutral5: 'hsl(220, 13%, 22%)',
  neutral10: 'hsl(220, 13%, 26%)',
  neutral20: 'hsl(220, 13%, 34%)',
  neutral30: 'hsl(220, 13%, 43%)',
  neutral40: 'hsl(220, 13%, 51%)',
  neutral50: 'hsl(220, 13%, 59%)',
  neutral60: 'hsl(220, 13%, 67%)',
  neutral70: 'hsl(220, 13%, 75%)',
  neutral80: 'hsl(220, 13%, 84%)',
  neutral90: 'hsl(220, 13%, 92%)',
  primary: 'hsl(30, 60%, 50%)',
  primary25: 'hsl(30, 60%, 25%)',
  primary50: 'hsl(30, 60%, 20%)',
  primary75: 'hsl(30, 60%, 20%)',
  red1: 'hsl(355, 65%, 65%)',
  red2: 'hsl(5, 48%, 51%)',
  orange1: 'hsl(29, 54%, 61%)',
  orange2: 'hsl(39, 67%, 69%)',
  green: 'hsl(95, 38%, 62%)',
  purple: 'hsl(286, 60%, 67%)',
  blue: 'hsl(207, 82%, 66%)',
  cyan: 'hsl(207, 82%, 66%)'
};
