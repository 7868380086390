import React from 'react';
import Modal from 'components/ui/Modal';
import styled from 'styled-components';
import { Card } from 'components/ui/layout';
import { PrimaryButton } from 'components/ui/buttons';
import { Field } from 'components/ui/fields';
import { colors } from 'ui';

const ErrorMessageSticker = styled.div`
  z-index: 101;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: ${colors.red2};
  color: ${colors.neutral0};
  font-weight: bold;
  font-size: 1.2em;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FormWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  width: 480px;
  max-width: 100%;
  max-height: 100%;
`;

const Form = styled(Card)`
  background-color: ${colors.backgroundLighter};
  padding: 40px;
`;

const Heading = styled.h1`
  margin-top: 0;
  margin-bottom: 50px;
  text-align: center;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
`;

const TextInput = styled.input`
  height: 45px;
  width: 100%;
  color: ${colors.neutral80};
  background-color: ${colors.neutral0};
  border-color: ${colors.neutral20};
  border-radius: 0;
  border-style: solid;
  border-width: 1px;
  padding: 10px 10px;
  outline-style: none;
  font-family: inherit;
  font-size: inherit;
  transition: border-color 150ms ease;

  ::placeholder {
    color: ${colors.neutral50};
  }

  &:hover {
    border-color: ${colors.neutral40};
  }

  &:focus {
    border-color: ${colors.primary};
  }
`;

const LoginButton = styled(PrimaryButton)`
  width: 100%;
  height: 45px;
  line-height: 45px;
  background-color: ${colors.primary};

  &:focus {
    outline-style: auto;
    outline-width: 5px;
    outline-color: ${colors.neutral40};
  }
`;

class LoginForm extends React.Component {
  state = {
    username: '',
    password: '',
    isLoggingIn: false,
    hasError: false,
    errorMessage: null
  };
  handleUsernameChange = ({ target: { value } }) => {
    this.setState({ username: value, hasError: false });
  };
  handlePasswordChange = ({ target: { value } }) => {
    this.setState({ password: value, hasError: false });
  };
  handleSubmit = event => {
    event.preventDefault();
    this.setState({ isLoggingIn: true }, async () => {
      const { username, password } = this.state;
      const { success, message } = await this.props.login(username, password);
      if (!success) {
        this.setState({
          hasError: true,
          errorMessage: message,
          isLoggingIn: false
        });
      }
    });
  };
  render() {
    return (
      <Modal>
        {this.state.hasError ? (
          <ErrorMessageSticker>{this.state.errorMessage}</ErrorMessageSticker>
        ) : null}
        <FormWrapper>
          <Form as="form" onSubmit={this.handleSubmit}>
            <Heading>Log in</Heading>
            <Field marginBottom="25px">
              <TextInput
                type="text"
                placeholder="User name"
                onChange={this.handleUsernameChange}
              />
            </Field>
            <Field marginBottom="40px">
              <TextInput
                type="password"
                placeholder="Password"
                onChange={this.handlePasswordChange}
              />
            </Field>
            <Field>
              <LoginButton type="submit">
                {this.state.isLoggingIn ? 'Logging in…' : 'Log in'}
              </LoginButton>
            </Field>
          </Form>
        </FormWrapper>
        <Overlay />
      </Modal>
    );
  }
}

export default LoginForm;
