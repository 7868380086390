import { useQuery } from '@apollo/react-hooks';
import { useIsolatedMarginSorting } from './useIsolatedMarginSorting';
import { useCrossMarginSorting } from './useCrossMarginSorting';

import getMarginGql from './graphql/getMargin.gql';

export function useMargin(accountName) {
  const {
    sortColumn: isolatedSortColumn,
    sortDirection: isolatedSortDirection,
    toggleSorting: toggleIsolatedSorting
  } = useIsolatedMarginSorting();

  const {
    sortColumn: crossSortColumn,
    sortDirection: crossSortDirection,
    toggleSorting: toggleCrossSorting
  } = useCrossMarginSorting();

  const { data = {}, loading } = useQuery(getMarginGql, {
    variables: {
      accountName,
      isolatedSortColumn,
      isolatedSortDirection,
      crossSortColumn,
      crossSortDirection
    },
    fetchPolicy: 'network-only',
    pollInterval: 1000,
    notifyOnNetworkStatusChange: true
  });

  return {
    margin: data.margin,
    loading,
    isolatedSortColumn,
    isolatedSortDirection,
    toggleIsolatedSorting,
    crossSortColumn,
    crossSortDirection,
    toggleCrossSorting
  };
}
