import styled from 'styled-components';
import { textStyles, colors } from 'ui';
import { Box } from 'components/ui/layout';

export const FieldLabel = styled.div`
  ${textStyles.smallCaps};
  color: ${colors.neutral60};
  margin-bottom: ${props => props.marginBottom || '8px'};
  font-size: 0.9em;
`;

export const Field = styled(Box)``;
