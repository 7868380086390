import { css } from 'styled-components';

const caps = css`
  letter-spacing: 0.04em;
  text-transform: uppercase;
  font-feature-settings: 'case' 1;
`;

const smallCaps = css`
  text-transform: uppercase;
  font-size: 0.8em !important;
  letter-spacing: 0.05em;
`;

const tabularFigures = css`
  font-feature-settings: 'tnum' 1;
`;

export const textStyles = {
  caps,
  smallCaps,
  tabularFigures
};
