import React, { useState, useEffect } from 'react';
import Balances from './Balances';
import CoinBalances from './CoinBalances';
import Deposits from './Deposits';
import WalletCard from './WalletCard';
import Orderbook from './Orderbook';
import ArbitrageTable from './ArbitrageTable';
import Arbitrage from './arbitrage/Arbitrage';
import styled from 'styled-components';
import argoTime from 'middleware/argoTime';
import { Box, Flex } from 'components/ui/layout';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useMarkets } from 'components/data/useMarkets';
import { useBalances } from 'components/dashboard/useBalances';
import { useMarketCurrencySwitch } from './useMarketCurrencySwitch';
import { useStoredSwitch } from 'pages/scanner/useStoredSwitch';

import getSettingsGql from 'components/settings/graphql/getSettings.gql';
import startArbitrageGql from './graphql/startArbitrage.gql';

const Wrapper = styled.div`
  margin-top: 8px;
  font-feature-settings: 'tnum' 1, 'lnum' 1;
  overflow-x: auto;
`;

function useSettings() {
  const { data: { settings = {} } = {}, loading } = useQuery(getSettingsGql);
  return [settings, loading];
}

function DashboardGrid({
  marketCurrency,
  marketCurrencyOptions,
  isOnHold,
  timestampDelayAlertValue,
  arbitrage,
  firstExchange: first,
  secondExchange: second,
  firstExchangeInfo = {},
  secondExchangeInfo = {},
  selectMarketCurrency,
  stopTracking,
  trackingState
}) {
  const [prevMarketCurrency, setPrevMarketCurrency] = useState(marketCurrency);
  const [coinBalancesKey, setCoinBalancesKey] = useState(0);
  const [stateArbitrage, setStateArbitrage] = useState(null);
  const [startArbitrageMutation] = useMutation(startArbitrageGql);
  const [settings] = useSettings();
  const [markets] = useMarkets();

  const [{ firstBalances, secondBalances }] = useBalances({
    enableBalances: true,
    marketCurrency,
    firstExchangeId: firstExchangeInfo.id,
    secondExchangeId: secondExchangeInfo.id
  });

  const { switchMarketCurrency } = useMarketCurrencySwitch({
    marketCurrency,
    marketCurrencyOptions,
    selectMarketCurrency,
    stopTracking,
    trackingState
  });

  const negativeSwitch = useStoredSwitch('trade_entry_disable_negative');
  const priceBandsSwitch = useStoredSwitch('trade_entry_display_price_bands');

  // Collect all timestamps to check the trade alert:
  const { startTime: firstBalancesStartTime = 0 } = firstBalances || {};
  const { startTime: secondBalancesStartTime = 0 } = secondBalances || {};
  const { arbitrageTable: firstArbitrageTable } = first || {};
  const { arbitrageTable: secondArbitrageTable } = second || {};
  const { startTime: firstStartTime = 0 } = firstArbitrageTable || {};
  const { startTime: secondStartTime = 0 } = secondArbitrageTable || {};
  const now = argoTime.now().getTime();
  const timestamps = [
    now - firstBalancesStartTime,
    now - secondBalancesStartTime,
    now - firstStartTime,
    now - secondStartTime
  ];
  const hasTradeAlert = timestamps.some(ts => ts > timestampDelayAlertValue);

  useEffect(() => {
    if (marketCurrency !== prevMarketCurrency) {
      setPrevMarketCurrency(marketCurrency);
      // remount CoinBalances if market currency has changed and not empty:
      if (marketCurrency) {
        setCoinBalancesKey(key => key + 1);
      }
    }
  }, [marketCurrency, prevMarketCurrency]);

  useEffect(() => {
    if (arbitrage) {
      if (
        stateArbitrage === null ||
        arbitrage.lastUpdateTime > stateArbitrage.lastUpdateTime
      ) {
        setStateArbitrage(arbitrage);
      }
    }
  }, [arbitrage, stateArbitrage]);

  async function startArbitrage(input) {
    setStateArbitrage({
      ...stateArbitrage,
      status: 'starting'
    });
    try {
      await startArbitrageMutation({ variables: { input } });
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <Wrapper>
      <Flex marginBottom="10px">
        <Box marginRight="25px">
          <CoinBalances
            key={coinBalancesKey}
            markets={markets}
            switchMarketCurrency={switchMarketCurrency}
            defaultMarketCurrency={marketCurrency}
            marketCurrencyOptions={marketCurrencyOptions}
          />
        </Box>
        <Box marginRight="25px">
          <Deposits
            switchMarketCurrency={switchMarketCurrency}
            markets={markets}
          />
          <Balances
            markets={markets}
            marketCurrency={marketCurrency}
            firstExchangeId={firstExchangeInfo.id}
            firstName={firstExchangeInfo.name}
            firstBaseCurrency={firstExchangeInfo.baseCurrency}
            secondExchangeId={secondExchangeInfo.id}
            secondName={secondExchangeInfo.name}
            secondBaseCurrency={secondExchangeInfo.baseCurrency}
            firstBalances={firstBalances}
            secondBalances={secondBalances}
          />
        </Box>
        {stateArbitrage ? (
          <Box marginRight="25px">
            <Arbitrage arbitrage={stateArbitrage} markets={markets} />
          </Box>
        ) : null}
        <Box>
          <WalletCard markets={markets} />
        </Box>
      </Flex>
      <Flex marginBottom="50px">
        <Box marginRight="35px">
          {first ? (
            <ArbitrageTable
              markets={markets}
              isPaper={settings.isPaper}
              isOnHold={isOnHold}
              marketCurrency={marketCurrency}
              sellExchange={first}
              sellExchangeBalances={firstBalances}
              buyExchange={second}
              buyExchangeBalances={secondBalances}
              firstExchangeId={first.id}
              secondExchangeId={second.id}
              onStartArbitrage={startArbitrage}
              hasTradeAlert={hasTradeAlert}
              negativeSwitch={negativeSwitch}
              priceBandsSwitch={priceBandsSwitch}
            />
          ) : null}
        </Box>
        <Box>
          {second ? (
            <ArbitrageTable
              markets={markets}
              ageLabelOrder={-1}
              isPaper={settings.isPaper}
              isOnHold={isOnHold}
              marketCurrency={marketCurrency}
              sellExchange={second}
              sellExchangeBalances={secondBalances}
              buyExchange={first}
              buyExchangeBalances={firstBalances}
              firstExchangeId={first.id}
              secondExchangeId={second.id}
              onStartArbitrage={startArbitrage}
              hasTradeAlert={hasTradeAlert}
              negativeSwitch={negativeSwitch}
              priceBandsSwitch={priceBandsSwitch}
            />
          ) : null}
        </Box>
      </Flex>
      <Flex marginBottom="75px">
        <Box marginRight="75px">
          {first ? (
            <Flex>
              <Orderbook
                exchangeName={first.name}
                baseCurrency={first.baseCurrency}
                orderbook={first.orderbook}
              />
              {first.bitcoinOrderbook ? (
                <Box marginLeft="35px">
                  <Orderbook
                    exchangeName={first.name}
                    baseCurrency={first.baseCurrency}
                    orderbook={first.bitcoinOrderbook}
                  />
                </Box>
              ) : null}
            </Flex>
          ) : null}
        </Box>
        <Box>
          {second ? (
            <Flex>
              <Orderbook
                exchangeName={second.name}
                baseCurrency={second.baseCurrency}
                orderbook={second.orderbook}
              />
              {second.bitcoinOrderbook ? (
                <Box marginLeft="35px">
                  <Orderbook
                    exchangeName={second.name}
                    baseCurrency={second.baseCurrency}
                    orderbook={second.bitcoinOrderbook}
                  />
                </Box>
              ) : null}
            </Flex>
          ) : null}
        </Box>
      </Flex>
    </Wrapper>
  );
}

export default DashboardGrid;
