import authStore from 'security/authStore';
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { setContext } from 'apollo-link-context';
import { createHttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';

let apolloClient = null;

const createApolloClient = ({ authenticate }) => {
  const httpLink = createHttpLink({
    uri: '/graphql',
    credentials: 'same-origin'
  });
  // Sets access token for each request:
  const authLink = setContext((_, { headers }) => {
    const accessToken = authStore.getAccessToken();
    return {
      headers: {
        ...headers,
        Authorization: accessToken ? `Bearer ${accessToken}` : ''
      }
    };
  });
  // Catch graphql and network errors, authenticate on 401:
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.map(({ message, locations, path }) =>
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
      );
    }
    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
      if (networkError.statusCode === 401) {
        authenticate();
      }
    }
  });
  return new ApolloClient({
    cache: new InMemoryCache(),
    link: errorLink.concat(authLink).concat(httpLink)
  });
};

export const getApolloClient = ({ authenticate }) => {
  if (!apolloClient) {
    apolloClient = createApolloClient({ authenticate });
  }
  return apolloClient;
};
