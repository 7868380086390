import React from 'react';
import styled from 'styled-components';
import { textStyles, colors } from 'ui';

const Label = styled.div`
  line-height: 1;
  font-size: 1rem;
  margin-right: 5px;
  font-weight: bold;
  color: ${props => props.color};
  ${textStyles.caps};
`;

const StatusLabel = ({ status }) => {
  if (status === 'completed') {
    return <Label color={colors.green}>{status}</Label>;
  }
  if (status === 'failed') {
    return <Label color={colors.red1}>{status}</Label>;
  }
  if (status === 'started') {
    return <Label>{status}</Label>;
  }
  return null;
};

export default StatusLabel;
