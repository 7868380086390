import React, { useRef, useEffect } from 'react';
import { TextInput } from 'components/ui/inputs';

function AutoFocusTextInput(props) {
  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return <TextInput ref={inputRef} {...props} />;
}

export default AutoFocusTextInput;
