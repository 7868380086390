import React from 'react';
import Select from 'components/Select';
import { Box } from 'components/ui/layout';

const options = [
  {
    value: 'Binance',
    label: 'Binance'
  },
  {
    value: 'Huobi',
    label: 'Huobi'
  }
];

const MarginAccountSelect = ({ accountName, onChange }) => {
  const value = options.find(({ value }) => value === accountName) || null;
  return (
    <Box width="10rem">
      <Select options={options} value={value} onChange={onChange} />
    </Box>
  );
};

export default MarginAccountSelect;
