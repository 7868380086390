import { useQuery } from '@apollo/react-hooks';
import { useWalletBalancesSorting } from './useWalletBalancesSorting';

import getWalletBalances from './graphql/getWalletBalances.gql';

export function useWalletBalances({ platformId, pollInterval = 1000 }) {
  const {
    sortColumn,
    sortDirection,
    toggleSorting
  } = useWalletBalancesSorting();

  const { data = {}, loading } = useQuery(getWalletBalances, {
    fetchPolicy: 'network-only',
    pollInterval,
    notifyOnNetworkStatusChange: true,
    skip: !platformId,
    variables: {
      platformId,
      sortColumn,
      sortDirection
    }
  });

  const { walletBalances = [] } = data;

  return {
    walletBalances,
    loading,
    sortColumn,
    sortDirection,
    toggleSorting
  };
}
