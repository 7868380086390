import { useState } from 'react';
import { sortDirs, sortColumns } from './walletBalancesSorting';

export function useWalletBalancesSorting() {
  const [sortColumn, setSortColumn] = useState(sortColumns.market);
  const [sortDirection, setSortDirection] = useState(sortDirs.asc);

  function toggleSorting(column) {
    function tryToggleFor(targetColumn) {
      if (column === targetColumn) {
        if (sortColumn !== targetColumn) {
          setSortColumn(targetColumn);
          setSortDirection(sortDirs.desc);
        } else {
          if (sortDirection === sortDirs.desc) {
            setSortDirection(sortDirs.asc);
          } else if (sortDirection === sortDirs.asc) {
            setSortColumn(null);
            setSortDirection(null);
          }
        }
        return true;
      }
      return false;
    }
    tryToggleFor(sortColumns.market);
    tryToggleFor(sortColumns.balance);
  }

  return {
    sortColumn,
    sortDirection,
    toggleSorting
  };
}
