import * as timesync from 'timesync';

const TIMESYNC_ENDPOINT = '/timesync';
const TIMESYNC_INTERVAL = 10000;

const argoTime = {
  startTimeSync() {
    this.timeSyncInstance = timesync.create({
      server: TIMESYNC_ENDPOINT,
      interval: TIMESYNC_INTERVAL
    });
    this.timeSyncInstance.on('change', offset => {
      console.debug('Synchronized server time. Offset:', offset + 'ms');
    });
  },
  now() {
    return new Date(this.timeSyncInstance.now());
  }
};

export default argoTime;
