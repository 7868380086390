const digitsCache = {};

function getDigits(precision) {
  if (Number.isFinite(digitsCache[precision])) {
    return digitsCache[precision];
  } else {
    let digits = null;
    if (precision % 1 === 0) {
      digits = 0;
    } else {
      const symbols = precision.toFixed(8).split('');
      while (symbols[symbols.length - 1] === '0') {
        symbols.pop();
      }
      digits = symbols.length - 2;
    }
    digitsCache[precision] = digits;
    return digits;
  }
}

export function formatAmount(
  amount,
  amountPrecision = 1e-8,
  defaultValue = 'N/A'
) {
  if (Number.isFinite(amount)) {
    function formatDecimal(value) {
      const digits = getDigits(amountPrecision);
      return value.toLocaleString('en', {
        style: 'decimal',
        minimumFractionDigits: digits,
        maximumFractionDigits: digits
      });
    }
    return formatDecimal(
      (amount > 0
        ? Math.floor(amount / amountPrecision)
        : Math.round(amount / amountPrecision)) * amountPrecision
    );
  }
  return defaultValue;
}

export function formatPrice(price, defaultValue = 'N/A') {
  if (Number.isFinite(price)) {
    return price.toLocaleString('en', {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 8
    });
  }
  return defaultValue;
}

export function getAmountPrecision(market) {
  return market ? market.amountPrecision : 1e-8;
}

export function getAmountCurrency(market) {
  return market ? market.marketCurrency : '';
}
