import React, { useState } from 'react';
import styled from 'styled-components';
import classes from 'classnames';
import AgeLabel from 'components/dashboard/AgeLabel';
import TotalBalancesTableContainer from './TotalBalancesTableContainer';
import AutoFocusTextInput from 'components/ui/AutoFocusTextInput';
import { colors } from 'ui';
import { Box, Card, Flex } from 'components/ui/layout';
import { OutlineButton } from 'components/ui/buttons';
import { PrimaryButton } from 'components/ui/buttons';
import { CaretDown, CaretUp } from 'components/ui/icons';
import { useMarketSearch } from 'components/useMarketSearch';
import { useTotalBalances } from './useTotalBalances';
import { sortColumns, sortDirs } from './totalBalancesSorting';
import { formatAmount } from 'utils/format';

const width = '100%';

const CaretDownIcon = styled(CaretDown)`
  width: 14px;
  height: 14px;
`;

const CaretUpIcon = styled(CaretUp)`
  width: 14px;
  height: 14px;
`;

const Wrapper = styled.div`
  .button-with-age-label {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .clippable-text {
    max-width: 150px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  table {
    display: block;
    overflow: auto;
    font-feature-settings: 'tnum' 1, 'lnum' 1;
    border-collapse: collapse;

    .green {
      color: ${colors.green};
    }

    .red {
      color: ${colors.red1};
    }

    td,
    th {
      height: 30px;

      &.v-center {
        display: flex;
        align-items: center;
      }

      &.center {
        text-align: center;
      }

      &.right {
        text-align: right;
      }
    }

    tfoot {
      td {
        padding-top: 25px;
      }
    }

    thead {
      th {
        position: sticky;
        top: 0px;
        background: ${colors.neutral5};
      }
    }

    tfoot {
      th {
        position: sticky;
        bottom: 0px;
        background: ${colors.neutral5};
      }
    }

    tbody {
      tr {
        transition: background-color 100ms ease-in;

        &:hover {
          background-color: ${colors.neutral10};
        }
      }
    }

    th {
      text-align: left;
      padding: 6px 8px;
      vertical-align: center;
      white-space: nowrap;

      .sortable {
        cursor: pointer;
        display: inline-block;
        transition: color 200ms ease;

        &:hover {
          color: ${colors.neutral90};
        }
      }
    }

    td {
      padding: 6px 8px;
    }
  }
`;

function MarketHeader({ sortBy, sortDir, toggleSort }) {
  return (
    <span
      className="sortable"
      onClick={() => toggleSort(sortColumns.market)}
      style={{ minWidth: '60px' }}
    >
      Market{' '}
      {sortBy === sortColumns.market ? (
        sortDir === sortDir.asc ? (
          <CaretUpIcon />
        ) : (
          <CaretDownIcon />
        )
      ) : null}
    </span>
  );
}

function MarginHeader({ sortBy, sortDir, toggleSort }) {
  return (
    <span
      className="sortable"
      onClick={() => toggleSort(sortColumns.margin)}
      style={{ minWidth: '80px' }}
    >
      {sortBy === sortColumns.margin ? (
        sortDir === sortDirs.asc ? (
          <CaretUpIcon />
        ) : (
          <CaretDownIcon />
        )
      ) : null}{' '}
      Margin
    </span>
  );
}

function OtherHeader({ sortBy, sortDir, toggleSort }) {
  return (
    <span
      className="sortable"
      onClick={() => toggleSort(sortColumns.other)}
      style={{ minWidth: '80px' }}
    >
      {sortBy === sortColumns.other ? (
        sortDir === sortDirs.asc ? (
          <CaretUpIcon />
        ) : (
          <CaretDownIcon />
        )
      ) : null}{' '}
      Other
    </span>
  );
}

function ActualToTotalDiffHeader({ sortBy, sortDir, toggleSort }) {
  return (
    <span
      className="sortable"
      onClick={() => toggleSort(sortColumns.actualToTotalDiff)}
      style={{ minWidth: '80px' }}
    >
      {sortBy === sortColumns.actualToTotalDiff ? (
        sortDir === sortDir.asc ? (
          <CaretUpIcon />
        ) : (
          <CaretDownIcon />
        )
      ) : null}{' '}
      Actual – Total
    </span>
  );
}

function ActualToSavedDiffHeader({ sortBy, sortDir, toggleSort }) {
  return (
    <span
      className="sortable"
      onClick={() => toggleSort(sortColumns.actualToSavedDiff)}
      style={{ minWidth: '80px' }}
    >
      {sortBy === sortColumns.actualToSavedDiff ? (
        sortDir === sortDirs.asc ? (
          <CaretUpIcon />
        ) : (
          <CaretDownIcon />
        )
      ) : null}{' '}
      Actual – Saved
    </span>
  );
}

function TotalBtcHeader({ sortBy, sortDir, toggleSort }) {
  return (
    <span
      className="sortable"
      onClick={() => toggleSort(sortColumns.totalBtc)}
      style={{ minWidth: '80px' }}
    >
      {sortBy === sortColumns.totalBtc ? (
        sortDir === sortDirs.asc ? (
          <CaretUpIcon />
        ) : (
          <CaretDownIcon />
        )
      ) : null}{' '}
      Total BTC
    </span>
  );
}

function ActualBtcHeader({ sortBy, sortDir, toggleSort }) {
  return (
    <span
      className="sortable"
      onClick={() => toggleSort(sortColumns.actualBtc)}
      style={{ minWidth: '80px' }}
    >
      {sortBy === sortColumns.actualBtc ? (
        sortDir === sortDirs.asc ? (
          <CaretUpIcon />
        ) : (
          <CaretDownIcon />
        )
      ) : null}{' '}
      Actual BTC
    </span>
  );
}

function useBtcTotals({ items }) {
  const [isBtcTotalsOn, setIsBtcTotalsOn] = useState(
    localStorage.getItem('balances_btc_totals_on') === 'true'
  );

  function toggleBtcTotalsOn() {
    setIsBtcTotalsOn(!isBtcTotalsOn);
    localStorage.setItem(
      'balances_btc_totals_on',
      JSON.stringify(!isBtcTotalsOn)
    );
  }

  function calculateTotals() {
    let count = items.length;
    let marginCount = 0;
    let holdCount = 0;
    let total = 0;
    let actual = 0;
    let hold = 0;
    for (const item of items) {
      marginCount += item.totalMarginAmount !== 0 ? 1 : 0;
      holdCount += item.totalHoldAmount !== 0 ? 1 : 0;
      total += item.totalTotalAmountBtc;
      actual += item.totalActualAmountBtc;
      hold += item.totalHoldAmountBtc;
    }
    return {
      count,
      marginCount,
      holdCount,
      total,
      actual,
      hold
    };
  }

  return {
    isBtcTotalsOn,
    toggleBtcTotalsOn,
    totals: calculateTotals()
  };
}

function TotalBalances() {
  const {
    items,
    btcAmountPrecision,
    totalActualAmountSyncTime,
    totalMarginAmountSyncTime,
    totalSavedAmountSyncTime,
    syncActual,
    syncMargin,
    save,
    isSyncingActual,
    isSyncingMargin,
    isSaving,
    isResetting,
    sortBy,
    sortDir,
    toggleSort,
    showResetConfirmation,
    hideResetConfirmation,
    confirmReset,
    isResetConfirmationVisible
  } = useTotalBalances();

  const { searchedItems, searchText, updateSearchText } = useMarketSearch({
    items
  });

  const { totals, isBtcTotalsOn, toggleBtcTotalsOn } = useBtcTotals({
    items: searchedItems
  });

  return (
    <Wrapper>
      <Box>
        <Flex
          marginBottom="15px"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Flex alignItems="center" height="38px">
            <OutlineButton active={isBtcTotalsOn} onClick={toggleBtcTotalsOn}>
              BTC
            </OutlineButton>
            <Box marginLeft="10px">
              <AutoFocusTextInput
                placeholder="Search market…"
                style={{ paddingBottom: '1px' }}
                value={searchText}
                onChange={event => updateSearchText(event.target.value)}
              />
            </Box>
          </Flex>
          <Flex alignItems="flex-start">
            <OutlineButton
              onClick={showResetConfirmation}
              disabled={isResetConfirmationVisible}
            >
              Reset
            </OutlineButton>
            <div className="button-with-age-label">
              <PrimaryButton
                onClick={syncActual}
                disabled={isSyncingActual || isResetConfirmationVisible}
                marginLeft="1.5rem"
                marginBottom="5px"
              >
                Sync Actual
              </PrimaryButton>
              <AgeLabel startTime={totalActualAmountSyncTime} />
            </div>
            <div className="button-with-age-label">
              <PrimaryButton
                onClick={save}
                disabled={isSaving || isResetConfirmationVisible}
                marginLeft="1.5rem"
                marginBottom="5px"
              >
                Save
              </PrimaryButton>
              <AgeLabel startTime={totalSavedAmountSyncTime} />
            </div>
            <div className="button-with-age-label">
              <PrimaryButton
                onClick={syncMargin}
                disabled={isSyncingMargin || isResetConfirmationVisible}
                marginLeft="1.5rem"
                marginBottom="5px"
              >
                Sync Margin
              </PrimaryButton>
              <AgeLabel startTime={totalMarginAmountSyncTime} />
            </div>
          </Flex>
        </Flex>
        <TotalBalancesTableContainer>
          {({ height }) => {
            // To avoid displaying part of the row, use 30px step
            const tableHeight = height - (height % 30);
            return (
              <Box inlineFlexBox width="100%" flexDirection="column">
                <Flex>
                  {isResetConfirmationVisible ? (
                    <Card
                      flexBox
                      minWidth={width}
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Box marginBottom="1.5rem" marginTop="2rem">
                        Are you sure you want to reset all total balances?
                      </Box>
                      <Box marginBottom="2rem">
                        <OutlineButton
                          disabled={isResetting}
                          onClick={hideResetConfirmation}
                        >
                          Cancel
                        </OutlineButton>
                        <PrimaryButton
                          marginLeft="1rem"
                          disabled={isResetting}
                          onClick={confirmReset}
                        >
                          Yes, Reset
                        </PrimaryButton>
                      </Box>
                    </Card>
                  ) : (
                    <Card fontSize="0.75em" width="100%">
                      <table style={{ height: tableHeight }}>
                        <thead>
                          <tr>
                            <th>
                              <MarketHeader
                                sortBy={sortBy}
                                sortDir={sortDir}
                                toggleSort={toggleSort}
                              />
                            </th>
                            <th className="right">Total</th>
                            {isBtcTotalsOn ? (
                              <th className="right">
                                <TotalBtcHeader
                                  sortBy={sortBy}
                                  sortDir={sortDir}
                                  toggleSort={toggleSort}
                                />
                              </th>
                            ) : null}
                            <th className="right">Actual</th>
                            {isBtcTotalsOn ? (
                              <th className="right">
                                <ActualBtcHeader
                                  sortBy={sortBy}
                                  sortDir={sortDir}
                                  toggleSort={toggleSort}
                                />
                              </th>
                            ) : null}
                            <th className="right">
                              <ActualToTotalDiffHeader
                                sortBy={sortBy}
                                sortDir={sortDir}
                                toggleSort={toggleSort}
                              />
                            </th>
                            <th className="right">Saved</th>
                            <th className="right">
                              <ActualToSavedDiffHeader
                                sortBy={sortBy}
                                sortDir={sortDir}
                                toggleSort={toggleSort}
                              />
                            </th>
                            <th style={{ width: '100%' }}></th>
                            <th className="right">Hold</th>
                            {isBtcTotalsOn ? (
                              <th className="right">Hold BTC</th>
                            ) : null}
                            <th className="right">
                              <MarginHeader
                                sortBy={sortBy}
                                sortDir={sortDir}
                                toggleSort={toggleSort}
                              />
                            </th>
                            <th className="right">
                              <OtherHeader
                                sortBy={sortBy}
                                sortDir={sortDir}
                                toggleSort={toggleSort}
                              />
                            </th>
                            <th>Comment</th>
                          </tr>
                        </thead>
                        <tbody>
                          {searchedItems.map(totalBalance => {
                            const {
                              marketCurrency,
                              comment,
                              totalHoldAmount,
                              totalHoldAmountBtc,
                              totalOtherAmount,
                              totalActualAmount,
                              totalActualAmountBtc,
                              totalSavedAmount,
                              totalMarginAmount,
                              totalTotalAmount,
                              totalTotalAmountBtc,
                              actualToTotalDiff,
                              actualToSavedDiff,
                              amountPrecision
                            } = totalBalance;
                            return (
                              <tr key={marketCurrency}>
                                <td>{marketCurrency}</td>
                                <td className="right">
                                  {formatAmount(
                                    totalTotalAmount,
                                    amountPrecision
                                  )}
                                </td>
                                {isBtcTotalsOn ? (
                                  <td className="right">
                                    {formatAmount(
                                      totalTotalAmountBtc,
                                      btcAmountPrecision
                                    )}
                                  </td>
                                ) : null}
                                <td className="right">
                                  {formatAmount(
                                    totalActualAmount,
                                    amountPrecision
                                  )}
                                </td>
                                {isBtcTotalsOn ? (
                                  <td className="right">
                                    {formatAmount(
                                      totalActualAmountBtc,
                                      btcAmountPrecision
                                    )}
                                  </td>
                                ) : null}
                                <td
                                  className={classes('right', {
                                    red: actualToTotalDiff < 0,
                                    green: actualToTotalDiff > 0
                                  })}
                                >
                                  {formatAmount(
                                    actualToTotalDiff,
                                    amountPrecision
                                  )}
                                </td>
                                <td className="right">
                                  {formatAmount(
                                    totalSavedAmount,
                                    amountPrecision
                                  )}
                                </td>
                                <td
                                  className={classes('right', {
                                    red: actualToSavedDiff < 0,
                                    green: actualToSavedDiff > 0
                                  })}
                                >
                                  {formatAmount(
                                    actualToSavedDiff,
                                    amountPrecision
                                  )}
                                </td>
                                <td></td>
                                <td className="right">
                                  {formatAmount(
                                    totalHoldAmount,
                                    amountPrecision
                                  )}
                                </td>
                                {isBtcTotalsOn ? (
                                  <td className="right">
                                    {formatAmount(
                                      totalHoldAmountBtc,
                                      btcAmountPrecision
                                    )}
                                  </td>
                                ) : null}
                                <td
                                  className={classes('right', {
                                    red: totalMarginAmount < 0,
                                    green: totalMarginAmount > 0
                                  })}
                                >
                                  {totalMarginAmount
                                    ? formatAmount(
                                        Math.abs(totalMarginAmount),
                                        amountPrecision
                                      )
                                    : null}
                                </td>
                                <td
                                  className={classes('right', {
                                    red: totalOtherAmount < 0,
                                    green: totalOtherAmount > 0
                                  })}
                                >
                                  {totalOtherAmount
                                    ? formatAmount(
                                        Math.abs(totalOtherAmount),
                                        amountPrecision
                                      )
                                    : null}
                                </td>
                                <td>
                                  <div className="clippable-text">
                                    {comment}
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                        <tfoot>
                          <tr>
                            <th>{totals.count}</th>
                            <th></th>
                            {isBtcTotalsOn ? (
                              <th className="right">
                                {formatAmount(totals.total, btcAmountPrecision)}
                              </th>
                            ) : null}
                            <th></th>
                            {isBtcTotalsOn ? (
                              <th className="right">
                                {formatAmount(
                                  totals.actual,
                                  btcAmountPrecision
                                )}
                              </th>
                            ) : null}
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th className="right">{totals.holdCount}</th>
                            {isBtcTotalsOn ? (
                              <th className="right">
                                {formatAmount(totals.hold, btcAmountPrecision)}
                              </th>
                            ) : null}
                            <th className="right">{totals.marginCount}</th>
                            <th></th>
                            <th></th>
                          </tr>
                        </tfoot>
                      </table>
                    </Card>
                  )}
                </Flex>
              </Box>
            );
          }}
        </TotalBalancesTableContainer>
      </Box>
    </Wrapper>
  );
}

export default TotalBalances;
