import React, { useRef, useImperativeHandle, forwardRef } from 'react';
import { OutlineButton } from 'components/ui/buttons';
import { useMutation } from '@apollo/react-hooks';

import cancelBithumbOrder from './graphql/cancelBithumbOrder.gql';

function OrderRow(
  { order: { id, type, marketCurrency, displayName } = {} },
  ref
) {
  function handleCancelClick() {
    cancel({ variables: { orderId: id, orderType: type, marketCurrency } });
  }
  function getButtonDisplayText() {
    if (isCanceling) {
      return '…';
    }
    if (isSuccess) {
      return 'Done';
    }
    if (isError) {
      return 'Failed';
    }
    return 'Cancel';
  }
  const buttonRef = useRef();
  useImperativeHandle(ref, () => ({
    cancel: () => {
      buttonRef.current.click();
    }
  }));
  const [cancel, cancelResult] = useMutation(cancelBithumbOrder);
  const isCanceling = cancelResult.loading;
  const isError = Boolean(cancelResult.error);
  const isSuccess = cancelResult.data && cancelResult.data.cancelBithumbOrder;
  const isButtonDisabled = isCanceling || isSuccess || isError;
  return (
    <tr>
      <td>{id}</td>
      <td>{displayName}</td>
      <td>
        <OutlineButton
          small
          ref={buttonRef}
          width="64px"
          onClick={handleCancelClick}
          disabled={isButtonDisabled}
        >
          {getButtonDisplayText()}
        </OutlineButton>
      </td>
    </tr>
  );
}

export default forwardRef(OrderRow);
