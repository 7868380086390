import React from 'react';
import WalletsToolbar from './WalletsToolbar';
import WalletBalances from './WalletBalances';
import { useWallets } from './useWallets';

const WalletsPage = () => {
  const { platformId, selectPlatform, platforms } = useWallets();
  return (
    <div>
      <WalletsToolbar
        platformId={platformId}
        selectPlatform={selectPlatform}
        platforms={platforms}
      />
      <WalletBalances platformId={platformId} />
    </div>
  );
};

export default WalletsPage;
