const authStore = {
  getAccessToken() {
    return localStorage.getItem('access_token');
  },
  setAccessToken(token) {
    localStorage.setItem('access_token', token);
  }
};

export default authStore;
