import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import GridSection from './GridSection';
import Select from 'components/Select';
import Switch from 'components/ui/Switch';
import { OutlineButton } from 'components/ui/buttons';
import { Bell } from 'components/ui/icons';
import { Card, Box, Flex } from 'components/ui/layout';
import { TextInput } from 'components/ui/inputs';
import { colors } from 'ui';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { formatAmount, getAmountPrecision } from 'utils/format';
import { useWalletBalancesPlatforms } from 'pages/wallets/useWalletBalancesPlatforms';
import { useWalletBalances } from 'pages/wallets/useWalletBalances';
import { parseDecimal } from 'utils/number';

import alertAudio from 'media/walletAlert.mp3';
import getWalletGql from './graphql/getWallet.gql';
import startEtherscanOneGql from './graphql/startEtherscanOne.gql';
import stopEtherscanOneGql from './graphql/stopEtherscanOne.gql';
import updateSettingGql from 'components/settings/graphql/updateSetting.gql';

const MinBalanceTextInput = styled(TextInput)`
  border-color: ${props => (props.isToggled ? colors.green : undefined)};

  &:hover {
    border-color: ${props => (props.isToggled ? colors.green : undefined)};
  }

  &:focus {
    border-color: ${props => (props.isToggled ? colors.green : undefined)};
  }
`;

const IconButton = styled.button`
  display: flex;
  color: inherit;
  align-items: center;
  justify-content: center;
  outline-style: none;
  border-radius: 50%;
  border-color: transparent;
  border-width: 0;
  height: ${props => props.size};
  width: ${props => props.size};
  background-color: ${props => (props.active ? colors.overlay : 'transparent')};
  transition: background-color 150ms ease;

  &:hover {
    background-color: ${colors.overlay};
  }
`;

const Footer = styled.div`
  display: flex;
  height: 36px;
  justify-content: space-between;
  align-items: center;
  padding-top: 1px;
  padding: 6px 10px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  background-color: ${colors.neutral10};
`;

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 36px);
  padding: 10px 10px 0px 10px;

  .balance-container {
    font-size: 1rem;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      cursor: pointer;
      transition: opacity 150ms ease;

      &.green {
        color: ${colors.green};
      }

      &:hover {
        opacity: 0.8;
      }
    }
  }

  .button-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    button {
      width: 52px;
    }
  }

  .select-container {
    font-size: 1rem;
    width: 100%;
  }
`;

function useWallet() {
  const { data = {}, loading } = useQuery(getWalletGql, {
    fetchPolicy: 'network-only',
    pollInterval: 1000
  });
  const { wallet = {} } = data;
  return { wallet, loading };
}

function useMinBalanceValue() {
  const [value, setValue] = useState(
    JSON.parse(localStorage.getItem('wallet_min_balance_value') || null)
  );

  function setMinBalanceValue(value) {
    // Set min balance value only for positive numeric values:
    if (Number(value) > 0) {
      setValue(value);
      localStorage.setItem('wallet_min_balance_value', JSON.stringify(value));
    }
  }

  function resetMinBalanceValue() {
    setValue(null);
    localStorage.setItem('wallet_min_balance_value', JSON.stringify(null));
  }

  return {
    minBalanceValue: Number(value) || null,
    setMinBalanceValue,
    resetMinBalanceValue
  };
}

function useAudioAlert() {
  const audioRef = useRef(null);

  const [isAlertEnabled, setIsAlertEnabled] = useState(
    localStorage.getItem('wallet_audio_alert_enabled') === 'true'
  );

  function toggleAudioAlert() {
    if (!isAlertEnabled) {
      pauseAudioAlert();
    }
    setIsAlertEnabled(!isAlertEnabled);
    localStorage.setItem(
      'wallet_audio_alert_enabled',
      JSON.stringify(!isAlertEnabled)
    );
  }

  function playAudioAlert() {
    if (audioRef.current) {
      if (isAlertEnabled) {
        audioRef.current.play();
      }
    }
  }

  function pauseAudioAlert() {
    if (audioRef.current) {
      audioRef.current.pause();
    }
  }

  return {
    audioRef,
    isAlertEnabled,
    toggleAudioAlert,
    playAudioAlert
  };
}

function WalletCard({ markets }) {
  const {
    minBalanceValue,
    setMinBalanceValue,
    resetMinBalanceValue
  } = useMinBalanceValue();

  const {
    audioRef,
    isAlertEnabled,
    playAudioAlert,
    toggleAudioAlert
  } = useAudioAlert();

  const [startMutation] = useMutation(startEtherscanOneGql);
  const [stopMutation] = useMutation(stopEtherscanOneGql);
  const [updateSettingMutation] = useMutation(updateSettingGql);
  const [isStarting, setIsStarting] = useState(false);
  const [isStopping, setIsStopping] = useState(false);
  const [minBalance, setMinBalance] = useState(minBalanceValue);
  const [platform, setPlatform] = useState(null);
  const [market, setMarket] = useState(null);
  const [prevBalance, setPrevBalance] = useState(null);
  const [isAutoStartEnabled, setAutoStartEnabled] = useState(null);

  const { platforms, loading: platformsLoading } = useWalletBalancesPlatforms();

  const { walletBalances, loading: walletBalancesLoading } = useWalletBalances({
    pollInterval: 0,
    platformId: platform ? platform.id : null
  });

  const { wallet, loading: walletLoading } = useWallet();

  const loading = platformsLoading || walletLoading || walletBalancesLoading;
  const isStarted = wallet.isStarted;
  const platformSelected = platform !== null;
  const marketSelected = market !== null;
  const balance = isStarted ? wallet.balance : null;
  const isGreen = minBalanceValue !== null && balance >= minBalanceValue;
  const marketCurrencyPrecision = isStarted
    ? getAmountPrecision(markets[wallet.marketCurrency])
    : null;

  async function start() {
    if (platformSelected && marketSelected) {
      const platformId = platform.id;
      const marketCurrency = market.marketCurrency;
      setIsStarting(true);
      await startMutation({ variables: { platformId, marketCurrency } });
    }
  }

  async function stop() {
    setIsStopping(true);
    await stopMutation();
  }

  function selectPlatform(selectedPlatform) {
    setPlatform(selectedPlatform);
    if (selectedPlatform !== platform) {
      setMarket(null);
    }
  }

  function updateMinBalance(event) {
    setMinBalance(event.target.value);
    resetMinBalanceValue();
  }

  function selectMarket(market) {
    setMarket(market);
  }

  function blurMinBalance() {
    setMinBalanceValue(minBalance);
  }

  function handleKeyDown(event) {
    if (event.keyCode === 13) {
      event.target.blur();
    }
  }

  function handleBalanceClick() {
    const value = parseDecimal(
      formatAmount(balance * 1.01, marketCurrencyPrecision)
    );
    setMinBalance(value);
    setMinBalanceValue(value);
  }

  async function toggleAutoStart() {
    const isEnabled = !isAutoStartEnabled;
    setAutoStartEnabled(isEnabled);
    await updateSettingMutation({
      variables: {
        name: 'etherscanOneAutoStart',
        value: JSON.stringify(isEnabled)
      }
    });
  }

  useEffect(() => {
    if (isAutoStartEnabled === null && !walletLoading) {
      setAutoStartEnabled(wallet.useAutoStart);
    }
  }, [wallet, walletLoading, isAutoStartEnabled]);

  useEffect(() => {
    if (prevBalance !== null && prevBalance < minBalanceValue && isGreen) {
      playAudioAlert();
    }
  }, [isGreen, prevBalance, minBalanceValue, playAudioAlert]);

  useEffect(() => {
    if (wallet.isStarted && !loading) {
      if (prevBalance === null || balance !== prevBalance) {
        setPrevBalance(balance);
      }
    }
  }, [prevBalance, balance, wallet, loading]);

  useEffect(() => {
    if (wallet.isStarted && !loading) {
      const platformId = wallet.platformId;
      const walletMarketCurrency = wallet.marketCurrency;
      const walletPlatform = platforms.find(({ id }) => id === platformId);
      const walletMarket = walletBalances.find(
        ({ marketCurrency }) => marketCurrency === walletMarketCurrency
      );
      if (platform !== walletPlatform) {
        setPlatform(walletPlatform);
      }
      if (market !== walletMarket) {
        setMarket(walletMarket);
      }
    }
  }, [wallet, platform, market, walletBalances, platforms, loading]);

  useEffect(() => {
    if (isStarting && wallet.isStarted) {
      setIsStarting(false);
    }
  }, [isStarting, wallet]);

  useEffect(() => {
    if (isStopping && !wallet.isStarted) {
      setIsStopping(false);
    }
  }, [isStopping, wallet]);

  return (
    <GridSection
      startTime={wallet.startTime}
      header={
        <Flex flex="1" justifyContent="space-between" alignItems="center">
          <div>Wallet</div>
          <IconButton size="24px" onClick={toggleAudioAlert}>
            <Bell
              size={24}
              color={isAlertEnabled ? colors.primary : undefined}
            />
          </IconButton>
        </Flex>
      }
    >
      <Card height="275px" padding="0" style={{ width: '180px' }}>
        <audio ref={audioRef} src={alertAudio} loop={isAlertEnabled} />
        <Wrapper>
          <div>
            <Box className="select-container" marginBottom="10px">
              <Select
                placeholder="Platform…"
                options={platforms}
                getOptionLabel={({ name }) => name}
                getOptionValue={({ id }) => id}
                value={platform}
                onChange={selectPlatform}
                isDisabled={isStarted || loading}
                isClearable
              />
            </Box>
            <Box className="select-container" marginBottom="10px">
              <Select
                placeholder="Market…"
                isDisabled={isStarted || loading}
                options={walletBalances}
                value={market}
                onChange={selectMarket}
                getOptionLabel={({ marketCurrency }) => marketCurrency}
                getOptionValue={({ marketCurrency }) => marketCurrency}
                isClearable
              />
            </Box>
            <Box className="select-container">
              <MinBalanceTextInput
                isToggled={minBalanceValue !== null}
                width="100%"
                height="38px"
                placeholder="Set min balance…"
                style={{ paddingBottom: '1px' }}
                onKeyDown={handleKeyDown}
                onChange={updateMinBalance}
                onBlur={blurMinBalance}
                value={minBalance || ''}
              />
            </Box>
          </div>
          {!loading && isStarted ? (
            <div className="balance-container">
              <span
                onClick={handleBalanceClick}
                className={isGreen ? 'green' : undefined}
              >
                {formatAmount(balance, marketCurrencyPrecision)}
              </span>
            </div>
          ) : null}
        </Wrapper>
        <Footer>
          {loading ? null : (
            <>
              <div>
                {isAutoStartEnabled === null ? null : (
                  <Switch
                    small
                    isOn={isAutoStartEnabled}
                    onChange={toggleAutoStart}
                  />
                )}
              </div>
              <div>
                {isStarted ? (
                  <OutlineButton
                    small
                    onClick={stop}
                    disabled={!isStarted || isStopping}
                  >
                    {isStopping ? '…' : 'Stop'}
                  </OutlineButton>
                ) : (
                  <OutlineButton
                    small
                    onClick={start}
                    disabled={
                      !platformSelected || !marketSelected || isStarting
                    }
                  >
                    {isStarting ? '…' : 'Start'}
                  </OutlineButton>
                )}
              </div>
            </>
          )}
        </Footer>
      </Card>
    </GridSection>
  );
}

export default WalletCard;
