import { css } from 'styled-components';

export const colorProps = ({
  color,
  backgroundColor,
  backgroundImage,
  backgroundSize
}) => css`
  color: ${color};
  background-color: ${backgroundColor};
  background-image: ${backgroundImage};
  background-size: ${backgroundSize};
`;
