import React from 'react';
import HealthPage from '../HealthPage';
import ProxiesTable from './ProxiesTable';
import styled from 'styled-components';
import { Container } from 'components/ui/layout';

const Content = styled(Container)`
  padding-top: 15px;
  display: flex;
  flex-wrap: wrap;
`;

const HealthProxiesPage = () => (
  <HealthPage>
    <Content>
      <ProxiesTable
        proxyType="SCAN"
        title="Scanner proxies"
        marginRight="35px"
      />
      <ProxiesTable proxyType="TRADE" title="Trading proxies" />
    </Content>
  </HealthPage>
);

export default HealthProxiesPage;
