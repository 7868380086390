import React from 'react';
import { Button, OutlineButton } from 'components/ui/buttons';
import { trackingStates } from './trackingStates';

const PauseButton = ({ trackingState, onClick = () => {} }) => {
  if (trackingState === trackingStates.onHold) {
    return (
      <Button marginRight="5px" disabled>
        On Hold
      </Button>
    );
  }
  if (trackingState === trackingStates.started) {
    return (
      <OutlineButton
        marginRight="5px"
        onClick={onClick}
        disabled={trackingState === trackingStates.stopped}
      >
        Pause
      </OutlineButton>
    );
  }
  return null;
};

export default PauseButton;
