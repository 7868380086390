import { useState } from 'react';
import { sortDirs, isolatedSortColumns } from './marginSorting';

export function useIsolatedMarginSorting() {
  const [sortColumn, setSortColumn] = useState(isolatedSortColumns.market);
  const [sortDirection, setSortDirection] = useState(sortDirs.asc);

  function toggleSorting(column) {
    function tryToggleFor(targetColumn) {
      if (column === targetColumn) {
        if (sortColumn !== targetColumn) {
          setSortColumn(targetColumn);
          setSortDirection(sortDirs.desc);
        } else {
          if (sortDirection === sortDirs.desc) {
            setSortDirection(sortDirs.asc);
          } else if (sortDirection === sortDirs.asc) {
            setSortColumn(null);
            setSortDirection(null);
          }
        }
        return true;
      }
      return false;
    }
    tryToggleFor(isolatedSortColumns.market);
    tryToggleFor(isolatedSortColumns.quote);
    tryToggleFor(isolatedSortColumns.collateral);
    tryToggleFor(isolatedSortColumns.marginLevel);
    tryToggleFor(isolatedSortColumns.interest);
  }

  return {
    sortColumn,
    sortDirection,
    toggleSorting
  };
}
