import React from 'react';
import styled from 'styled-components';
import { withAuth } from 'components/auth/withAuth';
import { colors } from 'ui';
import { FieldLabel } from 'components/ui/fields';
import { Flex, Absolute } from 'components/ui/layout';

const Wrapper = styled(Absolute)`
  top: 100%;
  right: 0;
  z-index: 1;
  width: 170px;
  background-color: ${colors.neutral5};
  color: ${colors.bodyText};
  box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1);
  margin-bottom: 8px;
  margin-top: 8px;
`;

const Menu = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 4px 0;
`;

const MenuItem = styled.li``;

const Button = styled.div`
  padding: 8px 12px;
  transition: background-color 150ms ease;

  &:hover {
    background-color: ${colors.neutral20};
    cursor: pointer;
  }
`;

const Separator = styled.div`
  border-top: 1px solid ${colors.neutral20};
  display: block;
  height: 0;
  margin: 8px 0;
`;

const ProfileMenuPopover = ({ username, authenticate }) => (
  <Wrapper>
    <Menu>
      <MenuItem>
        <Flex flexDirection="column" padding="8px 12px">
          <FieldLabel marginBottom="4px">Logged in as</FieldLabel>
          <span>{username}</span>
        </Flex>
      </MenuItem>
      <MenuItem>
        <Separator />
      </MenuItem>
      <MenuItem>
        <Button onClick={() => authenticate()}>Log out</Button>
      </MenuItem>
    </Menu>
  </Wrapper>
);

export default withAuth(ProfileMenuPopover);
