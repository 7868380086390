import { css } from 'styled-components';

export const textProps = ({
  center,
  color,
  opacity,
  fontSize,
  fontWeight,
  lineHeight
}) => css`
  text-align: ${center ? 'center' : undefined};
  font-size: ${fontSize};
  font-weight: ${fontWeight};
  color: ${color};
  opacity: ${opacity};
  line-height: ${lineHeight};
`;
