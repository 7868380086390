import React from 'react';
import styled from 'styled-components';
import uniqueString from 'unique-string';
import { colors } from 'ui';

const SwitchInput = styled.input.attrs({ type: 'checkbox' })`
  display: none;
`;

function getWidth({ small, tiny }) {
  if (small) {
    return '44px';
  }
  if (tiny) {
    return '38px';
  }
  return '56px';
}

function getHeight({ small, tiny }) {
  if (small) {
    return '24px';
  }
  if (tiny) {
    return '20px';
  }
  return '28px';
}

function getToggleSize({ small, tiny }) {
  if (small) {
    return '16px';
  }
  if (tiny) {
    return '12px';
  }
  return '20px';
}

const SwitchLabel = styled.label`
  cursor: pointer;
  width: ${getWidth};
  background: ${colors.neutral20};
  display: block;
  height: ${getHeight};
  border-radius: 24px;
  position: relative;

  ${SwitchInput}:checked + & {
    background: ${colors.primary};
  }

  ${SwitchInput}:checked + &:after {
    left: calc(100% - 4px);
    transform: translateX(-100%);
  }

  &:after {
    content: '';
    position: absolute;
    top: 4px;
    left: 4px;
    width: ${getToggleSize};
    height: ${getToggleSize};
    background: ${colors.neutral90};
    border-radius: 10px;
    transition: 0.15s;
  }
`;

class Switch extends React.Component {
  constructor(props) {
    super(props);
    this.id = uniqueString();
  }
  handleChange = () => {
    if (this.props.onChange) {
      this.props.onChange(!this.props.isOn);
    }
  };
  render() {
    return (
      <>
        <SwitchInput
          id={this.id}
          checked={this.props.isOn}
          onChange={this.handleChange}
        />
        <SwitchLabel
          small={this.props.small}
          tiny={this.props.tiny}
          htmlFor={this.id}
        />
      </>
    );
  }
}

export default Switch;
