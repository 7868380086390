import { useState, useEffect, useMemo } from 'react';
import { trackingStates } from './trackingStates';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { pipe } from 'utils/func';
import { haveSameKeys } from 'utils/object';

import startTrackingGql from './graphql/startArbitrageTracking.gql';
import stopTrackingGql from './graphql/stopArbitrageTracking.gql';
import pauseTrackingGql from './graphql/pauseArbitrageTracking.gql';
import getTrackingGql from './graphql/getArbitrageTracking.gql';
import getExchangesGql from './graphql/getExchanges.gql';
import getFrequentlyUsedExchangeIdsGql from './graphql/getFrequentlyUsedExchangeIds.gql';
import getMarketCurrenciesGql from './graphql/getMarketCurrencies.gql';
import getLastMarketCurrenciesGql from './graphql/getLastMarketCurrencies.gql';

const POLL_INTERVAL = 200;

const initialViewModel = {
  exchangeOptions: [],
  frequentExchangeOptions: [],
  marketCurrencyOptions: [],
  lastMarketCurrencyOptions: [],
  frequentlyUsedExchangeIds: [],
  marketCurrency: null,
  firstExchangeId: null,
  secondExchangeId: null,
  trackingState: undefined,
  isStarted: undefined
};

function getIdsObject(idList) {
  const ids = {};
  for (const id of idList) {
    ids[id] = true;
  }
  return ids;
}

function tryAutoselectExchanges(viewModel) {
  const exchangeOptions = viewModel.exchangeOptions;
  const frequentExchangeOptions = viewModel.frequentExchangeOptions;
  if (frequentExchangeOptions.length === 2) {
    const [firstOption, secondOption] = frequentExchangeOptions;
    return {
      ...viewModel,
      firstExchangeId: firstOption.value,
      secondExchangeId: secondOption.value
    };
  } else if (exchangeOptions.length === 2) {
    const [firstOption, secondOption] = exchangeOptions;
    return {
      ...viewModel,
      firstExchangeId: firstOption.value,
      secondExchangeId: secondOption.value
    };
  }
  return viewModel;
}

function ensureExchangeIds(viewModel) {
  function ensure(exchangeId) {
    const exchangeOptions = viewModel.exchangeOptions;
    if (exchangeOptions.find(({ value }) => value === exchangeId)) {
      return exchangeId;
    }
    return null;
  }
  return {
    ...viewModel,
    firstExchangeId: ensure(viewModel.firstExchangeId),
    secondExchangeId: ensure(viewModel.secondExchangeId)
  };
}

function reshapeViewModel(
  viewModel,
  updateFragment,
  {
    arbitrageTracking,
    exchanges,
    marketCurrencies,
    lastMarketCurrencies,
    frequentlyUsedExchangeIds
  }
) {
  const nextViewModel = { ...viewModel, ...updateFragment };
  function getExchangeOptions(marketCurrency) {
    return exchanges
      .filter(
        ({ marketCurrencies }) =>
          marketCurrencies.indexOf(marketCurrency) !== -1
      )
      .map(({ id, name }) => ({ value: id, label: name }));
  }
  function getFrequentExchangeOptions(exchangeOptions) {
    return frequentlyUsedExchangeIds
      .map(id => {
        return exchangeOptions.find(({ value }) => id === value);
      })
      .filter(Boolean);
  }
  function getMarketCurrencyOptions(marketCurrencies) {
    return marketCurrencies.map(currency => ({
      value: currency,
      label: currency
    }));
  }
  function getTrackingState() {
    const { trackingState } = nextViewModel;
    const { isStarted, isOnHold } = arbitrageTracking;
    if (isStarted === true) {
      if (isOnHold === true) {
        if (trackingState !== trackingStates.starting) {
          return trackingStates.onHold;
        }
      } else {
        if (trackingState !== trackingStates.stopping) {
          return trackingStates.started;
        }
      }
    } else if (isStarted === false) {
      if (trackingState !== trackingStates.starting) {
        return trackingStates.stopped;
      }
    }
    return trackingState;
  }
  const marketCurrencyOptions = getMarketCurrencyOptions(marketCurrencies);
  const lastMarketCurrencyOptions = getMarketCurrencyOptions(
    lastMarketCurrencies
  );
  const trackingState = getTrackingState();
  if (arbitrageTracking.isStarted) {
    const marketCurrency = arbitrageTracking.marketCurrency;
    const exchangeOptions = getExchangeOptions(marketCurrency);
    const frequentExchangeOptions = getFrequentExchangeOptions(exchangeOptions);
    const firstExchange = arbitrageTracking.firstExchange || {};
    const secondExchange = arbitrageTracking.secondExchange || {};
    return ensureExchangeIds({
      marketCurrency,
      firstExchangeId: firstExchange.id,
      secondExchangeId: secondExchange.id,
      frequentlyUsedExchangeIds,
      exchangeOptions,
      frequentExchangeOptions,
      marketCurrencyOptions,
      lastMarketCurrencyOptions,
      trackingState,
      isStarted: true
    });
  } else {
    const marketCurrency = nextViewModel.marketCurrency;
    const exchangeOptions = getExchangeOptions(marketCurrency);
    const frequentExchangeOptions = getFrequentExchangeOptions(exchangeOptions);
    const frequentExchangesUpdated = !haveSameKeys(
      getIdsObject(nextViewModel.frequentlyUsedExchangeIds),
      getIdsObject(frequentlyUsedExchangeIds)
    );
    return pipe(
      ensureExchangeIds,
      frequentExchangesUpdated ? tryAutoselectExchanges : _ => _
    )({
      marketCurrency,
      firstExchangeId: nextViewModel.firstExchangeId,
      secondExchangeId: nextViewModel.secondExchangeId,
      frequentlyUsedExchangeIds,
      exchangeOptions,
      frequentExchangeOptions,
      marketCurrencyOptions,
      lastMarketCurrencyOptions,
      trackingState,
      isStarted: false
    });
  }
}

function useArbitrageTracking() {
  const { data: { arbitrageTracking = {} } = {}, loading, refetch } = useQuery(
    getTrackingGql,
    {
      pollInterval: POLL_INTERVAL,
      fetchPolicy: 'network-only'
    }
  );
  return [arbitrageTracking, loading, refetch];
}

function useExchanges() {
  const { data: { exchanges = [] } = {}, loading } = useQuery(getExchangesGql);
  return [exchanges, loading];
}

function useMarketCurrencies() {
  const { data: { marketCurrencies = [] } = {}, loading } = useQuery(
    getMarketCurrenciesGql
  );
  return [marketCurrencies, loading];
}

function useLastMarketCurrencies({ onUpdate }) {
  const [prevIds, setPrevIds] = useState(null);
  const { data: { lastMarketCurrencies = [] } = {}, loading } = useQuery(
    getLastMarketCurrenciesGql,
    {
      pollInterval: 2500,
      fetchPolicy: 'network-only'
    }
  );

  useEffect(() => {
    const currentIds = getIdsObject(lastMarketCurrencies);
    if (prevIds === null) {
      if (!loading) {
        setPrevIds(currentIds);
      }
    } else if (!haveSameKeys(currentIds, prevIds)) {
      if (onUpdate) {
        onUpdate();
      }
      setPrevIds(currentIds);
    }
  }, [loading, lastMarketCurrencies, prevIds, onUpdate]);

  return [lastMarketCurrencies, loading];
}

function useFrequentExchangeIds({ marketCurrency, onUpdate }) {
  const [prevIds, setPrevIds] = useState(null);
  const { data: { frequentlyUsedExchangeIds = [] } = {}, loading } = useQuery(
    getFrequentlyUsedExchangeIdsGql,
    {
      variables: { marketCurrency },
      pollInterval: 2500,
      fetchPolicy: 'network-only'
    }
  );

  useEffect(() => {
    const currentIds = getIdsObject(frequentlyUsedExchangeIds);
    if (prevIds === null) {
      if (!loading) {
        setPrevIds(currentIds);
      }
    } else if (!haveSameKeys(currentIds, prevIds)) {
      if (onUpdate) {
        onUpdate();
      }
      setPrevIds(currentIds);
    }
  }, [loading, frequentlyUsedExchangeIds, prevIds, onUpdate]);

  return [frequentlyUsedExchangeIds, loading];
}

export function useDashboard() {
  const [loaded, setLoaded] = useState(false);
  const [viewModel, setViewModel] = useState(initialViewModel);
  const [startTrackingMutation] = useMutation(startTrackingGql);
  const [stopTrackingMutation] = useMutation(stopTrackingGql);
  const [pauseTrackingMutation] = useMutation(pauseTrackingGql);
  const marketCurrency = viewModel.marketCurrency;
  const [prevMarketCurrency, setPrevMarketCurrency] = useState(marketCurrency);
  const [exchanges, exchangesLoading] = useExchanges();
  const [frequentlyUsedExchangeIds, frequentlyLoading] = useFrequentExchangeIds(
    {
      marketCurrency,
      onUpdate: refresh
    }
  );
  const [marketCurrencies, marketCurrenciesLoading] = useMarketCurrencies();
  const [
    lastMarketCurrencies,
    lastMarketCurrenciesLoading
  ] = useLastMarketCurrencies({
    onUpdate: refresh
  });
  const [
    arbitrageTracking,
    arbitrageTrackingLoading,
    refetch
  ] = useArbitrageTracking();

  const data = {
    arbitrageTracking,
    exchanges,
    frequentlyUsedExchangeIds,
    marketCurrencies,
    lastMarketCurrencies
  };

  const loading =
    exchangesLoading ||
    frequentlyLoading ||
    marketCurrenciesLoading ||
    lastMarketCurrenciesLoading ||
    arbitrageTrackingLoading;

  function refresh() {
    setLoaded(false);
  }

  async function reload() {
    await refetch();
    refresh();
  }

  useEffect(() => {
    if (marketCurrency !== prevMarketCurrency) {
      refresh();
      setPrevMarketCurrency(marketCurrency);
    }
  }, [marketCurrency, prevMarketCurrency]);

  useEffect(() => {
    if (!loading && !loaded) {
      setViewModel(reshapeViewModel(viewModel, {}, data));
      setLoaded(true);
    }
  }, [loaded, loading, viewModel, data]);

  // Check if tracking has been changed remotely
  // I.e. from the scanner, another session or tab
  // In this case, refresh the data
  useEffect(() => {
    if (!loading) {
      const model = arbitrageTracking;
      if (model.isStarted === true && viewModel.isStarted === false) {
        refresh();
      } else if (model.isStarted === false && viewModel.isStarted === true) {
        refresh();
      } else if (model.isStarted === true && viewModel.isStarted === true) {
        if (model.marketCurrency !== viewModel.marketCurrency) {
          refresh();
        } else if (model.firstExchange.id !== viewModel.firstExchangeId) {
          refresh();
        } else if (model.secondExchange.id !== viewModel.secondExchangeId) {
          refresh();
        }
      }
    }
  }, [loading, arbitrageTracking, viewModel]);

  function updateViewModel(updateFragment = {}) {
    setViewModel(viewModel =>
      reshapeViewModel(viewModel, updateFragment, data)
    );
  }

  function handleMarketCurrencyChange(option) {
    const marketCurrency = option ? option.value : null;
    updateViewModel({
      marketCurrency,
      firstExchangeId: null,
      secondExchangeId: null
    });
  }

  function handleFirstExchangeChange(option) {
    const firstExchangeId = option ? option.value : null;
    updateViewModel({ firstExchangeId });
  }

  function handleSecondExchangeChange(option) {
    const secondExchangeId = option ? option.value : null;
    updateViewModel({ secondExchangeId });
  }

  function handleTrackButtonClick() {
    const { trackingState } = viewModel;
    if (trackingState === trackingStates.started) {
      stop();
    }
    if (trackingState === trackingStates.stopped) {
      start();
    }
    if (trackingState === trackingStates.onHold) {
      start();
    }
  }

  function stopTracking() {
    const { trackingState } = viewModel;
    if (trackingState === trackingStates.started) {
      stop();
    }
  }

  function handlePauseButtonClick() {
    pause();
  }

  async function start() {
    updateViewModel({ trackingState: trackingStates.starting });
    await startTrackingMutation({
      variables: {
        marketCurrency: viewModel.marketCurrency,
        firstExchangeId: viewModel.firstExchangeId,
        secondExchangeId: viewModel.secondExchangeId
      }
    });
    await reload();
  }

  async function stop() {
    updateViewModel({ trackingState: trackingStates.stopping });
    await stopTrackingMutation();
    await reload();
  }

  async function pause() {
    updateViewModel({ trackingState: trackingStates.stopping });
    await pauseTrackingMutation();
    await reload();
  }
  const marketCurrencyOptions = viewModel.marketCurrencyOptions;
  const lastMarketCurrencyOptions = viewModel.lastMarketCurrencyOptions;
  const exchangeOptions = viewModel.exchangeOptions;
  const frequentExchangeOptions = viewModel.frequentExchangeOptions;
  const firstExchangeId = viewModel.firstExchangeId;
  const secondExchangeId = viewModel.secondExchangeId;
  const trackingState = viewModel.trackingState;
  const firstExchange = arbitrageTracking.firstExchange;
  const secondExchange = arbitrageTracking.secondExchange;
  const isStarted = arbitrageTracking.isStarted;
  const isOnHold = arbitrageTracking.isOnHold;
  const arbitrage = arbitrageTracking.arbitrage;
  const timestampDelayAlertValue = arbitrageTracking.timestampDelayAlertValue;

  const canSelectMarketCurrency = !isStarted && loaded;
  const canSelectExchange = !isStarted && loaded && marketCurrency;
  const canStartTracking =
    marketCurrency && secondExchangeId && firstExchangeId;

  const firstExchangeInfo = useMemo(() => {
    const exchange = exchanges.find(({ id }) => id === firstExchangeId);
    return exchange || { name: 'First' };
  }, [firstExchangeId, exchanges]);

  const secondExchangeInfo = useMemo(() => {
    const exchange = exchanges.find(({ id }) => id === secondExchangeId);
    return exchange || { name: 'Second' };
  }, [secondExchangeId, exchanges]);

  return {
    marketCurrency,
    marketCurrencyOptions,
    lastMarketCurrencyOptions,
    exchangeOptions,
    frequentExchangeOptions,
    firstExchangeId,
    secondExchangeId,
    firstExchangeInfo,
    secondExchangeInfo,
    trackingState,
    firstExchange,
    secondExchange,
    arbitrage,
    isStarted,
    isOnHold,
    timestampDelayAlertValue,
    canStartTracking,
    canSelectMarketCurrency,
    canSelectExchange,
    handleMarketCurrencyChange,
    handleFirstExchangeChange,
    handleSecondExchangeChange,
    handlePauseButtonClick,
    handleTrackButtonClick,
    stopTracking
  };
}
