import React from 'react';
import styled from 'styled-components';
import AgeLabel from 'components/dashboard/AgeLabel';
import { Checkmark, Warning } from 'components/ui/icons';
import { useQuery } from '@apollo/react-hooks';
import { Box, Card, Flex } from 'components/ui/layout';
import { colors } from 'ui';

import getProxies from './graphql/getProxies.gql';

const Table = styled.table`
  font-feature-settings: 'tnum' 1, 'lnum' 1;
  border-collapse: collapse;
  width: 100%;

  td,
  th {
    height: 30px;

    &.v-center {
      display: flex;
      align-items: center;
    }

    &.center {
      text-align: center;
    }

    &.right {
      text-align: right;
    }
  }

  tfoot {
    td {
      padding-top: 25px;
    }
  }

  tbody {
    tr {
      transition: background-color 100ms ease-in;

      &:hover {
        background-color: ${colors.neutral10};
      }
    }
  }

  th {
    text-align: left;
    padding: 6px 8px;
    vertical-align: top;
    white-space: nowrap;
  }

  td {
    padding: 6px 8px;
  }
`;

function ProxiesTable({ proxyType = 'A', title = '', ...rest }) {
  const { data = {} } = useQuery(getProxies, {
    variables: { type: proxyType },
    pollInterval: 500,
    notifyOnNetworkStatusChange: true
  });
  const { proxies = [] } = data;
  return (
    <Box marginBottom="75px" {...rest}>
      <Box marginBottom="8px">{title}</Box>
      <Box inlineFlexBox flexDirection="column">
        <Flex fontSize="0.75em" marginBottom="5px">
          <Card minWidth="600px" flexBox>
            <Table>
              <thead>
                <tr>
                  <th>Proxy</th>
                  <th>IP</th>
                  <th className="right">Resp. time (ms)</th>
                  <th className="right">Requests</th>
                  <th colSpan={4} className="center">
                    Connections
                  </th>
                  <th colSpan={2}></th>
                </tr>
                <tr>
                  <th colSpan={4}></th>
                  <th className="right">Open</th>
                  <th className="right">Bad</th>
                  <th className="right">Denied</th>
                  <th className="right">H/L Refused</th>
                  <th colSpan={2}></th>
                </tr>
              </thead>
              <tbody>
                {proxies.map(proxy => (
                  <tr key={proxy.id}>
                    <td>{proxy.name}</td>
                    <td>{proxy.ipAddress}</td>
                    <td className="right">{proxy.responseTime}</td>
                    <td className="right">{proxy.requests}</td>
                    <td className="right">{proxy.openConnections}</td>
                    <td className="right">{proxy.badConnections}</td>
                    <td className="right">{proxy.deniedConnections}</td>
                    <td className="right">
                      {proxy.highLoadRefusedConnections}
                    </td>
                    <td className="right" style={{ width: '80px' }}>
                      <AgeLabel startTime={proxy.lastUpdated} />
                    </td>
                    <td className="v-center">
                      {proxy.hasError ? (
                        <Warning size={12} color={colors.red1} />
                      ) : (
                        <Checkmark size={12} color={colors.green} />
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card>
        </Flex>
      </Box>
    </Box>
  );
}

export default ProxiesTable;
