import React from 'react';
import styled from 'styled-components';
import { colors } from 'ui';

const Wrapper = styled.div`
  border-radius: ${props => props.height / 2}px;
  overflow: hidden;
  width: 100%;

  .background {
    display: block;
    background: ${props => props.backgroundColor};
  }
`;

const Progress = styled.span`
  display: block;
  background: ${props => props.activeColor};
  height: ${props => props.height}px;
  width: ${props => props.width || 0};
`;

const VolumeBar = ({
  value = 0,
  height = 2,
  activeColor = colors.neutral20,
  backgroundColor = colors.red2
}) => {
  const boundValue = Math.min(value, 1);
  const progressWidth = (boundValue * 100).toFixed(1) + '%';
  return (
    <Wrapper height={height} backgroundColor={backgroundColor}>
      <div className="background">
        <Progress
          width={progressWidth}
          activeColor={activeColor}
          height={height}
        />
      </div>
    </Wrapper>
  );
};

export default VolumeBar;
