import React from 'react';
import AgeLabel from './AgeLabel';
import { Box, Flex } from 'components/ui/layout';

const GridSection = ({
  header,
  children,
  startTime,
  useAgeLabelColor,
  ageLabelsPosition = 'bottom',
  marginBottom = '2px',
  ageLabelOrder
}) => {
  return (
    <Box>
      <Flex
        marginBottom="2px"
        justifyContent="space-between"
        alignItems="center"
      >
        {header}
        <div style={{ order: ageLabelOrder }}>
          {ageLabelsPosition === 'top' ? (
            <AgeLabel startTime={startTime} useColor={useAgeLabelColor} />
          ) : null}
        </div>
      </Flex>
      <Box inlineFlexBox flexDirection="column">
        <Flex fontSize="0.75em" marginBottom={marginBottom}>
          {children}
        </Flex>
        {ageLabelsPosition === 'bottom' ? (
          <Flex justifyContent="flex-end">
            <AgeLabel startTime={startTime} useColor={useAgeLabelColor} />
          </Flex>
        ) : null}
      </Box>
    </Box>
  );
};

export default GridSection;
