export const sortDirs = {
  none: null,
  asc: 'ASC',
  desc: 'DESC'
};

export const isolatedSortColumns = {
  none: null,
  market: 'BASE',
  quote: 'QUOTE',
  collateral: 'QUOTE_NET_ASSET',
  marginLevel: 'MARGIN_LEVEL',
  interest: 'INTEREST'
};

export const crossSortColumns = {
  none: null,
  market: 'BASE',
  interest: 'INTEREST'
};
