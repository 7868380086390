import React from 'react';
import { PrimaryButton } from 'components/ui/buttons';
import { colors } from 'ui';

export const saveStatuses = {
  init: 'INIT',
  unsaved: 'UNSAVED',
  progress: 'PROGRESS',
  success: 'SUCCESS',
  failed: 'FAILED'
};

function SaveButton({ onClick, status, ...props }) {
  function getName() {
    switch (status) {
      case saveStatuses.init:
      case saveStatuses.unsaved:
        return 'Save';
      case saveStatuses.progress:
        return '…';
      case saveStatuses.success:
        return 'Saved';
      case saveStatuses.failed:
        return 'Failed';
      default:
        return '';
    }
  }

  function getDisabled() {
    return status !== saveStatuses.unsaved;
  }

  function getBackgroundColor() {
    if (status === saveStatuses.failed) {
      return colors.red2;
    }
  }

  return (
    <PrimaryButton
      style={{ backgroundColor: getBackgroundColor() }}
      disabled={getDisabled()}
      onClick={onClick}
      {...props}
    >
      {getName()}
    </PrimaryButton>
  );
}

export default SaveButton;
