import React from 'react';
import ReactSelect from 'react-select';
import func from 'utils/func';
import { colors } from 'ui';

export const themeSettings = {
  baseUnit: 3,
  controlHeight: 36
};

export const selectStyles = {
  clearIndicator: func.id,
  container: func.id,
  control: styles => ({ ...styles, boxShadow: null }),
  dropdownIndicator: func.id,
  group: func.id,
  groupHeading: func.id,
  indicatorsContainer: func.id,
  indicatorSeparator: func.id,
  input: styles => ({ ...styles, color: colors.bodyText }),
  loadingIndicator: func.id,
  loadingMessage: func.id,
  menu: styles => ({ ...styles, zIndex: 2 }),
  menuList: func.id,
  menuPortal: func.id,
  multiValue: func.id,
  multiValueLabel: func.id,
  multiValueRemove: func.id,
  noOptionsMessage: func.id,
  option: styles => {
    const verticalPadding = themeSettings.baseUnit * 2;
    const horizontalPadding = themeSettings.baseUnit * 4;
    return {
      ...styles,
      padding: `${verticalPadding}px ${horizontalPadding}px`
    };
  },
  placeholder: func.id,
  singleValue: func.id,
  valueContainer: styles => {
    const verticalPadding = themeSettings.baseUnit / 2;
    const horizontalPadding = themeSettings.baseUnit * 3.5;
    return {
      ...styles,
      padding: `${verticalPadding}px ${horizontalPadding}px`
    };
  }
};

export const theme = provided => ({
  ...provided,
  borderRadius: 0,
  spacing: {
    ...provided.spacing,
    baseUnit: themeSettings.baseUnit,
    controlHeight: themeSettings.controlHeight
  },
  colors: {
    ...provided.colors,
    neutral0: colors.neutral0,
    neutral5: colors.neutral5,
    neutral10: colors.neutral10,
    neutral20: colors.neutral20,
    neutral30: colors.neutral30,
    neutral40: colors.neutral40,
    neutral50: colors.neutral50,
    neutral60: colors.neutral60,
    neutral70: colors.neutral70,
    neutral80: colors.neutral80,
    neutral90: colors.neutral90,
    primary: colors.primary,
    primary25: colors.primary25,
    primary50: colors.primary50,
    primary75: colors.primary75
  }
});

const Select = props => {
  return <ReactSelect styles={selectStyles} theme={theme} {...props} />;
};

export default Select;
