export const sortDirs = {
  none: null,
  asc: 'ASC',
  desc: 'DESC'
};

export const sortColumns = {
  none: null,
  market: 'MARKET',
  margin: 'MARGIN',
  other: 'OTHER',
  actualToTotalDiff: 'ACTUAL_TO_TOTAL_DIFF',
  actualToSavedDiff: 'ACTUAL_TO_SAVED_DIFF',
  totalBtc: 'TOTAL_BTC',
  actualBtc: 'ACTUAL_BTC'
};
