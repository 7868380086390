import delay from 'delay';
import { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { saveStatuses } from './SaveButton';
import { useInterval } from 'components/useInterval';

import getScannerGql from './graphql/getScanner.gql';
import updateScannerGql from './graphql/updateScanner.gql';

const savedDelay = 500;

export function useScannerPage() {
  const [isEditing, setEditing] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [saveStatus, setSaveStatus] = useState(saveStatuses.init);
  const [activeMarkets, setActiveMarkets] = useState([]);
  const [maintenanceMarkets, setMaintenanceMarkets] = useState([]);
  const [multichainMarkets, setMultichainMarkets] = useState([]);
  const [specialMarkets, setSpecialMarkets] = useState([]);
  const [inactiveMarkets, setInactiveMarkets] = useState([]);
  const [platforms, setPlatforms] = useState([]);
  const [updateMarketsMutation] = useMutation(updateScannerGql);

  const { data = {}, loading, refetch } = useQuery(getScannerGql, {
    fetchPolicy: 'network-only'
  });

  // Poll and reload if not in edit mode:
  useInterval(async () => {
    if (!isEditing) {
      await reload(true);
    }
  }, 1000);

  useEffect(() => {
    if (!loaded && !loading) {
      const { scanner: { markets = {}, platforms = [] } = {} } = data;
      setActiveMarkets(markets.active);
      setMaintenanceMarkets(markets.maintenance);
      setMultichainMarkets(markets.multichain);
      setSpecialMarkets(markets.special);
      setInactiveMarkets(markets.inactive);
      setPlatforms(platforms);
      setLoaded(true);
    }
  }, [loaded, loading, data]);

  async function reload(shouldRefetch) {
    if (shouldRefetch) {
      await refetch();
    }
    setLoaded(false);
  }

  function unshape() {
    const isPending = ({ isPending }) => isPending;
    const unshapeMarket = ({ symbol, scanCategory }) => ({
      symbol,
      scanCategory
    });
    const unshapePlatform = ({ id, scanCategory }) => ({
      id,
      scanCategory
    });
    return {
      platforms: platforms.filter(isPending).map(unshapePlatform),
      markets: [
        ...activeMarkets.filter(isPending),
        ...multichainMarkets.filter(isPending),
        ...specialMarkets.filter(isPending),
        ...maintenanceMarkets.filter(isPending),
        ...inactiveMarkets.filter(isPending)
      ].map(unshapeMarket)
    };
  }

  function setUnsaved() {
    setSaveStatus(saveStatuses.unsaved);
  }

  function startEditing() {
    setEditing(true);
  }

  function cancelEditing() {
    setEditing(false);
    reload();
  }

  function sortMarkets(markets) {
    return markets.slice().sort((a, b) => a.symbol.localeCompare(b.symbol));
  }

  function togglePlatform(platformId) {
    const index = platforms.findIndex(({ id }) => id === platformId);
    const platform = platforms[index];
    const updatedPlatform = {
      ...platform,
      scanCategory: platform.scanCategory === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE',
      isPending: true
    };
    const updatedPlatforms = [
      ...platforms.slice(0, index),
      updatedPlatform,
      ...platforms.slice(index + 1)
    ];
    setPlatforms(updatedPlatforms);
    setUnsaved();
  }

  function moveMarket(symbol, { fromState, toState, toScanCategory }) {
    const [fromMarkets, setFromMarkets] = fromState;
    const [toMarkets, setToMarkets] = toState;

    const index = fromMarkets.findIndex(market => market.symbol === symbol);
    const market = fromMarkets[index];
    const updatedMarket = {
      ...market,
      isPending: true,
      scanCategory: toScanCategory
    };
    const updatedFrom = [
      ...fromMarkets.slice(0, index),
      ...fromMarkets.slice(index + 1)
    ];
    const updatedTo = sortMarkets([updatedMarket, ...toMarkets]);
    setFromMarkets(updatedFrom);
    setToMarkets(updatedTo);
    setUnsaved();
  }

  function moveActiveMarket(symbol) {
    moveMarket(symbol, {
      fromState: [activeMarkets, setActiveMarkets],
      toState: [multichainMarkets, setMultichainMarkets],
      toScanCategory: 'ACTIVE_MULTICHAIN'
    });
  }

  function moveMultichainMarket(symbol) {
    moveMarket(symbol, {
      fromState: [multichainMarkets, setMultichainMarkets],
      toState: [specialMarkets, setSpecialMarkets],
      toScanCategory: 'ACTIVE_SPECIAL'
    });
  }

  function moveSpecialMarket(symbol) {
    moveMarket(symbol, {
      fromState: [specialMarkets, setSpecialMarkets],
      toState: [maintenanceMarkets, setMaintenanceMarkets],
      toScanCategory: 'ACTIVE_MAINTENANCE'
    });
  }

  function moveMaintenanceMarket(symbol) {
    moveMarket(symbol, {
      fromState: [maintenanceMarkets, setMaintenanceMarkets],
      toState: [inactiveMarkets, setInactiveMarkets],
      toScanCategory: 'INACTIVE'
    });
  }

  function moveInactiveMarket(symbol) {
    moveMarket(symbol, {
      fromState: [inactiveMarkets, setInactiveMarkets],
      toState: [activeMarkets, setActiveMarkets],
      toScanCategory: 'ACTIVE'
    });
  }

  async function save() {
    setSaveStatus(saveStatuses.progress);
    try {
      const { data = {} } = await updateMarketsMutation({
        variables: unshape()
      });
      const { updateScanner } = data;
      if (updateScanner) {
        setSaveStatus(saveStatuses.success);
        await delay(savedDelay);
        await reload(true);
        setEditing(false);
      } else {
        throw new Error('The updateScannerMarkets mutation returned false.');
      }
    } catch (error) {
      setSaveStatus(saveStatuses.failed);
      console.log('Failed to save scanner markets', error);
    }
  }

  return {
    loading,
    isEditing,
    saveStatus,
    platforms,
    activeMarkets,
    maintenanceMarkets,
    multichainMarkets,
    inactiveMarkets,
    specialMarkets,
    startEditing,
    cancelEditing,
    save,
    togglePlatform,
    moveActiveMarket,
    moveMultichainMarket,
    moveMaintenanceMarket,
    moveSpecialMarket,
    moveInactiveMarket
  };
}
