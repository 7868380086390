import React from 'react';
import SettingsPageHeader from './SettingsPageHeader';

const SettingsPage = ({ children }) => (
  <>
    <SettingsPageHeader />
    {children}
  </>
);

export default SettingsPage;
