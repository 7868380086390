import React from 'react';
import ReactDOM from 'react-dom';

const modalRoot = document.getElementById('modal-root');

class Modal extends React.Component {
  constructor(props) {
    super(props);
    this.container = document.createElement('div');
  }
  componentDidMount() {
    modalRoot.appendChild(this.container);
  }
  componentWillUnmount() {
    modalRoot.removeChild(this.container);
  }
  render() {
    return ReactDOM.createPortal(this.props.children, this.container);
  }
}

export default Modal;
