import React from 'react';
import classes from 'classnames';
import SaveButton from './SaveButton';
import { Link } from 'react-router-dom';
import { Wrapper } from './scannerStyles';
import { PrimaryButton, OutlineButton } from 'components/ui/buttons';
import { useScannerPage } from './useScannerPage';
import { useStoredSwitch } from './useStoredSwitch';

const primaryButtonWidth = '76px';

function MarketsSection({ marketsList, title, isEditing, onEditMarketClick }) {
  const sectionSwitch = useStoredSwitch(`scanner_${title}_collapsed`);
  const isCollapsed = sectionSwitch.isOn;
  return (
    <section>
      <h3 onClick={sectionSwitch.toggle}>
        {title} {isCollapsed ? '+' : ''}
      </h3>
      {isCollapsed ? null : (
        <ul>
          {marketsList.map(market => {
            const symbol = market.symbol;
            const scanCategory = market.scanCategory;
            const spreadColor = market.bestSpreadColor;
            const mergedSpreadColor = market.bestMergedSpreadColor;
            const isPending = market.isPending;
            const isSpecial =
              scanCategory === 'ACTIVE_MULTICHAIN' ||
              scanCategory === 'ACTIVE_SPECIAL';
            const color = spreadColor;
            const borderColor = isSpecial ? undefined : color;
            const borderBottomColor = isSpecial ? mergedSpreadColor : color;
            return (
              <li key={symbol}>
                {isEditing ? (
                  <span
                    className={classes('market', {
                      pending: isPending
                    })}
                    onClick={() => onEditMarketClick(symbol)}
                  >
                    {symbol}
                  </span>
                ) : (
                  <Link
                    to={`/scanner/${symbol}`}
                    style={{
                      color,
                      borderColor,
                      borderBottomColor
                    }}
                    onClick={() => {}}
                  >
                    {symbol}
                  </Link>
                )}
              </li>
            );
          })}
        </ul>
      )}
    </section>
  );
}

function PlatformsSection({ platforms, isEditing, onTogglePlatform }) {
  const sectionSwitch = useStoredSwitch(`scanner_platforms_collapsed`);
  const isCollapsed = sectionSwitch.isOn;

  function handleClick(platformId) {
    if (isEditing) {
      onTogglePlatform(platformId);
    }
  }

  return (
    <section>
      <h3 onClick={sectionSwitch.toggle}>platforms {isCollapsed ? '+' : ''}</h3>
      {isCollapsed ? null : (
        <ul>
          {platforms.map(({ id, name, scanCategory }) => (
            <li key={id}>
              <span
                onClick={() => handleClick(id)}
                className={classes('platform', {
                  active: scanCategory === 'ACTIVE',
                  edit: isEditing
                })}
              >
                {name}
              </span>
            </li>
          ))}
        </ul>
      )}
    </section>
  );
}

function ScannerPage() {
  const {
    loading,
    isEditing,
    saveStatus,
    platforms,
    activeMarkets,
    maintenanceMarkets,
    multichainMarkets,
    specialMarkets,
    inactiveMarkets,
    startEditing,
    cancelEditing,
    save,
    togglePlatform,
    moveActiveMarket,
    moveMultichainMarket,
    moveMaintenanceMarket,
    moveSpecialMarket,
    moveInactiveMarket
  } = useScannerPage();

  return (
    <Wrapper>
      <header>
        <nav>
          <ul>
            <li>Scanner</li>
          </ul>
        </nav>
        <div className="buttons">
          {isEditing ? (
            <>
              <OutlineButton onClick={cancelEditing} marginRight="10px">
                Cancel
              </OutlineButton>
              <SaveButton
                width={primaryButtonWidth}
                onClick={save}
                status={saveStatus}
              />
            </>
          ) : (
            <PrimaryButton width={primaryButtonWidth} onClick={startEditing}>
              Edit
            </PrimaryButton>
          )}
        </div>
      </header>
      <main>
        {loading ? (
          'loading...'
        ) : (
          <div>
            <MarketsSection
              title="normal"
              marketsList={activeMarkets}
              isEditing={isEditing}
              onEditMarketClick={moveActiveMarket}
            />
            <MarketsSection
              title="multichain"
              marketsList={multichainMarkets}
              isEditing={isEditing}
              onEditMarketClick={moveMultichainMarket}
            />
            <MarketsSection
              title="special"
              marketsList={specialMarkets}
              isEditing={isEditing}
              onEditMarketClick={moveSpecialMarket}
            />
            <MarketsSection
              title="maintenance"
              marketsList={maintenanceMarkets}
              isEditing={isEditing}
              onEditMarketClick={moveMaintenanceMarket}
            />
            <PlatformsSection
              platforms={platforms}
              isEditing={isEditing}
              onTogglePlatform={togglePlatform}
            />
            <MarketsSection
              title="inactive"
              marketsList={inactiveMarkets}
              isEditing={isEditing}
              onEditMarketClick={moveInactiveMarket}
            />
          </div>
        )}
      </main>
    </Wrapper>
  );
}

export default ScannerPage;
