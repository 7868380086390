import React from 'react';
import styled from 'styled-components';
import { colors } from 'ui';
import { formatAmount, getAmountPrecision } from 'utils/format';
import { copyToClipboard } from 'utils/clipboard';
import { Flex, Box } from 'components/ui/layout';

const padding = '5px';

const Table = styled.table`
  font-feature-settings: 'tnum' 1, 'lnum' 1;
  border-collapse: collapse;
`;

const TableRow = styled.tr``;

const TableHeader = styled.th`
  font-size: ${props => (props.bigger ? '1.25em' : undefined)};
  text-align: ${props => props.textAlign || 'center'};
  padding: 4px 4px;
  min-width: ${props => props.minWidth || undefined};
  color: ${props => props.color};
  white-space: nowrap;

  &.no-v-padding {
    padding-top: 0px;
    padding-bottom: 0px;
  }
`;

const TableCell = styled.td`
  font-size: ${props => (props.bigger ? '1.25em' : undefined)};
  text-align: ${props => props.textAlign || 'center'};
  padding: 4px 4px;
  min-width: ${props => props.minWidth || undefined};

  &.v-top {
    vertical-align: top;
  }

  .clip {
  }
`;

const EmptyCell = styled.td`
  height: 10px;
`;

const CurrencyWrapper = styled.div`
  display: inline-block;
  transition: opacity 150ms ease;

  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
`;

const getColorByValue = value => {
  if (value > 0) return colors.green;
  if (value < 0) return colors.red1;
};

const Amount = ({ children, color, amountPrecision }) => {
  const value = children;
  if (Number.isFinite(value)) {
    return Math.abs(value) < 1e-9 ? (
      '0'
    ) : (
      <Box color={color || getColorByValue(value)}>
        {formatAmount(value, amountPrecision)}
      </Box>
    );
  }
  return 'N/A';
};

const getAlt = (resultBalances, entryBalances) => {
  return (
    resultBalances.marketCurrencyBalance - entryBalances.marketCurrencyBalance
  );
};

const getBtc = (resultBalances, entryBalances) => {
  return resultBalances.bitcoinBalance - entryBalances.bitcoinBalance;
};

const getFiat = (resultBalances, entryBalances) => {
  return resultBalances.baseCurrencyBalance - entryBalances.baseCurrencyBalance;
};

class ResultsTable extends React.Component {
  render() {
    const {
      markets,
      marketCurrency,
      firstName,
      firstBaseCurrency,
      firstIsFiat,
      secondName,
      secondBaseCurrency,
      secondIsFiat
    } = this.props;
    const firstEntryBalances = this.props.firstEntryBalances || {};
    const firstResultBalances = this.props.firstResultBalances || {};
    const secondEntryBalances = this.props.secondEntryBalances || {};
    const secondResultBalances = this.props.secondResultBalances || {};
    const firstAlt = getAlt(firstResultBalances, firstEntryBalances);
    const firstBtc = getBtc(firstResultBalances, firstEntryBalances);
    const firstFiatProfit = getFiat(firstResultBalances, firstEntryBalances);
    const secondAlt = getAlt(secondResultBalances, secondEntryBalances);
    const secondBtc = getBtc(secondResultBalances, secondEntryBalances);
    const secondFiatProfit = getFiat(secondResultBalances, secondEntryBalances);
    const altProfit = firstAlt + secondAlt;
    const btcProfit = firstBtc + secondBtc;

    const marketCurrencyPrecision = getAmountPrecision(markets[marketCurrency]);
    const btcPrecision = getAmountPrecision(markets.BTC);
    const firstBasePrecision = getAmountPrecision(markets[firstBaseCurrency]);
    const secondBasePrecision = getAmountPrecision(markets[secondBaseCurrency]);

    return (
      <Flex
        justifyContent="center"
        height="calc(100% - 72px)"
        padding="5px 10px"
      >
        <Box paddingRight="5px" borderRight>
          <Table>
            <tbody>
              <TableRow>
                <TableHeader bigger className="no-v-padding">
                  &nbsp;
                </TableHeader>
              </TableRow>
              <TableRow>
                <TableHeader bigger>&nbsp;</TableHeader>
              </TableRow>
              <TableRow>
                <EmptyCell />
              </TableRow>
              <TableRow>
                <TableHeader textAlign="right">Trade</TableHeader>
              </TableRow>
              <TableRow>
                <TableHeader textAlign="right">Before</TableHeader>
              </TableRow>
              <TableRow>
                <TableHeader textAlign="right">After</TableHeader>
              </TableRow>
            </tbody>
          </Table>
        </Box>
        <Box paddingRight={padding} paddingLeft={padding} borderRight>
          <Table>
            <tbody>
              <TableRow>
                <TableHeader colSpan={2} bigger className="no-v-padding">
                  <CurrencyWrapper
                    onClick={() => copyToClipboard(marketCurrency)}
                  >
                    {marketCurrency}
                  </CurrencyWrapper>
                </TableHeader>
              </TableRow>
              <TableRow>
                <TableCell colSpan={2} bigger>
                  <Amount amountPrecision={marketCurrencyPrecision}>
                    {altProfit}
                  </Amount>
                </TableCell>
              </TableRow>
              <TableRow>
                <EmptyCell />
              </TableRow>
              <TableRow>
                <TableCell>
                  <Amount amountPrecision={marketCurrencyPrecision}>
                    {firstAlt}
                  </Amount>
                </TableCell>
                <TableCell>
                  <Amount amountPrecision={marketCurrencyPrecision}>
                    {secondAlt}
                  </Amount>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Amount
                    amountPrecision={marketCurrencyPrecision}
                    color="inherit"
                  >
                    {firstEntryBalances.marketCurrencyBalance}
                  </Amount>
                </TableCell>
                <TableCell>
                  <Amount
                    amountPrecision={marketCurrencyPrecision}
                    color="inherit"
                  >
                    {secondEntryBalances.marketCurrencyBalance}
                  </Amount>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Amount
                    amountPrecision={marketCurrencyPrecision}
                    color="inherit"
                  >
                    {firstResultBalances.marketCurrencyBalance}
                  </Amount>
                </TableCell>
                <TableCell>
                  <Amount
                    amountPrecision={marketCurrencyPrecision}
                    color="inherit"
                  >
                    {secondResultBalances.marketCurrencyBalance}
                  </Amount>
                </TableCell>
              </TableRow>
              <TableRow>
                <EmptyCell />
              </TableRow>
              <TableRow>
                <TableCell className="v-top">
                  <div className="clip">{firstName}</div>
                </TableCell>
                <TableCell className="v-top">
                  <div className="clip">{secondName}</div>
                </TableCell>
              </TableRow>
            </tbody>
          </Table>
        </Box>
        <Box
          paddingLeft={padding}
          paddingRight={firstIsFiat || secondIsFiat ? padding : undefined}
          borderRight={firstIsFiat || secondIsFiat}
        >
          <Table>
            <tbody>
              <TableRow>
                <TableHeader colSpan={2} bigger className="no-v-padding">
                  BTC
                </TableHeader>
              </TableRow>
              <TableRow>
                <TableCell colSpan={2} bigger>
                  <Amount amountPrecision={btcPrecision}>{btcProfit}</Amount>
                </TableCell>
              </TableRow>
              <TableRow>
                <EmptyCell />
              </TableRow>
              <TableRow>
                <TableCell>
                  <Amount amountPrecision={btcPrecision}>{firstBtc}</Amount>
                </TableCell>
                <TableCell>
                  <Amount amountPrecision={btcPrecision}>{secondBtc}</Amount>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Amount amountPrecision={btcPrecision} color="inherit">
                    {firstEntryBalances.bitcoinBalance}
                  </Amount>
                </TableCell>
                <TableCell>
                  <Amount amountPrecision={btcPrecision} color="inherit">
                    {secondEntryBalances.bitcoinBalance}
                  </Amount>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Amount amountPrecision={btcPrecision} color="inherit">
                    {firstResultBalances.bitcoinBalance}
                  </Amount>
                </TableCell>
                <TableCell>
                  <Amount amountPrecision={btcPrecision} color="inherit">
                    {secondResultBalances.bitcoinBalance}
                  </Amount>
                </TableCell>
              </TableRow>
              <TableRow>
                <EmptyCell />
              </TableRow>
              <TableRow>
                <TableCell className="v-top">
                  <div className="clip">{firstName}</div>
                </TableCell>
                <TableCell className="v-top">
                  <div className="clip">{secondName}</div>
                </TableCell>
              </TableRow>
            </tbody>
          </Table>
        </Box>
        {firstIsFiat ? (
          <Box
            paddingLeft={padding}
            paddingRight={secondIsFiat ? padding : undefined}
            borderRight={secondIsFiat}
          >
            <Table>
              <tbody>
                <TableRow>
                  <TableHeader
                    textAlign="center"
                    bigger
                    className="no-v-padding"
                  >
                    {firstBaseCurrency}
                  </TableHeader>
                </TableRow>
                <TableRow>
                  <TableCell textAlign="center" bigger>
                    <Amount amountPrecision={firstBasePrecision}>
                      {firstFiatProfit}
                    </Amount>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <EmptyCell />
                </TableRow>
                <TableRow>
                  <TableCell>&nbsp;</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell textAlign="center">
                    <Amount
                      color="inherit"
                      amountPrecision={firstBasePrecision}
                    >
                      {firstEntryBalances.baseCurrencyBalance}
                    </Amount>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell textAlign="center">
                    <Amount
                      color="inherit"
                      amountPrecision={firstBasePrecision}
                    >
                      {firstResultBalances.baseCurrencyBalance}
                    </Amount>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <EmptyCell />
                </TableRow>
                <TableRow>
                  <TableCell textAlign="center" className="v-top">
                    <div className="clip">{firstName}</div>
                  </TableCell>
                </TableRow>
              </tbody>
            </Table>
          </Box>
        ) : null}
        {secondIsFiat ? (
          <Box paddingLeft={padding}>
            <Table>
              <tbody>
                <TableRow>
                  <TableHeader
                    textAlign="center"
                    bigger
                    className="no-v-padding"
                  >
                    {secondBaseCurrency}
                  </TableHeader>
                </TableRow>
                <TableRow>
                  <TableCell textAlign="center" bigger>
                    <Amount amountPrecision={secondBasePrecision}>
                      {secondFiatProfit}
                    </Amount>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <EmptyCell />
                </TableRow>
                <TableRow>
                  <TableCell>&nbsp;</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell textAlign="center">
                    <Amount
                      color="inherit"
                      amountPrecision={secondBasePrecision}
                    >
                      {secondEntryBalances.baseCurrencyBalance}
                    </Amount>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell textAlign="center">
                    <Amount
                      color="inherit"
                      amountPrecision={secondBasePrecision}
                    >
                      {secondResultBalances.baseCurrencyBalance}
                    </Amount>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <EmptyCell />
                </TableRow>
                <TableRow>
                  <TableCell textAlign="center" className="v-top">
                    <div className="clip">{secondName}</div>
                  </TableCell>
                </TableRow>
              </tbody>
            </Table>
          </Box>
        ) : null}
      </Flex>
    );
  }
}

export default ResultsTable;
