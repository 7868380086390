import React from 'react';
import DashboardToolbar from './DashboardToolbar';
import DashboardGrid from './DashboardGrid';
import { Container } from 'components/ui/layout';
import { useDashboard } from './useDashboard';

function Dashboard() {
  const {
    marketCurrency,
    marketCurrencyOptions,
    lastMarketCurrencyOptions,
    exchangeOptions,
    frequentExchangeOptions,
    firstExchangeId,
    secondExchangeId,
    firstExchangeInfo,
    secondExchangeInfo,
    firstExchange,
    secondExchange,
    arbitrage,
    isOnHold,
    timestampDelayAlertValue,
    trackingState,
    canStartTracking,
    canSelectMarketCurrency,
    canSelectExchange,
    handleMarketCurrencyChange,
    handleFirstExchangeChange,
    handleSecondExchangeChange,
    handlePauseButtonClick,
    handleTrackButtonClick,
    stopTracking
  } = useDashboard();

  return (
    <>
      <DashboardToolbar
        marketCurrency={marketCurrency}
        marketCurrencyOptions={marketCurrencyOptions}
        lastMarketCurrencyOptions={lastMarketCurrencyOptions}
        exchangeOptions={exchangeOptions}
        frequentExchangeOptions={frequentExchangeOptions}
        firstExchangeId={firstExchangeId}
        secondExchangeId={secondExchangeId}
        trackingState={trackingState}
        canStartTracking={canStartTracking}
        canSelectMarketCurrency={canSelectMarketCurrency}
        canSelectExchange={canSelectExchange}
        handleMarketCurrencyChange={handleMarketCurrencyChange}
        handleFirstExchangeChange={handleFirstExchangeChange}
        handleSecondExchangeChange={handleSecondExchangeChange}
        handlePauseButtonClick={handlePauseButtonClick}
        handleTrackButtonClick={handleTrackButtonClick}
      />
      <Container>
        <DashboardGrid
          marketCurrency={marketCurrency}
          marketCurrencyOptions={marketCurrencyOptions}
          firstExchangeInfo={firstExchangeInfo}
          secondExchangeInfo={secondExchangeInfo}
          firstExchange={firstExchange}
          secondExchange={secondExchange}
          arbitrage={arbitrage}
          isOnHold={isOnHold}
          timestampDelayAlertValue={timestampDelayAlertValue}
          selectMarketCurrency={handleMarketCurrencyChange}
          stopTracking={stopTracking}
          trackingState={trackingState}
        />
      </Container>
    </>
  );
}

export default Dashboard;
