import vendorParseDecimalNumber from 'parse-decimal-number';

// A simple way to determing the browser’s locale
// decimal separator by formatting a number with `toLocaleString`.
const getDecimalSeparator = () => {
  const testNumber = 1.1;
  const [, separator] = /^1(.+)1$/.exec(testNumber.toLocaleString());
  return separator;
};

export const parseDecimal = (value = '') => {
  if (typeof value === 'string') {
    const decimal = getDecimalSeparator();
    const thousands = decimal === '.' ? ',' : '.';
    const parsedValue = vendorParseDecimalNumber(value, { thousands, decimal });
    if (Number.isFinite(parsedValue)) {
      return parsedValue;
    }
  }
  if (typeof value === 'number') {
    return value;
  }
  return null;
};
