import React from 'react';
import NavTabs from 'components/navigation/NavTabs';
import styled from 'styled-components';
import routes from 'routes';
import { colors } from 'ui';

const Wrapper = styled.div`
  padding-top: 15px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-color: ${colors.backgroundLighter};
`;

const HealthPageHeader = () => (
  <Wrapper>
    <NavTabs height="52px" routes={[routes.health.proxies]} />
  </Wrapper>
);

export default HealthPageHeader;
