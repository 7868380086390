import { css } from 'styled-components';

export const positionProps = ({
  position,
  top,
  right,
  bottom,
  left,
  zIndex
}) => css`
  position: ${position};
  top: ${top};
  right: ${right};
  bottom: ${bottom};
  left: ${left};
  z-index: ${zIndex};
`;
