import React from 'react';
import styled from 'styled-components';
import classes from 'classnames';
import AgeLabel from 'components/dashboard/AgeLabel';
import Switch from 'components/ui/Switch';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { colors } from 'ui';
import { Container } from 'components/ui/layout';
import { Box, Card, Flex } from 'components/ui/layout';

import getStakingGql from './graphql/getStaking.gql';
import updateStakingAlertGql from './graphql/updateStakingAlert.gql';

const Wrapper = styled(Container)`
  padding-top: 25px;
  display: flex;
  flex-wrap: wrap;

  .sorting-header {
    cursor: pointer;
    display: inline-block;
    transition: color 200ms ease;

    &:hover {
      color: ${colors.neutral90};
    }
  }

  .green {
    color: ${colors.green};
  }

  .active {
    color: ${colors.primary};
  }

  .secondary {
    color: ${colors.neutral20};
  }

  .red {
    color: ${colors.red1};
  }

  table {
    font-feature-settings: 'tnum' 1, 'lnum' 1;
    border-collapse: collapse;
    width: 100%;

    td,
    th {
      height: 30px;

      &.v-center {
        display: flex;
        align-items: center;
      }

      &.center {
        text-align: center;
      }

      &.right {
        text-align: right;
      }
    }

    tfoot {
      td {
        padding-top: 25px;
      }
    }

    tbody {
      tr {
        transition: background-color 100ms ease-in;

        &:hover {
          background-color: ${colors.neutral10};
        }
      }
    }

    th {
      text-align: left;
      padding: 6px 8px;
      vertical-align: top;
      white-space: nowrap;
    }

    td {
      white-space: nowrap;
      padding: 6px 8px;
    }
  }
`;

function formatApy(value) {
  if (Number.isFinite(value)) {
    return value.toLocaleString('en', {
      style: 'percent',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
  } else {
    return '';
  }
}

function StakingPage() {
  const [updateAlertMutation] = useMutation(updateStakingAlertGql);

  const { data = {}, refetch } = useQuery(getStakingGql, {
    fetchPolicy: 'network-only',
    pollInterval: 2500,
    notifyOnNetworkStatusChange: true
  });

  const { staking: { time, items = [] } = {} } = data;

  async function updateStakingAlert(item) {
    const input = {
      asset: item.asset,
      duration: item.duration,
      isOn: !item.isOn
    };
    try {
      await updateAlertMutation({ variables: { input } });
      await refetch();
    } catch (error) {
      console.log('Failed to update staking alert', error);
    }
  }

  return (
    <Wrapper>
      <Box marginBottom="75px" marginRight="35px">
        <Box marginBottom="8px">
          <Flex justifyContent="space-between">
            <div>Binance Assets</div>
            <AgeLabel startTime={time} />
          </Flex>
        </Box>
        <Box inlineFlexBox flexDirection="column">
          <Flex fontSize="0.75em" marginBottom="5px">
            <Card minWidth="500px" flexBox>
              <table>
                <thead>
                  <tr>
                    <th>Asset</th>
                    <th className="right">Duration</th>
                    <th style={{ width: '100%' }}></th>
                    <th>Status</th>
                    <th className="right"></th>
                    <th style={{ width: '100%' }}></th>
                    <th className="center">Alert</th>
                  </tr>
                </thead>
                <tbody>
                  {items.map(item => {
                    const { asset, duration, apy, isOn } = item;
                    const key = `${asset}:${duration}`;
                    const isAvailable = apy !== null;
                    const colorClassName = isOn ? undefined : 'secondary';
                    return (
                      <tr key={key}>
                        <td className={colorClassName}>{asset}</td>
                        <td className={classes('right', colorClassName)}>
                          {duration}
                        </td>
                        <td></td>
                        <td className={colorClassName}>
                          {isAvailable ? 'Staking' : 'Sold out'}
                        </td>
                        <td className={classes('right', colorClassName)}>
                          {formatApy(apy)}
                        </td>
                        <td></td>
                        <td className="center">
                          <Switch
                            tiny
                            isOn={isOn}
                            onChange={() => updateStakingAlert(item)}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </Card>
          </Flex>
        </Box>
      </Box>
    </Wrapper>
  );
}

export default StakingPage;
