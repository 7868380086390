import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const Aligner = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Content = styled.div`
  text-align: center;
`;

const Heading = styled.h1`
  margin: 0;
`;

const Text = styled.p`
  font-size: 1.1em;
`;

const NotFoundPage = () => (
  <Wrapper>
    <Aligner>
      <Content>
        <Heading>Not Found</Heading>
        <Text>This page doesn’t exist or has been removed.</Text>
      </Content>
    </Aligner>
  </Wrapper>
);

export default NotFoundPage;
