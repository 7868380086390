import React from 'react';
import NotFoundPage from 'pages/NotFoundPage';
import Layout from 'pages/Layout';
import routeConfig from './routeConfig';
import { Switch, Route } from 'react-router-dom';

const AppPagesSwitch = () => (
  <Switch>
    {routeConfig.map(
      ({ route: { path, exact } = {}, component: Component }) => (
        <Route
          key={path}
          path={path}
          exact={exact}
          render={props => (
            <Layout>
              <Component {...props} />
            </Layout>
          )}
        />
      )
    )}
    <Route component={NotFoundPage} />
  </Switch>
);

export default AppPagesSwitch;
