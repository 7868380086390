import React from 'react';
import HealthPageHeader from './HealthPageHeader';

const HealthPage = ({ children }) => (
  <>
    <HealthPageHeader />
    {children}
  </>
);

export default HealthPage;
