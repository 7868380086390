import { useState } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useTotalBalancesSorting } from './useTotalBalancesSorting';

import getTotalBalances from './graphql/getTotalBalances.gql';
import syncActualTotalBalances from './graphql/syncActualTotalBalances.gql';
import syncMarginTotalBalances from './graphql/syncMarginTotalBalances.gql';
import saveTotalBalances from './graphql/saveTotalBalances.gql';
import resetTotalBalances from './graphql/resetTotalBalances.gql';

export function useTotalBalances() {
  const [isResetConfirmationVisible, setResetConfirmationVisible] = useState(
    false
  );

  const { sortBy, sortDir, toggleSort, sort } = useTotalBalancesSorting();

  const { data = {}, refetch } = useQuery(getTotalBalances, {
    fetchPolicy: 'network-only',
    pollInterval: 5000,
    notifyOnNetworkStatusChange: true
  });

  const { totalBalances = {} } = data;
  const {
    btcAmountPrecision,
    totalActualAmountSyncTime,
    totalMarginAmountSyncTime,
    totalSavedAmountSyncTime,
    items = []
  } = totalBalances;

  const [syncActualMutation, syncActualResult] = useMutation(
    syncActualTotalBalances
  );
  const [syncMarginMutation, syncMarginResult] = useMutation(
    syncMarginTotalBalances
  );
  const [saveMutation, saveResult] = useMutation(saveTotalBalances);
  const [resetMutation, resetResult] = useMutation(resetTotalBalances);
  const isSyncingActual = syncActualResult.loading;
  const isSyncingMargin = syncMarginResult.loading;
  const isSaving = saveResult.loading;
  const isResetting = resetResult.loading;

  async function syncActual() {
    await syncActualMutation();
    await refetch();
  }

  async function syncMargin() {
    await syncMarginMutation();
    await refetch();
  }

  async function save() {
    await saveMutation();
    await refetch();
  }

  async function reset() {
    await resetMutation();
    await refetch();
  }

  function showResetConfirmation() {
    setResetConfirmationVisible(true);
  }

  function hideResetConfirmation() {
    setResetConfirmationVisible(false);
  }

  async function confirmReset() {
    await reset();
    hideResetConfirmation();
  }

  return {
    items: sort(items.slice()),
    btcAmountPrecision,
    totalActualAmountSyncTime,
    totalMarginAmountSyncTime,
    totalSavedAmountSyncTime,
    syncActual,
    syncMargin,
    save,
    isSyncingActual,
    isSyncingMargin,
    isSaving,
    isResetting,
    sortBy,
    sortDir,
    toggleSort,
    showResetConfirmation,
    hideResetConfirmation,
    confirmReset,
    isResetConfirmationVisible
  };
}
